import Season, { Exam, Question } from "../../../domain/Season";
import { UUID } from "../../../domain/UUID";
import { workingSeasonState, WorkingSeasonState } from "./useWorkingSeason";

type QuestionField = 'name' | 'number' | 'maximumPoints';

interface ResetAction {
  readonly type: "RESET",
  readonly value: Season | undefined
}

interface AddQuestionAction {
  readonly type: "ADD_QUESTION",
  readonly value: Question
}

interface DeleteQuestionAction {
  readonly type: "DELETE_QUESTION",
  readonly value: UUID<Question>
}

interface ChangeQuestionAction {
  readonly type: "CHANGE_QUESTION",
  readonly value: {
    readonly questionId: UUID<Question>
    readonly field: QuestionField,
    readonly value: string | number
  }
}

interface AssignQuestionAction {
  readonly type: "ASSIGN_QUESTION",
  readonly value: {
    readonly questionId: UUID<Question>,
    readonly examId: UUID<Exam>
  }
}

interface UnassignQuestionAction {
  readonly type: "UNASSIGN_QUESTION",
  readonly value: {
    readonly questionId: UUID<Question>,
    readonly examId: UUID<Exam>
  }
}

interface ChangeAssignedMarksAction {
  readonly type: "CHANGE_ASSIGNED_MARKS",
  readonly value: {
    readonly questionId: UUID<Question>,
    readonly examId: UUID<Exam>,
    readonly marks: number
  }
}

type Action =
  ResetAction
  | AddQuestionAction
  | DeleteQuestionAction
  | ChangeQuestionAction
  | AssignQuestionAction
  | UnassignQuestionAction
  | ChangeAssignedMarksAction;

export default function workingSeasonReducer(previousState: WorkingSeasonState, action: Action): WorkingSeasonState {
  switch (action.type) {
    case "RESET":
      return workingSeasonState(action.value === undefined ? previousState.initialSeason : action.value);

    case "ADD_QUESTION": {
      const newQuestions = new Map(previousState.questions);

      newQuestions.set(action.value.id, action.value);

      return { ...previousState, questions: newQuestions };
    }

    case "DELETE_QUESTION": {
      const newQuestions = new Map(previousState.questions);

      newQuestions.delete(action.value);

      return {
        ...previousState,
        questions: newQuestions,
        examAssignments: previousState.examAssignments.filter(ea => ea.questionId != action.value)
      };
    }

    case "CHANGE_QUESTION":
      const oldQuestion = previousState.questions.get(action.value.questionId);

      if (oldQuestion === undefined || oldQuestion[action.value.field] === action.value.value) {
        return previousState;
      }

      const newQuestion = {
        ...oldQuestion,
        [action.value.field]: action.value.value
      }

      const newQuestions = new Map(previousState.questions);

      newQuestions.set(newQuestion.id, newQuestion);

      return {
        ...previousState,
        questions: newQuestions
      }

    case "ASSIGN_QUESTION": {
      const newAssignments = previousState.examAssignments.filter(ea => ea.examId !== action.value.examId || ea.questionId !== action.value.questionId);

      newAssignments.push({ examId: action.value.examId, questionId: action.value.questionId, maximumMarks: 0 });

      return {
        ...previousState,
        examAssignments: newAssignments
      }
    }

    case "UNASSIGN_QUESTION":
      return {
        ...previousState,
        examAssignments: previousState.examAssignments.filter(ea => ea.examId !== action.value.examId || ea.questionId !== action.value.questionId)
      }

    case "CHANGE_ASSIGNED_MARKS": {
      const newAssignments = previousState.examAssignments.filter(ea => ea.examId !== action.value.examId || ea.questionId !== action.value.questionId);

      newAssignments.push({
        examId: action.value.examId,
        questionId: action.value.questionId,
        maximumMarks: action.value.marks
      });

      return {
        ...previousState,
        examAssignments: newAssignments
      }
    }
  }
  return previousState;
}
