import * as React from 'react';
import { useEffect, useState } from 'react';
import Season, { Exam } from "../../domain/Season";
import { useAccount } from "../account/AccountContext";
import { getAllSeasons, getSeason } from "../../gateway/exams";
import SeasonState from "../../domain/SeasonState";
import ExamLevel from "../../domain/ExamLevel";

interface SeasonContext {
  readonly currentSeason: Season | undefined,
  readonly currentAdvancedExam: Exam | undefined,
  readonly invalidate: () => Promise<void>,
}

const SeasonContext = React.createContext<SeasonContext>({
  currentSeason: undefined,
  currentAdvancedExam: undefined,
  invalidate: () => Promise.resolve()
});

export const useCurrentSeason = () => {
  return React.useContext(SeasonContext);
};

export const SeasonProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoggedIn } = useAccount();
  const [season, setSeason] = useState<Season | undefined>(undefined);

  const invalidate = () => {
    if (!isLoggedIn) {
      setSeason(undefined);
      return Promise.resolve();
    }

    return getAllSeasons()
      .then(seasons => seasons.filter(season => season.state !== SeasonState.UNPUBLISHED)[0])
      .then(currentSeason => {
        if (currentSeason === undefined) {
          setSeason(undefined);
          return;
        }

        getSeason(currentSeason.id).then(setSeason);
      });
  }

  useEffect(() => {
    invalidate()
  }, [isLoggedIn]);

  const context = {
    currentSeason: season,
    currentAdvancedExam: season?.exams[ExamLevel.ADVANCED],
    invalidate
  }

  return (
    <SeasonContext.Provider value={context}>
      {children}
    </SeasonContext.Provider>
  );
};
