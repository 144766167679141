import * as React from 'react';
import { Control, Controller, FieldPath, useFormState } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import ValidationRules from './ValidationRules';

interface Props<T> {
  readonly control: Control<T>
  readonly name: FieldPath<T>
  readonly label: string
  readonly required?: boolean,
  readonly placeholder?: string,
  readonly size?: 'small' | 'medium',
  readonly multiline?: boolean,
  readonly minRows?: number,
  readonly autoFocus?: boolean,
  readonly rules?: ValidationRules
  readonly fullWidth?: boolean
  readonly sx?: any
}

export default function FormInputText<T>({ control, name, label, required, placeholder, size = 'small', multiline, minRows, fullWidth, autoFocus, rules, sx }: Props<T>) {
  const { errors } = useFormState({ control });
  // @ts-ignore
  const error = errors[name];

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <TextField
          label={label}
          size={size}
          variant="outlined"
          margin="none"
          required={required}
          placeholder={placeholder}
          multiline={multiline}
          minRows={minRows || 1}
          fullWidth={fullWidth}
          autoFocus={autoFocus}
          error={error !== undefined}
          helperText={error?.message}
          sx={sx}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          name={name}
          inputRef={ref}
        />
      )}
    />
  );
};
