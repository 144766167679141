import { UUID } from '../domain/UUID';
import axios from 'axios';
import Season, { Exam, Question, SeasonMetadata } from "../domain/Season";
import SeasonState from "../domain/SeasonState";
import Document, { DocumentType } from '../domain/Document';

interface AllSeasonsResponse {
  readonly seasons: SeasonMetadata[],
}

interface ExamQuestionAssignment {
  readonly examId: UUID<Exam>,
  readonly questionId: UUID<Question>,
  readonly maximumMarks: number
}

export interface PutSeasonQuestionsRequest {
  readonly questions: Question[],
  readonly examAssignments: ExamQuestionAssignment[]
}

export function getAllSeasons(): Promise<SeasonMetadata[]> {
  return axios.get<AllSeasonsResponse>('/exams/seasons').then(({ data }) => data.seasons);
}

export function getSeason(seasonId: UUID<Season>): Promise<Season> {
  return axios.get<Season>(`/exams/seasons/${seasonId}`).then(({ data }) => data);
}

export function putSeasonState(seasonId: UUID<Season>, state: SeasonState): Promise<Season> {
  return axios.put<Season>(`/exams/seasons/${seasonId}/state`, { state }).then(({ data }) => data);
}

export function putSeasonQuestions(seasonId: UUID<Season>, putSeasonRequest: PutSeasonQuestionsRequest): Promise<Season> {
  return axios.put<Season>(`/exams/seasons/${seasonId}/questions`, putSeasonRequest).then(({ data }) => data);
}

export function deleteSeason(seasonId: UUID<Season>): Promise<void> {
  return axios.delete(`/exams/seasons/${seasonId}`);
}

export function postNewSeason(year: number): Promise<Season> {
  return axios.post<Season>(`/exams/seasons/new/${year}`).then(({ data }) => data);
}

export function getExam(examId: UUID<Exam>): Promise<Exam> {
  return axios.get<Exam>(`/exams/${examId}`).then(({ data }) => data);
}

export function putExamDocument(examId: UUID<Exam>, documentId: UUID<Document>, documentType: DocumentType): Promise<Exam>
{
  // validate that DocumentType is QUESTIONS or SOLUTIONS
  if (documentType !== DocumentType.QUESTIONS && documentType !== DocumentType.SOLUTIONS) {
    throw new Error(`Invalid document type ${documentType}`);
  }

  return axios.put<Exam>(`/exams/${examId}/document/${documentType.toLowerCase()}/${documentId}`, {}).then(({ data }) => data);
}

export function deleteExamDocument(examId: UUID<Exam>, documentType: DocumentType): Promise<Exam>
{
  // validate that DocumentType is QUESTIONS or SOLUTIONS
  if (documentType !== DocumentType.QUESTIONS && documentType !== DocumentType.SOLUTIONS) {
    throw new Error(`Invalid document type ${documentType}`);
  }

  return axios.delete<Exam>(`/exams/${examId}/document/${documentType.toLowerCase()}`).then(({ data }) => data);
}
