import * as React from "react";
import { useEffect, useState } from "react";
import { CentreMetadata } from "../../../domain/Centre";
import Account from "../../../domain/Account";
import { getCentreManagers, requestToBecomeCentreManager } from "../../../gateway/centres";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";

interface Props {
  readonly centreMetadata: CentreMetadata,
  readonly onConfirmedRequestToJoin: () => void,
  readonly onGoBack: () => void
}

export default function RequestJoiningExistingCentre({ centreMetadata, onConfirmedRequestToJoin, onGoBack }: Props) {
  const [managers, setManagers] = useState<Account[] | undefined>(undefined);

  useEffect(() => {
    getCentreManagers(centreMetadata.id).then(setManagers);
  }, [centreMetadata.id]);

  if (managers === undefined) {
    return <CircularProgress />;
  }

  const anyExistingManagers = managers.length > 0;

  const handleRequestToJoin = () => requestToBecomeCentreManager(centreMetadata.id).then(onConfirmedRequestToJoin);

  return (
    <Stack direction="column" spacing={2}>
      {anyExistingManagers
        ? (
          <>
            <Typography>
              These people are currently managing <strong>{centreMetadata.name}</strong> ({centreMetadata.postcode}):
            </Typography>
            <ul>
              {managers.map(m => <li key={m.id}>{m.name} ({m.email})</li>)}
            </ul>
            <Typography>
              If this looks correct, you can request to join them.
              They will have to approve your request before you can register students.
            </Typography>
          </>
        ) : (
          <Typography>
            Nobody is managing registrations at <strong>{centreMetadata.name}</strong> at the moment,
            do you want to start?
          </Typography>
        )
      }
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={handleRequestToJoin} disableElevation>
          {anyExistingManagers ? 'Send them a request' : 'Yes, start'}
        </Button>
        <Button onClick={onGoBack}>
          {anyExistingManagers ? "That's not right, go back" : 'No, go back'}
        </Button>
      </Stack>
    </Stack>
  );
}
