import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { alpha, CircularProgress, InputAdornment, TextField } from "@mui/material";
import { debounce } from "lodash";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import exhaustiveGuard from "../common/exhaustiveGuard";
import theme from "../../theme";

type Props = {
  readonly points: number | undefined;
  readonly maximumPoints: number;
  readonly highlightEmpty: boolean;
  readonly onUpdatedPoints: (points: number) => void;
}

enum PointsState {
  UNTOUCHED,
  WAITING,
  INVALID_INPUT,
  SAVED
}

export default function StudentPointsTablePointsInput({ points, maximumPoints, highlightEmpty, onUpdatedPoints }: Props) {
  const [pointsValue, setPointsValue] = useState<string>(points === undefined ? "" : points.toString());
  const [pointsState, setPointsState] = useState<PointsState>(points === undefined ? PointsState.UNTOUCHED : PointsState.SAVED);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  useEffect(() => {
    if (points !== undefined && (points < 0 || points > maximumPoints)) {
      setPointsState(PointsState.INVALID_INPUT);
    }

    setPointsValue(points === undefined ? "" : points.toString());
  }, [points, maximumPoints]);

  const propagateChange = useCallback(debounce((stringValue: string) => {
    if (!new RegExp('^[0-9]+$').test(stringValue)) {
      setPointsState(PointsState.INVALID_INPUT);
      return;
    }

    const value = parseInt(stringValue);
    if (value < 0 || value > maximumPoints) {
      setPointsState(PointsState.INVALID_INPUT);
      return;
    }

    onUpdatedPoints(value);
    setPointsState(PointsState.SAVED);
  }, 1000), []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const stringValue = e.currentTarget.value;

    setPointsValue(stringValue);
    setPointsState(PointsState.WAITING);

    propagateChange(stringValue);
  }

  let color: "primary" | "success" | "error" = "primary";
  let icon = <CheckIcon sx={{ visibility: "hidden" }} />; // place
  switch (pointsState) {
    case PointsState.INVALID_INPUT:
      color = "error";
      icon = <CloseIcon color={color} />;
      break;
    case PointsState.SAVED:
      color = "success";
      icon = <CheckIcon color={color} />;
      break;
    case PointsState.WAITING:
      color = "primary";
      icon = <CircularProgress size={20} sx={{ p: 0.25 }} />;
      break;
    case PointsState.UNTOUCHED:
      break;
    default:
      exhaustiveGuard(pointsState);
  }

  return (
    <TextField
      value={pointsValue}
      onChange={handleChange}
      size="small"
      error={pointsState === PointsState.INVALID_INPUT}
      color={color}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      focused={isFocused || pointsState === PointsState.INVALID_INPUT || pointsState === PointsState.WAITING}
      fullWidth
      sx={pointsState === PointsState.INVALID_INPUT ? { input: { color: 'error.main'}} : {}}
      InputProps={{
        inputProps: {
          sx: {
            textAlign: "right",
            pr: { xs: 1, sm: 2 },
            py: 0.75,
          },
        },
        sx: {
          pl: 1,
          pr: 0,
          backgroundColor: (highlightEmpty && pointsValue === "") ? alpha(theme.palette.primary.light, 0.2) : "background.paper"
        },
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{ display: { xs: "none", md: "flex" } }}>
            {icon}
          </InputAdornment>
        )
      }}
    />
  );
}


