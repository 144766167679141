import * as React from 'react';
import { useState } from 'react';
import { Alert, Button, Container, Link, Paper, Stack, TextField, Typography } from '@mui/material';
import { forgotPassword } from '../../gateway/accounts';
import { ROUTE_LOGIN } from '../../UkloRoutes';
import { Link as RouterLink } from 'react-router-dom';

export default function ForgotPasswordPage() {
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget as typeof event.currentTarget & {
      email: { value: string };
    };
    const email = form.email.value;

    if (!email.trim() || !email.includes('@')) {
      setError(true);
      return;
    }

    forgotPassword(email.trim())
      .then(() => {
        setSubmitted(true);
        setError(false);
      })
      .catch(() => setError(true));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper sx={{ mt: 4, p: 4, pb: 3 }}>
        <Stack alignItems="center" spacing={3}>
          <Typography component="h1" variant="h5">
            Reset Your Password
          </Typography>
          {!submitted && (
            <Stack component="form" onSubmit={handleSubmit} noValidate spacing={1} sx={{ width: '100%' }}>
              {error && (
                <Alert severity="error">
                  Unable to send reset instructions. Check your email or try again.
                </Alert>
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Your email address"
                name="email"
                autoComplete="email"
                autoFocus
                error={error}
              />
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                disableElevation
              >
                Send reset instructions
              </Button>
            </Stack>
          )}
          {submitted && (
            <Alert severity="success">
              If an account with that email exists, we’ve sent instructions to reset your password. Please check your
              email.
            </Alert>
          )}
          <Link component={RouterLink} to={ROUTE_LOGIN} variant="body2">
            Back to login
          </Link>
        </Stack>
      </Paper>
    </Container>
  );
}
