import * as React from "react";
import MarkingCapacityCard from "../marking/MarkingCapacityCard";
import { useCurrentSeason } from "../exams/SeasonContext";
import ExamLevel from "../../domain/ExamLevel";
import { Typography } from "@mui/material";
import MarkingAssignmentsCard from "../marking/MarkingAssignmentsCard";
import SeasonState from "../../domain/SeasonState";

export default function MarkingSection() {
  const { currentSeason } = useCurrentSeason();

  if (currentSeason === undefined) {
    return null;
  }

  const currentAdvancedExam = currentSeason?.exams[ExamLevel.ADVANCED];

  if (!currentAdvancedExam) {
    return <Typography>Loading</Typography>
  }

  return (
    <>
      {SeasonState.isOrIsAfter(currentSeason.state, SeasonState.ROUND_1_TEST_PERIOD)
        && <MarkingAssignmentsCard season={currentSeason} exam={currentAdvancedExam} />}
      {SeasonState.isBefore(currentSeason.state, SeasonState.ROUND_1_ADVANCED_RESULTS_VERIFICATION)
        && <MarkingCapacityCard season={currentSeason} exam={currentAdvancedExam} />}
    </>
  );
}
