import Award from "../../../domain/Award";
import StudentExamResult from "../../../domain/StudentExamResult";

export interface BoundaryPreview {
  readonly award: Award;
  readonly boundary: number;
  readonly count: number;
  readonly percentageOfStudents: number;
}

interface ProposedBoundary {
  readonly award: Award;
  readonly boundary: number;
}

export function calculateBoundaryPreview(sortedResults: StudentExamResult[], proposedBoundaries: ProposedBoundary[]): BoundaryPreview[] {
  let remainingStudents = [...sortedResults];

  return proposedBoundaries.map((proposedBoundary) => {
    const qualifiedStudents = remainingStudents.filter(
      (student) => parseFloat(student.percentage) >= proposedBoundary.boundary
    );

    remainingStudents = remainingStudents.filter(
      (student) => parseFloat(student.percentage) < proposedBoundary.boundary
    );

    return {
      award: proposedBoundary.award,
      count: qualifiedStudents.length,
      boundary: proposedBoundary.boundary,
      percentageOfStudents: parseFloat(((qualifiedStudents.length / sortedResults.length) * 100).toFixed(2)),
    };
  });
}
