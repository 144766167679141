import { UUID } from '../domain/UUID';
import axios from 'axios';
import Centre, { CentreDetails, CentreMetadata } from '../domain/Centre';
import Account from "../domain/Account";
import { AccountListResponse } from "./accounts";

interface CentreListResponse {
  readonly centres: Centre[]
}

interface CentreMetadataListResponse {
  readonly centreMetadata: CentreMetadata[]
}

export function createCentre(centreDetails: CentreDetails): Promise<Centre> {
  return axios.post('/centres', centreDetails).then(({ data }) => data);
}

export function getAllCentreMetadata(): Promise<CentreMetadata[]> {
  return axios.get<CentreMetadataListResponse>(`/centres`).then(({ data }) => data.centreMetadata);
}

export function getCentre(centreId: UUID<Centre>): Promise<Centre> {
  return axios.get<Centre>(`/centres/${centreId}`).then(({ data }) => data);
}

export function updateCentre(centreId: UUID<Centre>, centreDetails: CentreDetails): Promise<Centre> {
  return axios.put(`/centres/${centreId}`, centreDetails).then(({ data }) => data);
}

export function getCentreManagers(centreId: UUID<Centre>): Promise<Account[]> {
  return axios.get<AccountListResponse>(`/centreManagers/centre/${centreId}`).then(({ data }) => data.accounts);
}

export function getMyManagedCentres(): Promise<Centre[]> {
  return axios.get<CentreListResponse>(`/centreManagers/me`).then(({ data }) => data.centres);
}

export function requestToBecomeCentreManager(centreId: UUID<Centre>): Promise<void> {
  return axios.post(`/centreManagers/centre/${centreId}/join`);
}
