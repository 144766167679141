import * as React from "react";
import Document from "../../domain/Document";
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import TruncateWithTooltip from "../common/TruncateWithTooltip";
import { formatTime } from "../common/time";
import useConfirmationDialog from "../common/useConfirmationDialog";

interface Props {
  readonly document: Document;
  readonly deletable?: boolean;
  readonly onDelete?: () => void;
}

export default function CentreExamDocumentDisplay({ document, deletable = false, onDelete = () => {} }: Props) {
  const { requestConfirmation, component } = useConfirmationDialog();
  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pl: 1.5,
        pr: 0.5,
        height: 56,
      }}
    >
      <Stack direction="column">
        <Typography variant="body2">
          <TruncateWithTooltip text={document.name} maxLength={22} />
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {formatTime(document.uploadedAt)}
        </Typography>
      </Stack>

      <Box>
        <IconButton
          href={document.url}
          download
          size="small"
          aria-label="Download document"
          color="primary"
          sx={{
            color: "grey.500",
            "&:hover": {
              color: "primary.main",
            },
          }}
        >
          <DownloadIcon />
        </IconButton>
        {deletable && (
          <IconButton
            onClick={() => requestConfirmation({
              action: onDelete,
              title: "Really delete file?",
              message: `Are you sure you want to delete '${document.name}'?`,
              confirmText: `Yes, delete`,
              cancelText: "No, keep it",
            })}
            size="small"
            aria-label="Delete document"
            color="error"
            sx={{
              color: "grey.500",
              "&:hover": {
                color: "error.main",
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
      {component}
    </Paper>
  );
}
