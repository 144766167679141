import * as React from 'react';
import { Control, Controller, Path, PathValue, useFormState } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import ValidationRules from './ValidationRules';

interface Option {
  readonly value: string;
  readonly label: string;
}

interface Props<T> {
  readonly control: Control<T>;
  readonly name: Path<T>;
  readonly label: string;
  readonly options: Option[] | string[];
  readonly required?: boolean;
  readonly rules?: ValidationRules;
  readonly sx?: any;
}

export default function FormInputSelect<T>({ control, name, label, options, required, rules, sx }: Props<T>) {
  const { errors } = useFormState({ control });
  // @ts-ignore
  const error = errors[name];

  const objectOptions: Option[] = options.map(option => typeof option === 'string' ? { value: option, label: option } : option);

  const findOption = (value: string) => objectOptions.find(option => option.value === value);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          options={objectOptions.map(option => option.value)}
          size="small"
          sx={sx}
          value={field.value as string}
          onChange={(e, value) => field.onChange(value)}
          onBlur={field.onBlur}
          getOptionLabel={(option) => findOption(option)?.label || ''}
          renderInput={(params) => (
            <TextField
              label={label}
              variant="outlined"
              margin="none"
              required={required}
              error={error !== undefined}
              helperText={error?.message}
              {...params}
            />
          )}
        />
      )}
    />
  );
};
