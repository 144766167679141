import * as React from "react";
import { useEffect, useState } from "react";
import { Exam, SeasonMetadata } from "../../domain/Season";
import Document from "../../domain/Document";
import { Box, Link, TableCell, TableRow, Typography } from "@mui/material";
import { getExam } from "../../gateway/exams";
import { getDocument } from "../../gateway/documents";
import { UUID } from "../../domain/UUID";

interface Props {
  readonly seasonMetadata: SeasonMetadata;
  readonly examId: UUID<Exam>;
  readonly includeSolutions: boolean;
}

interface ExamDocumentCellProps {
  readonly documentId: UUID<Document> | null;
}

function ExamDocumentCellContents({ documentId }: ExamDocumentCellProps) {
  const [document, setDocument] = useState<Document | undefined>(undefined);

  useEffect(() => {
    if (documentId === null) {
      setDocument(undefined);
      return;
    }

    getDocument(documentId).then(setDocument);
  }, [documentId]);

  if (documentId == null) {
    return <>Not available yet</>;
  }

  if (document === undefined) {
    return <>Loading</>;
  }

  return (
    <Link href={document.url} download underline="hover" target="_self" rel="noreferrer">
      {document.name}
    </Link>
  );
}

export default function ExamDocumentRow({ seasonMetadata, examId, includeSolutions }: Props) {
  const [exam, setExam] = useState<Exam | undefined>(undefined);

  useEffect(() => {
    getExam(examId).then(setExam);
  }, [examId]);

  if (exam === undefined) {
    return (
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell colSpan={3}>
          <Typography>Loading</Typography>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell>
        {Exam.name(seasonMetadata, exam)}
      </TableCell>
      <TableCell>
        <Box sx={{ pl: 1 }}>
          <ExamDocumentCellContents documentId={exam.questionsDocument} />
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ pl: 1 }}>
          {includeSolutions
            ? <ExamDocumentCellContents documentId={exam.solutionsDocument} />
            : "Not available yet"
          }
        </Box>
      </TableCell>
    </TableRow>
  );
}
