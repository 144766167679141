import StudentExamPoints from '../domain/StudentExamPoints';
import axios from 'axios';
import { UUID } from '../domain/UUID';
import Centre from '../domain/Centre';
import Student from '../domain/Student';
import { Exam, Question } from "../domain/Season";

interface GetPointsRequest {
  centreId: UUID<Centre>,
  examId: UUID<Exam>
}

interface QuestionIdAndPointsDto {
  questionId: UUID<Question>,
  points: number
}

interface StudentExamPointsDto {
  studentId: UUID<Student>,
  examId: UUID<Exam>,
  points: QuestionIdAndPointsDto[],
  scriptMissing: boolean,
}

interface GetPointsResponse {
  studentExamPoints: StudentExamPointsDto[];
}

export function putPoints(studentExamPoints: StudentExamPoints) {
  return axios.put(
    '/points',
    {
      examId: studentExamPoints.examId,
      studentId: studentExamPoints.studentId,
      points: Array.from(studentExamPoints.points).map(entry => ({ questionId: entry[0], points: entry[1] })),
      scriptMissing: studentExamPoints.scriptMissing,
    }
  );
}

export function getPoints({ examId, centreId }: GetPointsRequest): Promise<StudentExamPoints[]> {
  return axios.get<GetPointsResponse>('/points', { params: { examId, centreId } })
    .then(response => response.data.studentExamPoints)
    .then(dtoArray => dtoArray.map(dto => ({
      studentId: dto.studentId,
      examId: dto.examId,
      points: pointsArrayToMap(dto.points),
      scriptMissing: dto.scriptMissing,
    })));
}

function pointsArrayToMap(pointsArray: QuestionIdAndPointsDto[]): ReadonlyMap<UUID<Question>, number> {
  const map = new Map();

  for (let questionIdAndPointsDto of pointsArray) {
    map.set(questionIdAndPointsDto.questionId, questionIdAndPointsDto.points);
  }

  return map;
}
