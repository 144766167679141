import * as React from 'react';
import Page from '../common/Page';
import { useUUIDParam } from '../../UkloRoutes';
import Centre from '../../domain/Centre';
import CentreManagement from "./CentreManagement";

export default function CentrePage() {
  const centreId = useUUIDParam<Centre>();

  return (
    <Page main padding={0}>
      <CentreManagement centreId={centreId} />
    </Page>
  );
}
