import * as React from "react";
import { Marker } from "../../../domain/MarkingCapacity";
import { Autocomplete, Popper } from "@mui/material";
import TextField from "@mui/material/TextField";
import Account from "../../../domain/Account";
import { UUID } from "../../../domain/UUID";
import MarkerDetails from "./MarkerDetails";

interface Props {
  readonly markers: ReadonlyMap<UUID<Account>, Marker>,
  readonly selectedMarkerId: UUID<Account> | undefined,
  readonly onSelectMarkerId: (markerId: UUID<Account> | undefined) => void
}

function LargePopper(props: any) {
  return <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />;
}

export default function SelectMarker({ markers, selectedMarkerId, onSelectMarkerId }: Props) {
  const selectedMarker = selectedMarkerId === undefined ? undefined : markers.get(selectedMarkerId);

  const markerOptions = Array.from(markers.values()).sort((a, b) => {
    const capacityDifference = (b.totalCapacity - b.usedCapacity) - (a.totalCapacity - a.usedCapacity);

    if (capacityDifference !== 0) {
      return capacityDifference;
    }

    return a.account.name.localeCompare(b.account.name);
  });

  return (
    <Autocomplete
      options={markerOptions}
      value={selectedMarker || null}
      onChange={(e, value) => onSelectMarkerId(value === null ? undefined : value.account.id)}
      getOptionLabel={(marker) => `${marker.account.name}`}
      noOptionsText="No markers found"
      renderInput={(params) => (
        <TextField label="Marker" variant="outlined" autoFocus {...params} />
      )}
      renderOption={(liProps, marker) => (
        <li {...liProps} key={marker.account.id}>
          <MarkerDetails marker={marker} />
        </li>
      )}
      size="small"
      PopperComponent={LargePopper}
    />
  );
}
