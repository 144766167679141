import React, { useState } from 'react';
import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TitleIcon from '@mui/icons-material/Title';
import CodeIcon from '@mui/icons-material/Code';
import UnderlineIcon from '@mui/icons-material/FormatUnderlined';
import LinkIcon from '@mui/icons-material/Link';

interface Props {
  readonly editor: any;
  readonly showRawHtml: boolean;
  readonly onToggleRawHtml: () => void;
}

export default function EditorToolbar({ editor, showRawHtml, onToggleRawHtml }: Props) {
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');

  if (!editor) {
    return null;
  }

  const handleStyleToggle = (style: string) => {
    if (!showRawHtml) {
      switch (style) {
        case 'bold':
          editor.chain().focus().toggleBold().run();
          break;
        case 'italic':
          editor.chain().focus().toggleItalic().run();
          break;
        case 'strike':
          editor.chain().focus().toggleStrike().run();
          break;
        case 'underline':
          editor.chain().focus().toggleUnderline().run();
          break;
        default:
          break;
      }
    }
  };

  const handleHeadingToggle = (level: string | null) => {
    if (!showRawHtml) {
      if (level === 'h1') {
        editor.chain().focus().toggleHeading({ level: 1 }).run();
      } else if (level === 'h2') {
        editor.chain().focus().toggleHeading({ level: 2 }).run();
      } else if (level === 'h3') {
        editor.chain().focus().toggleHeading({ level: 3 }).run();
      } else {
        editor.chain().focus().setParagraph().run();
      }
    }
  };

  const handleListToggle = (listType: string) => {
    if (!showRawHtml) {
      if (listType === 'bullet') {
        editor.chain().focus().toggleBulletList().run();
      } else if (listType === 'ordered') {
        editor.chain().focus().toggleOrderedList().run();
      }
    }
  };

  const handleLinkAdd = () => {
    if (!showRawHtml) {
      const previousUrl = editor.getAttributes('link').href || '';
      setLinkUrl(previousUrl);
      setLinkDialogOpen(true);
    }
  };

  const handleLinkConfirm = () => {
    if (linkUrl === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
    } else {
      editor.chain().focus().extendMarkRange('link').setLink({ href: linkUrl }).run();
    }
    setLinkDialogOpen(false);
    setLinkUrl('');
  };

  const handleLinkCancel = () => {
    setLinkDialogOpen(false);
    setLinkUrl('');
  };

  const handleLinkDelete = () => {
    editor.chain().focus().extendMarkRange('link').unsetLink().run();
    setLinkDialogOpen(false);
    setLinkUrl('');
  };

  return (
    <>
      <Stack direction="row" spacing={1} sx={{ marginBottom: 1, maxHeight: 40 }}>
        <ToggleButtonGroup
          size="small"
          value={null}
          aria-label="Text formatting"
          disabled={showRawHtml}
          color="primary"
        >
          <ToggleButton
            value="bold"
            selected={editor.isActive('bold')}
            aria-label="Bold"
            onChange={(_, style) => handleStyleToggle(style)}
          >
            <FormatBoldIcon />
          </ToggleButton>
          <ToggleButton
            value="italic"
            selected={editor.isActive('italic')}
            aria-label="Italic"
            onChange={(_, style) => handleStyleToggle(style)}
          >
            <FormatItalicIcon />
          </ToggleButton>
          <ToggleButton
            value="underline"
            selected={editor.isActive('underline')}
            aria-label="Underline"
            onChange={(_, style) => handleStyleToggle(style)}
          >
            <UnderlineIcon />
          </ToggleButton>
          <ToggleButton
            value="strike"
            selected={editor.isActive('strike')}
            aria-label="Strikethrough"
            onChange={(_, style) => handleStyleToggle(style)}
          >
            <StrikethroughSIcon />
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
          size="small"
          exclusive
          value={
            showRawHtml
              ? null
              : editor.isActive('heading', { level: 1 })
                ? 'h1'
                : editor.isActive('heading', { level: 2 })
                  ? 'h2'
                  : editor.isActive('heading', { level: 3 })
                    ? 'h3'
                    : null
          }
          onChange={(_, level) => handleHeadingToggle(level)}
          aria-label="Headings"
          disabled={showRawHtml}
          color="primary"
        >
          <ToggleButton value="h1" aria-label="Heading 1">
            <TitleIcon /> H1
          </ToggleButton>
          <ToggleButton value="h2" aria-label="Heading 2">
            <TitleIcon /> H2
          </ToggleButton>
          <ToggleButton value="h3" aria-label="Heading 3">
            <TitleIcon /> H3
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
          size="small"
          value={null}
          onChange={(_, listType) => handleListToggle(listType)}
          aria-label="List formatting"
          disabled={showRawHtml}
          exclusive
          color="primary"
        >
          <ToggleButton
            value="bullet"
            selected={editor.isActive('bulletList')}
            aria-label="Bullet List"
          >
            <FormatListBulletedIcon />
          </ToggleButton>
          <ToggleButton
            value="ordered"
            selected={editor.isActive('orderedList')}
            aria-label="Ordered List"
          >
            <FormatListNumberedIcon />
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButton
          value="link"
          selected={editor.isActive('link')}
          aria-label="Link"
          onClick={handleLinkAdd}
        >
          <LinkIcon />
        </ToggleButton>

        <Box sx={{ flexGrow: 1 }} />

        <ToggleButton
          value="rawHtml"
          selected={showRawHtml}
          onClick={onToggleRawHtml}
          aria-label="Toggle Raw HTML"
          color="primary"
        >
          <CodeIcon />
        </ToggleButton>
      </Stack>

      <Dialog open={linkDialogOpen} onClose={handleLinkCancel} fullWidth maxWidth="sm">
        <DialogTitle>{editor.isActive('link') ? 'Edit Link' : 'Insert Link'}</DialogTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLinkConfirm();
          }}
        >
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="URL"
              type="url"
              fullWidth
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLinkCancel}>Cancel</Button>
            {editor.isActive('link') && (
              <Button onClick={handleLinkDelete} color="error">
                Delete Link
              </Button>
            )}
            <Button type="submit" variant="contained">
              {editor.isActive('link') ? 'Update' : 'Insert'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
