import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { SeasonMetadata } from "../../domain/Season";

interface Props {
  open: boolean,
  season: SeasonMetadata,
  dismiss: () => void,
  confirm: () => void,
}

export default function ConfirmDeleteSeasonDialog({ open, season, dismiss, confirm }: Props) {
  return (
    <Dialog open={open} onClose={dismiss} fullWidth maxWidth="sm">
      <DialogTitle>
        Really delete {season.year} season?
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>Are you sure you want to delete the {season.year} season?</Typography>
        <Typography color="error" fontWeight="bold">This action cannot be undone.</Typography>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={dismiss} autoFocus>No, stop</Button>
        <Button onClick={confirm} color="error" variant="contained" startIcon={<DeleteIcon/>} disableElevation>Yes, delete</Button>
      </DialogActions>
    </Dialog>
  );
}
