import { DataGrid, GridActionsCellItem, GridColumns } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import Student from '../../../domain/Student';
import Gender from '../../../domain/Gender';
import { UUID } from '../../../domain/UUID';
import { IconButton, Tooltip } from '@mui/material';
import Season, { Exam } from '../../../domain/Season';
import StudentExamResult from '../../../domain/StudentExamResult';
import Award from '../../../domain/Award';
import { downloadStudentCertificate } from "../../../gateway/results";
import sanitizeFileName from "../../common/sanitizeFileName";

interface Props {
  readonly season: Season;
  readonly rows: Student[];
  readonly results?: { [key: UUID<Student>]: StudentExamResult } | undefined;
  readonly displayRegistered?: boolean;
  readonly editable?: boolean;
  readonly onDeleteStudent?: (studentId: UUID<Student>) => void;
}

export default function CentreStudentsTableDataGrid({
                                                      season,
                                                      rows,
                                                      results,
                                                      displayRegistered,
                                                      editable,
                                                      onDeleteStudent,
                                                    }: Props) {
  const columns: GridColumns<Student> = [
    { headerName: 'Name', flex: 5, field: 'name', valueGetter: (params) => params.row.name },
    { headerName: 'Gender', flex: 2, field: 'gender', valueGetter: (params) => Gender.pretty(params.row.gender) },
    {
      headerName: 'Year',
      flex: 2,
      field: 'year',
      valueGetter: (params) => (params.row.year === '' ? 'Unknown' : params.row.year),
    },
    {
      headerName: 'Exam',
      flex: 3,
      field: 'examId',
      valueGetter: (params) =>
        Exam.name(season, Object.values(season.exams).find((e) => e.examId === params.row.examId)),
    },
  ];

  if (results !== undefined) {
    columns.push(
      {
        headerName: 'Marks',
        flex: 2,
        field: 'marks',
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => {
          const studentResult = results[params.row.id];
          if (studentResult === undefined) {
            return 0;
          }
          return parseFloat(studentResult.totalMarks) || 0;
        },
        valueFormatter: ({ value }) => (value > 0 ? value.toFixed(2) : '-'),
      },
      {
        headerName: 'Percentage',
        flex: 2,
        field: 'percentage',
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => {
          const studentResult = results[params.row.id];
          if (studentResult === undefined) {
            return 0;
          }
          return parseFloat(studentResult.percentage) || 0;
        },
        valueFormatter: ({ value }) => (value > 0 ? `${value.toFixed(2)}%` : '-'),
      },
      {
        headerName: 'Award',
        flex: 2,
        field: 'award',
        valueGetter: (params) => {
          const studentResult = results[params.row.id];
          if (studentResult === undefined) {
            return null;
          }
          return studentResult.award;
        },
        valueFormatter: ({ value }) => Award.toHumanString(value),
        sortComparator: (v1, v2) => {
          if (v1 === null && v2 === null) {
            return 0;
          }
          if (v1 === null) {
            return -1;
          }
          if (v2 === null) {
            return 1;
          }
          return Award.compare(v1, v2);
        },
      }
    );

    const anyStudentHasAward = rows.some((student) => {
      const studentResult = results[student.id];
      return studentResult && studentResult.award;
    });

    if (anyStudentHasAward) {
      columns.push({
        headerName: 'Certificate',
        field: 'certificate',
        width: 100,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          const studentResult = results[params.row.id];
          if (studentResult && studentResult.award) {
            const requestedFileName = sanitizeFileName(params.row.name);
            return (
              <IconButton
                onClick={() => downloadStudentCertificate(params.row.id, requestedFileName)}
                aria-label="Download Certificate"
              >
                <Tooltip title="Download Certificate">
                  <DownloadIcon />
                </Tooltip>
              </IconButton>
            );
          } else {
            return "-";
          }
        },
      });
    }
  }

  if (displayRegistered) {
    columns.push({ headerName: 'Registered', sortable: false, field: 'registered', valueGetter: () => 'Yes' });
  }

  if (rows.some((student) => student.createdByMarker)) {
    columns.splice(1, 0, {
      headerName: 'Added in marking',
      flex: 3,
      field: 'createdByMarker',
      headerAlign: 'left',
      align: 'left',
      valueGetter: ({ row }) => row.createdByMarker,
      renderCell: ({ row }) => (row.createdByMarker ? 'Yes' : 'No'),
    });
  }

  if (editable) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: 'Unregister',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          // @ts-ignore
          <GridActionsCellItem
            icon={
              <Tooltip title="Unregister">
                <CloseIcon />
              </Tooltip>
            }
            label="Unregister"
            onClick={() => {
              if (onDeleteStudent !== undefined) {
                onDeleteStudent(id as UUID<Student>);
              }
            }}
            color="error"
          />,
        ];
      },
    });
  }

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      getRowId={(student) => student.id}
      density="compact"
      autoHeight
      pageSize={rows.length}
      hideFooter
      disableColumnMenu={results === undefined}
      disableColumnSelector
      disableSelectionOnClick
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        },
      }}
    />
  );
}
