import { Exam, Question } from "../../../domain/Season";
import { UUID } from "../../../domain/UUID";
import ExamLevel from "../../../domain/ExamLevel";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Checkbox, Stack, TableCell, TextField } from "@mui/material";
import { WorkingSeason } from "./useWorkingSeason";

interface Props {
  readonly workingSeason: WorkingSeason,
  readonly exam: Exam,
  readonly questionId: UUID<Question>,
  readonly editing: boolean
}

export default function LevelMarkCell({ workingSeason, exam, questionId, editing }: Props) {
  const question = exam.questions.find(q => questionId === q.id);
  const included = question !== undefined;

  const marksInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (included) {
      marksInputRef.current?.focus();
    }
  }, [included]);

  if (exam.level === ExamLevel.OTHER) {
    return (
      <TableCell align="center">
        <Checkbox checked disabled/>
      </TableCell>
    );
  }

  return (
    <TableCell>
      <Stack direction="row" sx={{ justifyContent: "center" }} spacing={0.5}>
        <Checkbox
          checked={included}
          disabled={!editing}
          onChange={e => {
            if (e.currentTarget.checked)
            {
              workingSeason.onAssignQuestion(questionId, exam.examId);
            }
            else
            {
              workingSeason.onUnassignQuestion(questionId, exam.examId);
            }
          }}
        />
        <TextField
          defaultValue={question?.maximumMarks}
          size="small"
          label="marks"
          disabled={!editing}
          onBlur={e => workingSeason.onChangeAssignedMarks(questionId, exam.examId, parseInt(e.currentTarget.value))}
          sx={{ width: 60, mt: 0.5, mb: 0.5, visibility: included ? "default" : "hidden" }}
          InputProps={{ inputProps: { sx: { textAlign: "center" } } }}
          inputRef={marksInputRef}
        />
      </Stack>
    </TableCell>
  );
}
