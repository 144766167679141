import * as React from 'react';
import { ROUTE_DASHBOARD } from "../../UkloRoutes";
import { Link as RouterLink } from "react-router-dom";
import { Link as MaterialLink, Stack } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


export default function GoHome() {
  return (
    <MaterialLink component={RouterLink} to={ROUTE_DASHBOARD}>
      <Stack direction="row" spacing={1} alignItems="center">
        <ChevronLeftIcon />
        Home
      </Stack>
    </MaterialLink>
  );
}
