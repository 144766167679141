import { UUID } from '../domain/UUID';
import axios from 'axios';
import { Exam } from "../domain/Season";
import MarkingCapacity, { Marker } from "../domain/MarkingCapacity";
import { MarkingAssignment, MarkingState } from "../domain/MarkingAssignment";
import Centre from "../domain/Centre";
import Account from "../domain/Account";

interface MarkerListResponse {
  readonly markers: Marker[],
}

interface AssignmentListResponse {
  readonly assignments: MarkingAssignment[],
}

export function getMarkingCapacity(examId: UUID<Exam>): Promise<MarkingCapacity> {
  return axios.get<MarkingCapacity>(`/marking/capacity/${examId}`).then(({ data }) => data);
}

export function putMarkingCapacity(examId: UUID<Exam>, capacity: MarkingCapacity): Promise<MarkingCapacity> {
  return axios.put<MarkingCapacity>(`/marking/capacity/${examId}`, capacity).then(({ data }) => data);
}

export function getAllMarkers(examId: UUID<Exam>): Promise<Marker[]> {
  return axios.get<MarkerListResponse>(`/marking/markers/${examId}`).then(({ data }) => data.markers);
}

export function getMyAssignments(examId: UUID<Exam>): Promise<MarkingAssignment[]> {
  return axios.get<AssignmentListResponse>(`/marking/assignments/me/${examId}`).then(({ data }) => data.assignments);
}

export function getAllAssignments(examId: UUID<Exam>): Promise<MarkingAssignment[]> {
  return axios.get<AssignmentListResponse>(`/marking/assignments/${examId}`).then(({ data }) => data.assignments);
}

export function putAssignment(examId: UUID<Exam>, centreId: UUID<Centre>, markerId: UUID<Account>): Promise<MarkingAssignment> {
  return axios.put<MarkingAssignment>(`/marking/assignments/${examId}/${centreId}/${markerId}`).then(({ data }) => data);
}

export function putMarkingState(examId: UUID<Exam>, centreId: UUID<Centre>, state: MarkingState): Promise<MarkingAssignment> {
  return axios.put<MarkingAssignment>(`/marking/assignment/state/${examId}/${centreId}/${state}`).then(({ data }) => data);
}

export function deleteAssignment(examId: UUID<Exam>, centreId: UUID<Centre>): Promise<void> {
  return axios.delete(`/marking/assignments/${examId}/${centreId}`);
}

export function createAllAssignments(examId: UUID<Exam>): Promise<MarkingAssignment[]> {
  return axios.put<AssignmentListResponse>(`/marking/assignments/all/${examId}`).then(({ data }) => data.assignments);
}

export function deleteAllAssignments(examId: UUID<Exam>): Promise<void> {
  return axios.delete(`/marking/assignments/all/${examId}`);
}
