import * as React from "react";
import { Stack, Typography } from "@mui/material";

interface Props {
  readonly icon: React.ReactNode;
  readonly text: string;
}

export default function IconCardTitle({ icon, text }: Props) {
  return (
    <Stack direction="row" spacing={1.5} alignItems="center" sx={{ pl: 0.5 }}>
      {icon}
      <Typography variant="h5">{text}</Typography>
    </Stack>
  );
}
