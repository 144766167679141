import * as React from 'react';
import Page from '../common/Page';
import { useCurrentSeason } from "../exams/SeasonContext";
import Centre from "../../domain/Centre";
import { ROUTE_DASHBOARD, useUUIDParam } from "../../UkloRoutes";
import exhaustiveGuard from "../common/exhaustiveGuard";
import AssignedMarkerMarkingPage from "./AssignedMarkerMarkingPage";
import { useNavigate } from "react-router-dom";
import SeasonState from "../../domain/SeasonState";
import CentreManagerMarkingPage from "./CentreManagerMarkingPage";

export enum MarkingMode {
  ASSIGNED_MARKER = 'm', // Marked by a UKLO marker
  CENTRE_MANAGER = 'c' // Lower exams, marked by a centre manager
}

type Props = {
  readonly mode: MarkingMode,
}

export default function MarkingPage({ mode }: Props) {
  const { currentSeason, currentAdvancedExam } = useCurrentSeason();
  const navigate = useNavigate();
  const centreId = useUUIDParam<Centre>();

  if (currentSeason === undefined || currentAdvancedExam === undefined) {
    return null;
  }

  switch (mode) {
    case MarkingMode.ASSIGNED_MARKER:
      if (currentSeason.state !== SeasonState.ROUND_1_ADVANCED_MARKING) {
        navigate(ROUTE_DASHBOARD);
        return null;
      }
      break;
    case MarkingMode.CENTRE_MANAGER:
      if (!SeasonState.isBetween(currentSeason.state, SeasonState.ROUND_1_ADVANCED_MARKING, SeasonState.COMPLETE)) {
        navigate(ROUTE_DASHBOARD);
        return null;
      }
      break;
    default:
      exhaustiveGuard(mode);
  }

  if (currentAdvancedExam.questions.length === 0) {
    return <Page title="This exam isn't ready to mark yet." main />
  }

  switch (mode) {
    case MarkingMode.ASSIGNED_MARKER:
      return <AssignedMarkerMarkingPage season={currentSeason} examId={currentAdvancedExam.examId}
                                        centreId={centreId} />;
    case MarkingMode.CENTRE_MANAGER:
      return <CentreManagerMarkingPage season={currentSeason} centreId={centreId} />;
    default:
      exhaustiveGuard(mode);
  }
}
