import * as React from "react";
import { Paper, Typography } from "@mui/material";
import SetupCentreCard from "../centres/setup/SetupCentreCard";
import CentreDetailsCard from "../centres/CentreDetailsCard";
import { useNavigate } from "react-router-dom";
import { ROUTE_CENTRE } from "../../UkloRoutes";
import CentreManagement from "../centres/CentreManagement";
import { useManagedCentres } from "../centres/ManagedCentresContext";

export default function CentreSection() {
  const { managedCentres } = useManagedCentres();
  const navigate = useNavigate();

  if (managedCentres === undefined) {
    return <Typography>Loading</Typography>;
  }

  if (managedCentres.length === 0) {
    return <SetupCentreCard />;
  }

  if (managedCentres.length === 1) {
    return (
      <Paper variant="outlined">
        <CentreManagement centreId={managedCentres[0].id} />
      </Paper>
    );
  }

  return (
    <>
      {managedCentres.map(c => (
        <CentreDetailsCard
          key={c.id} centre={c}
          onAction={() => navigate(ROUTE_CENTRE(c.id))}
          actionText="Manage centre"
        />
      ))}
    </>
  );
}
