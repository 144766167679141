import * as React from 'react';
import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import FormInputText from "../../form/FormInputText";
import { WorkingSeason } from "./useWorkingSeason";
import randomUUID from "../../common/randomUUID";

interface Props {
  readonly workingSeason: WorkingSeason
}

interface AddQuestionFormData {
  readonly number: number;
  readonly name: string;
  readonly points: number | '';
}

export default function AddQuestionForm({ workingSeason }: Props) {
  let nextNumber = workingSeason.seasonView.questions.length === 0
    ? 1
    : workingSeason.seasonView.questions[workingSeason.seasonView.questions.length - 1].number + 1;

  const { control, handleSubmit, reset, setFocus, setValue } = useForm<AddQuestionFormData>({
    defaultValues: {
      number: nextNumber,
      name: '',
      points: ''
    }
  });

  const onSubmit = handleSubmit(({ number, name, points }) => {
    if (name === '' || number === 0) {
      return;
    }

    // @ts-ignore sometimes the form input is a string, despite the types
    number = parseInt(number);
    // @ts-ignore
    points = points === '' ? 0 : parseInt(points);

    if (isNaN(number) || isNaN(points)) {
      return;
    }

    workingSeason.onAddQuestion({
      id: randomUUID(),
      name,
      number,
      maximumPoints: points
    });

    setFocus('name', { shouldSelect: true });
    reset();
    setValue('number', number + 1);
  });

  return (
    <form onSubmit={onSubmit} noValidate>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} sx={{ alignItems: { xs: 'flex-start', sm: 'center' } }}>
        <Typography>
          New question:
        </Typography>
        <FormInputText
          name="number"
          label="#"
          control={control}
          rules={{ required: true }}
          sx={{ width: 70 }}
        />
        <FormInputText
          name="name"
          label="Name"
          control={control}
          rules={{ required: true }}
          sx={{ width: 240 }}
        />
        <FormInputText
          name="points"
          label="Points"
          control={control}
          sx={{ width: 100 }}
        />
        <Button type="submit" variant="text" startIcon={<AddIcon/>}>
          Add
        </Button>
      </Stack>
    </form>
  );
}
