import React, { useEffect, useState } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import { Box, Paper } from '@mui/material';
import EditorToolbar from './EditorToolbar';
import Link from '@tiptap/extension-link';

interface Props {
  readonly onChange: (html: string) => void;
  readonly lastReset: number;
}

export default function Editor({ onChange, lastReset }: Props) {
  const [showRawHtml, setShowRawHtml] = useState(false);
  const [rawHtml, setRawHtml] = useState('');

  const editor = useEditor({
    extensions: [StarterKit, Underline, Link.extend({ inclusive: false })],
    content: rawHtml,
    onUpdate: ({ editor }) => {
      setRawHtml(editor.getHTML());
      onChange(editor.getHTML());
    },
  });

  if (!editor) {
    return null;
  }

  const handleToggleRawHtml = () => {
    setShowRawHtml((prev) => !prev);
    if (showRawHtml) {
      editor.chain().focus().setContent(rawHtml).run();
    }
  };

  const handleEditorFocus = () => {
    if (!showRawHtml && editor) {
      editor.chain().focus().run();
    }
  };

  useEffect(() => {
    if (lastReset > 0 && editor) {
      editor.chain().focus().clearContent().run();
      setRawHtml('');
    }
  }, [lastReset]);

  return (
    <>
      <EditorToolbar
        editor={editor}
        showRawHtml={showRawHtml}
        onToggleRawHtml={handleToggleRawHtml}
      />
      <Box sx={{ '& *:focus': { outline: 'none' }, m: 0, p: 0 }}>
        {showRawHtml ? (
          <textarea
            value={rawHtml}
            onChange={(e) => {
              setRawHtml(e.target.value);
              onChange(e.target.value);
            }}
            style={{
              width: '100%',
              minHeight: '280px',
              padding: '16px',
              fontFamily: 'monospace',
              fontSize: '14px',
              border: '1px solid #ddd',
              borderRadius: '4px',
              margin: '0',
              resize: 'vertical',
            }}
          />
        ) : (
          <Paper
            variant="outlined"
            sx={{ px: 2, py: 0, borderRadius: 1, minHeight: '280px' }}
            onClick={handleEditorFocus}
          >
            <EditorContent editor={editor} />
          </Paper>
        )}
      </Box>
    </>
  );
}
