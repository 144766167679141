import React, { useMemo } from "react";
import { useTheme } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import { CircularProgress, Typography, Box } from "@mui/material";
import StudentExamResult from "../../../domain/StudentExamResult";
import Award from "../../../domain/Award";

interface Props {
  readonly binCount?: number;
  readonly results?: StudentExamResult[];
}

export default function ResultsHistogram({ binCount = 100, results }: Props) {
  const theme = useTheme();

  const binData = useMemo(() => {
    if (!results) return [];

    const percentages = results.map((result) => parseFloat(result.percentage));

    const max = Math.max(...percentages);
    const binWidth = Math.ceil(max / binCount);
    const bins = Array.from({ length: binCount }, () => ({
      [Award.GOLD]: 0,
      [Award.SILVER]: 0,
      [Award.BRONZE]: 0,
      other: 0,
    }));

    results.forEach((result) => {
      const percentage = parseFloat(result.percentage);
      const binIndex = Math.min(Math.floor(percentage / binWidth), binCount - 1);
      const awardCategory = result.award ?? "other";
      if (awardCategory in bins[binIndex]) {
        (bins[binIndex] as Record<string, number>)[awardCategory]++;
      } else {
        bins[binIndex].other++;
      }
    });

    return bins.map((bin, index) => ({
      binLabel: `${index * binWidth}–${(index + 1) * binWidth}%`,
      ...bin,
    }));
  }, [results, binCount]);

  if (!results) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height={360}
      >
        <CircularProgress />
        <Typography variant="caption" mt={2}>
          Loading results...
        </Typography>
      </Box>
    );
  }

  const anyNonOtherAwardsExist = binData.some((bin) => Object.entries(bin).some(([key, value]) => key !== "other" && value > 0));

  return (
    <BarChart
      xAxis={[{
        scaleType: "band",
        data: binData.map((bin) => bin.binLabel),
        label: "Percentage Range",
      }]}
      yAxis={[{
        label: "Number of Students",
      }]}
      series={[
        {
          data: binData.map((bin) => bin.other),
          label: "Participation",
          color: theme.palette.primary.main,
          stack: "awards",
        },
        {
          data: binData.map((bin) => bin[Award.BRONZE]),
          label: "Bronze",
          color: "#CD7F32",
          stack: "awards",
        },
        {
          data: binData.map((bin) => bin[Award.SILVER]),
          label: "Silver",
          color: "#B0BEC5",
          stack: "awards",
        },
        {
          data: binData.map((bin) => bin[Award.GOLD]),
          label: "Gold",
          color: "#FFC400",
          stack: "awards",
        },
      ]}
      height={430}
      skipAnimation
      slotProps={{
        legend: { hidden: !anyNonOtherAwardsExist }
      }}
    />
  );
}
