import * as React from "react";
import { MarkingAssignment, MarkingState } from "../../domain/MarkingAssignment";
import { Alert, Box, Card, CardActionArea, CardContent, CardHeader, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ROUTE_MARKING } from "../../UkloRoutes";


import { MarkingMode } from "./MarkingPage";

interface Props {
  readonly markingAssignment: MarkingAssignment;
  readonly allowNavigation: boolean;
}

export default function AssignedCentre({ markingAssignment, allowNavigation }: Props) {
  const cardBody = (
    <>
      <CardHeader
        title={markingAssignment.centre.name}
        subheader={markingAssignment.centre.postcode}
        sx={{ pl: 2, pr: 2, pt: 2, pb: 0.5 }}
        titleTypographyProps={{ variant: "h6" }}
        subheaderTypographyProps={{ variant: "subtitle2" }}
      />
      <CardContent sx={{ pt: 1, pl: 2, pr: 2, pb: 2 }}>
        {markingAssignment.markingState === MarkingState.FINISHED
          ? <Alert severity="success">Finished marking</Alert>
          : (
            <Box>
              <Typography variant="body2">Registered students: {markingAssignment.registeredStudents}</Typography>
              <Typography variant="body2">Estimated students: {markingAssignment.estimatedStudents}</Typography>
            </Box>
          )}
      </CardContent>
    </>
  );

  return (
    <Card variant="outlined" sx={{ height: "100%" }} >
      {allowNavigation
        ? (
          <CardActionArea
            component={RouterLink}
            to={ROUTE_MARKING(markingAssignment.centre.id, MarkingMode.ASSIGNED_MARKER)}
            sx={{ height: "100%" }}
          >
            {cardBody}
          </CardActionArea>
        )
        : cardBody
      }
    </Card>
  );
}
