import React, { useEffect, useState } from "react";
import Page from "../../common/Page";
import { Button, ButtonGroup, Stack, Typography } from "@mui/material";
import ExamLevel from "../../../domain/ExamLevel";
import { useUUIDParam } from "../../../UkloRoutes";
import Season from "../../../domain/Season";
import { getSeason } from "../../../gateway/exams";
import StudentExamResult from "../../../domain/StudentExamResult";
import { getAllResults } from "../../../gateway/results";
import Grid from "@mui/material/Unstable_Grid2";
import ResultsHistogram from "./ResultsHistogram";
import ExamAwardBoundariesCard from "./ExamAwardBoundariesCard";
import ResultsTable from "./ResultsTable";
import SeasonState from "../../../domain/SeasonState";
import SuggestedBoundariesCard from "./SuggestedBoundariesCard";
import { getAwardBoundaries } from "../../../gateway/awardBoundaries";

export default function BoundariesPage() {
  const seasonId = useUUIDParam<Season>();
  const [season, setSeason] = useState<Season | undefined>(undefined);
  const [selectedExamLevel, setSelectedExamLevel] = useState<ExamLevel>(ExamLevel.ADVANCED);
  const [results, setResults] = useState<StudentExamResult[] | undefined>();
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date());
  const [examHasBoundaries, setExamHasBoundaries] = useState<boolean>(true);

  useEffect(() => {
    getSeason(seasonId).then(setSeason);
  }, [seasonId]);

  useEffect(() => {
    setResults(undefined);
    setExamHasBoundaries(true);

    if (season === undefined || !(selectedExamLevel in season.exams)) {
      return;
    }

    getAllResults(season.exams[selectedExamLevel].examId)
      .then(results => Object.values(results))
      .then(results => results.sort((a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)))
      .then(results => setResults(results));
    getAwardBoundaries(season.exams[selectedExamLevel].examId).then((boundaries) => {
      setExamHasBoundaries(boundaries !== undefined && Object.keys(boundaries.awardToThreshold).length > 0);
    });
  }, [selectedExamLevel, season, lastUpdated]);

  if (season === undefined) {
    return <Page
      title="Award boundaries"
      main
    >
      <Typography>Loading...</Typography>
    </Page>;
  }

  const exam = season.exams[selectedExamLevel];

  return (
    <Page
      title="Award boundaries"
      subtitle={`${season.year}`}
      main
    >
      <Grid container spacing={1}>
        <Grid xs={12} md={8}>
          <Stack spacing={1}>
            <ButtonGroup variant="outlined" color="primary" disableElevation>
              {ExamLevel.roundOneValues()
                .filter(level => level !== ExamLevel.OTHER && level in season.exams)
                .map(level => (
                  <Button
                    key={level}
                    onClick={() => setSelectedExamLevel(level)}
                    variant={selectedExamLevel === level ? "contained" : "outlined"}
                  >
                    {ExamLevel.pretty(level)}
                  </Button>
                ))}
            </ButtonGroup>
            <ResultsHistogram results={results} />
          </Stack>
        </Grid>
        <Grid xs={12} md={4}>
          <Stack spacing={1}>
            {SeasonState.isBefore(season.state, SeasonState.COMPLETE) && !examHasBoundaries && <SuggestedBoundariesCard results={results} />}
            <ExamAwardBoundariesCard
              examId={exam.examId}
              editable={SeasonState.isBefore(season.state, SeasonState.COMPLETE)}
              results={results}
              invalidate={() => setLastUpdated(new Date())}
            />
          </Stack>
        </Grid>
        <Grid xs={12}>
          <ResultsTable exam={exam} results={results} />
        </Grid>
      </Grid>
    </Page>
  );
}
