import * as React from 'react';
import { useEffect, useState } from 'react';
import { useUUIDParam } from "../../UkloRoutes";
import Season from "../../domain/Season";
import { getSeason } from "../../gateway/exams";
import Page from "../common/Page";
import QuestionManagement from "./questions/QuestionManagement";

export default function SeasonManagementPage() {
  const seasonId = useUUIDParam<Season>();
  const [season, setSeason] = useState<Season | undefined>(undefined);

  useEffect(() => {
    getSeason(seasonId).then(setSeason);
  }, [seasonId]);

  if (season === undefined) {
    return null;
  }

  return (
    <Page title={`Manage ${season.year} season`} main>
      <QuestionManagement season={season} />
    </Page>
  );
}
