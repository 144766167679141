import * as React from "react";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Control, Controller, Path, useFormState } from "react-hook-form";
import ValidationRules from "./ValidationRules";

interface Option {
  readonly value: string,
  readonly label: string
}

interface Props<T> {
  readonly control: Control<T>,
  readonly name: Path<T>,
  readonly label: string,
  readonly options: Option[] | string[],
  readonly required?: boolean,
  readonly size?: 'small' | 'medium',
  readonly rules?: ValidationRules,
  readonly sx?: any
}

export default function FormInputSelect<T>({ control, name, label, options, required, size = 'small', rules, sx }: Props<T>) {
  const { errors } = useFormState({ control });
  // @ts-ignore
  const error = errors[name];

  const objectOptions: Option[] = options.map(option => typeof option === 'string' ? {
    value: option,
    label: option
  } : option);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControl size={size} sx={sx} error={error} required={required}>
          <FormLabel id={name}>{label}</FormLabel>
          <RadioGroup
            row
            aria-labelledby={name}
            name={name}
            value={field.value}
            onChange={(e, value) => field.onChange(value)}
            onBlur={field.onBlur}
          >
            {objectOptions.map(option => (
              <FormControlLabel key={option.value.toString()} value={option.value} control={<Radio />} label={option.label} />
            ))}
          </RadioGroup>
          {error?.message && <FormHelperText error={true}>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
