enum CentreType {
  STATE_FUNDED = 'STATE_FUNDED',
  FEE_PAYING = 'FEE_PAYING',
  HOME_SCHOOL = 'HOME_SCHOOL',
  OVERSEAS = 'OVERSEAS'
}

const CENTRE_STRINGS: { [K in CentreType]: string } = {
  STATE_FUNDED: "State funded",
  FEE_PAYING: "Fee-paying",
  HOME_SCHOOL: "Homeschool",
  OVERSEAS: "Overseas"
}

namespace CentreType {
  export function values(): CentreType[] {
    return [CentreType.STATE_FUNDED, CentreType.FEE_PAYING, CentreType.HOME_SCHOOL, CentreType.OVERSEAS]
  }

  export function pretty(type: CentreType): string {
    return CENTRE_STRINGS[type];
  }
}

export default CentreType;
