import * as React from 'react';
import { useEffect } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from "react-hook-form";
import FormInputText from "../../form/FormInputText";

interface Props {
  readonly open: boolean,
  readonly initialEstimate: number,
  readonly dismiss: () => void,
  readonly save: (capacity: number) => void,
}

interface StudentEstimateFormData {
  readonly estimate: number
}

export default function StudentEstimateDialog({ open, initialEstimate, dismiss, save }: Props) {
  const { control, handleSubmit, setValue } = useForm<StudentEstimateFormData>({
    defaultValues: {
      estimate: initialEstimate
    }
  });

  useEffect(() => {
    if (open) {
      setValue('estimate', initialEstimate);
    }
  }, [open]);

  const onSubmit = handleSubmit(({ estimate }) => save(estimate));

  return (
    <Dialog open={open} onClose={dismiss} fullWidth>
      <form onSubmit={onSubmit} noValidate>
        <DialogTitle>
          Update estimated students
        </DialogTitle>
        <DialogContent dividers>
          <Stack direction="column" spacing={2}>
            <Alert severity="info">Please only estimate for the <strong>Advanced</strong> exam.</Alert>
            <FormInputText
              name="estimate"
              label="Estimate"
              control={control}
              fullWidth
              rules={{
                required: "Please input an estimate",
                min: { value: 0, message: "Your estimate cannot be less than 0" }
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button onClick={dismiss}>Cancel</Button>
          <Button type="submit" onClick={onSubmit} variant="contained" startIcon={<SaveIcon />} disableElevation>Save estimate</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
