import { Question } from "../../../domain/Season";
import { UUID } from "../../../domain/UUID";
import ExamLevel from "../../../domain/ExamLevel";
import * as React from "react";
import EditableQuestionRow from "./EditableQuestionRow";
import ReadonlyQuestionRow from "./ReadonlyQuestionRow";
import { WorkingSeason } from "./useWorkingSeason";

export interface QuestionRowProps {
  readonly workingSeason: WorkingSeason,
  readonly questionId: UUID<Question>,
  readonly levels: ExamLevel[],
}

interface Props extends QuestionRowProps {
  readonly editing: boolean
}

export default function QuestionRow({ editing, ...other }: Props) {
  return editing ? <EditableQuestionRow {...other} /> : <ReadonlyQuestionRow {...other} />;
}
