import * as React from "react";
import { useEffect, useState } from "react";
import { Alert, Button, Card, CardContent, CardHeader } from "@mui/material";
import Season, { Exam } from "../../../domain/Season";
import StudentEstimateDialog from "./StudentEstimateDialog";
import { getEstimatedStudents, putEstimatedStudents } from "../../../gateway/students";
import Centre from "../../../domain/Centre";
import { UUID } from "../../../domain/UUID";
import SuccessText from "../../common/SuccessText";

interface Props {
  readonly season: Season,
  readonly exam: Exam,
  readonly centreId: UUID<Centre>,
  readonly stale: Date,
}

export default function StudentEstimate({ season, exam, centreId, stale }: Props) {
  const [estimate, setEstimate] = useState<number>(-1);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    getEstimatedStudents({ examId: exam.examId, centreId }).then(e => setEstimate(e.estimate));
  }, [exam.examId, centreId, stale]);

  const handleEstimate = (estimate: number) => {
    putEstimatedStudents({examId: exam.examId, centreId }, { estimate })
      .then(e => setEstimate(e.estimate))
      .then(() => setShowDialog(false));
  }

  const studentEstimateSubHeader = estimate === 0
    ? 'You have not yet estimated how many students will sit this exam.'
    : <SuccessText text={`You estimate that ${estimate} student${estimate !== 1 ? 's' : ''} will sit ${Exam.name(season, exam)}.`} />;

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          subheader={studentEstimateSubHeader}
          action={
            <Button onClick={() => setShowDialog(true)}>Update estimate</Button>
          }
        />
        {estimate === 0 && (
          <CardContent sx={{ pt: 0 }}>
            <Alert severity="info">You can help us ensure that we have enough markers by estimating how many students will take the advanced exam this year.</Alert>
          </CardContent>
        )}
      </Card>
      <StudentEstimateDialog open={showDialog} initialEstimate={estimate} dismiss={() => setShowDialog(false)} save={handleEstimate} />
    </>
  );
}
