import * as React from 'react';
import { useEffect, useState } from 'react';
import Page from '../common/Page';
import { SeasonMetadata } from "../../domain/Season";
import { getAllSeasons, postNewSeason } from "../../gateway/exams";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { Box, Stack } from "@mui/material";
import SeasonCard from "./SeasonCard";
import { useCurrentSeason } from "./SeasonContext";
import SeasonState from "../../domain/SeasonState";

export default function SeasonsPage() {
  const { invalidate: invalidateCurrentSeason, currentSeason } = useCurrentSeason();
  const [seasons, setSeasons] = useState<SeasonMetadata[]>([]);

  const invalidate = () => {
    invalidateCurrentSeason().then(() => {
      getAllSeasons().then(setSeasons);
    });
  }

  useEffect(() => {
    getAllSeasons().then(setSeasons);
  }, []);

  const nextSeasonYear = seasons.length > 0 ? seasons[0].year + 1 : new Date().getFullYear();

  const createNewSeason = () => {
    postNewSeason(nextSeasonYear).then(invalidate);
  }

  return (
    <Page title="Manage seasons" main>
      <Stack direction="column" spacing={1}>
        {SeasonState.COMPLETE === currentSeason?.state && (
          <Box>
            <Button type="submit" variant="outlined" startIcon={<AddIcon/>} onClick={createNewSeason}>
              Create {nextSeasonYear} season
            </Button>
          </Box>
        )}
        {seasons.map(season => (
          <SeasonCard
            key={season.id}
            season={season}
            onChange={invalidate}
            displaySeasonDescription={season.id === currentSeason?.id}
          />
        ))}
      </Stack>
    </Page>
  );
}
