import * as React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import ExamLevel from "../../../domain/ExamLevel";
import QuestionRow from "./QuestionRow";
import { WorkingSeason } from "./useWorkingSeason";

interface Props {
  readonly workingSeason: WorkingSeason,
  readonly editing: boolean
}

export default function ({ workingSeason, editing }: Props) {
  const levels = Object.keys(workingSeason.seasonView.exams)
    .map(level => level as ExamLevel);

  levels.sort(ExamLevel.comparator(true));

  if (workingSeason.seasonView.questions.length == 0)
  {
    return <Typography>No questions yet</Typography>;
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="right">#</TableCell>
          <TableCell>Name</TableCell>
          <TableCell align="right">Points</TableCell>
          {levels.map(level => (
            <TableCell key={level} align="center">{ExamLevel.pretty(level as ExamLevel)}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {workingSeason.seasonView.questions.map(q => (
          <QuestionRow key={q.id} workingSeason={workingSeason} questionId={q.id} levels={levels} editing={editing}/>
        ))}
      </TableBody>
    </Table>
  );
}
