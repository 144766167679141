import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { postLogin } from '../../gateway/accounts';
import { Alert } from '@mui/material';
import { useAccount } from './AccountContext';

export default function LoginForm() {
  const { onLogin } = useAccount();
  const [error, setError] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget as typeof event.currentTarget & {
      email: { value: string };
      password: { value: string };
    };

    postLogin(form.email.value, form.password.value)
      .then(onLogin)
      .catch(() => setError(true));
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        Welcome back
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        {error && <Alert severity="error" sx={{ mt: 1, mb: 2 }}>Please check your email address and password.</Alert>}
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email address"
          name="email"
          autoComplete="email"
          autoFocus
          error={error}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          error={error}
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" defaultChecked />}
          label="Keep me logged in"
        />
        <Button
          type="submit"
          size="large"
          fullWidth
          variant="contained"
          disableElevation
          sx={{ mt: 2, mb: 2 }}
        >
          Log in
        </Button>
      </Box>
    </>
  );
}
