import * as React from 'react';
import { Box, CircularProgress, CircularProgressProps, Typography, Zoom } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  readonly value: number;
  readonly incomplete: boolean;
}

export default function CircularProgressWithLabel({ value, incomplete }: Props) {
  const progress = Math.min(100, Math.max(0, value));
  const done = value >= 99.9 && !incomplete;

  return (
    <>
      <Zoom in={done}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 40, width: 40 }}>
          <CheckIcon color="success" fontSize="large" />
        </Box>
      </Zoom>
      {!done && createProgressCircle(progress)}
    </>
  );
}

function createProgressCircle(progress: number) {
  const displayNumber = progress < 99.9;

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', height: 40, width: 40 }}>
      {displayNumber
        ? <CircularProgress variant="determinate" value={progress} />
        : <CircularProgress variant="indeterminate" sx={{ p: 0.5 }} />
      }
      {displayNumber && (
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >
            {Math.round(progress)}%
          </Typography>
        </Box>
      )}
    </Box>
  );
}
