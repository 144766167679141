import * as React from "react";
import Centre from "../../../domain/Centre";
import { UUID } from "../../../domain/UUID";
import { useCurrentSeason } from "../../exams/SeasonContext";
import { Typography } from "@mui/material";
import SeasonState from "../../../domain/SeasonState";
import RegisterStudentsSection from "./RegisterStudentsSection";
import StudentResultsSection from "./StudentResultsSection";

interface Props {
  readonly centreId: UUID<Centre>
}

export default function StudentsSection({ centreId }: Props) {
  const { currentSeason } = useCurrentSeason();

  if (currentSeason === undefined) {
    return <Typography>Loading</Typography>;
  }

  return currentSeason.state === SeasonState.REGISTRATION
    ? <RegisterStudentsSection season={currentSeason} centreId={centreId} />
    : <StudentResultsSection season={currentSeason} centreId={centreId} />;
}
