enum Award {
  PARTICIPATION = 'PARTICIPATION',
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD'
}

const AWARD_STRINGS: { [K in Award]: string } = {
  PARTICIPATION: "Participation",
  BRONZE: "Bronze",
  SILVER: "Silver",
  GOLD: "Gold"
};

namespace Award {
  export function fromString(awardString: string | null): Award | null {
    if (!awardString) {
      return null;
    }

    const upperCaseAward = awardString.toUpperCase() as Award;
    return Object.values(Award).includes(upperCaseAward) ? upperCaseAward : null;
  }

  export function toHumanString(award: Award | null): string {
    if (award === null) {
      return "-";
    }

    return AWARD_STRINGS[award];
  }

  export function getOrderedAwardsExcludingParticipation(): Award[] {
    return [Award.BRONZE, Award.SILVER, Award.GOLD];
  }

  export function getAllOrderedAwards(): Award[] {
    return [Award.PARTICIPATION, Award.BRONZE, Award.SILVER, Award.GOLD];
  }

  export function compare(a: Award, b: Award): number {
    return getAllOrderedAwards().indexOf(a) - getAllOrderedAwards().indexOf(b);
  }
}

export default Award;
