import * as React from 'react';
import SeasonState from "../../domain/SeasonState";
import { Box, Card, CardHeader } from "@mui/material";
import CardContentNoPadding from "../common/CardContentNoPadding";

interface Props {
  readonly seasonState: SeasonState,
  readonly label: "now" | "next";
}

interface SeasonStateCapabilities {
  readonly centreManagers: string[],
  readonly markers: string[],
}

const SEASON_STATE_CAPABILITIES: Record<SeasonState, SeasonStateCapabilities | string> = {
  UNPUBLISHED: "Only administrators can see this season.",
  REGISTRATION: {
    centreManagers: ["Estimate students for the Advanced exam", "Register students"],
    markers: ["Provide a marking capacity for the Advanced exam"],
  },
  ROUND_1_TEST_PERIOD: {
    centreManagers: ["Download all round 1 exam questions", "Upload Advanced scripts"],
    markers: ["See their assigned centre(s)"],
  },
  ROUND_1_ADVANCED_MARKING: {
    centreManagers: ["Download all round 1 exam questions", "Download non-Advanced solutions", "Input non-Advanced points for their own centre"],
    markers: [
      "See their assigned centre(s)",
      "Download Advanced questions and solutions",
      "Download Advanced scripts for their assigned centre(s)",
      "Input Advanced points for their assigned centre(s)"
    ]
  },
  ROUND_1_ADVANCED_RESULTS_VERIFICATION: {
    centreManagers: ["Download all round 1 exam questions", "Download non-Advanced solutions", "Input non-Advanced points for their own centre"],
    markers: [
      "No longer input points for their assigned centre(s)",
      "See their assigned centre(s)",
      "Download Advanced questions and solutions",
    ]
  },
  ROUND_1_ADVANCED_RESULTS_PUBLICATION: {
    centreManagers: [
      "See Advanced results for their own centre",
      "Download Advanced certificates for their own centre",
      "Download all round 1 exam questions",
      "Download non-Advanced solutions",
      "Input non-Advanced points for their own centre"
    ],
    markers: [
      "See their assigned centre(s)",
      "Download Advanced questions and solutions",
    ]
  },
  ROUND_1_RESULTS_PUBLICATION: {
    centreManagers: [
      "See all Round 1 results for their own centre",
      "Download all Round 1 certificates for their own centre",
      "Download all round 1 exam questions",
      "Download non-Advanced solutions",
      "Input non-Advanced points for their own centre"
    ],
    markers: [
      "See their assigned centre(s)",
      "Download Advanced questions and solutions",
    ]
  },
  ROUND_1_MATERIALS_PUBLICATION: "All results, certificates, questions, and solutions are available for download. Centre managers can still change non-Advanced points.",
  COMPLETE: "Exam season is complete and no further data related to it can be changed.",
}

export default function SeasonStateDescription({ seasonState, label }: Props) {
  const capabilities = SEASON_STATE_CAPABILITIES[seasonState];

  let body: JSX.Element | null;

  if (typeof capabilities === "string") {
    body = <p>{capabilities}</p>;
  } else {
    body = (
      <ul style={{ margin: 0, paddingInlineStart: '1em' }}>
        {capabilitiesDescription("Centre managers", capabilities.centreManagers, true)}
        {capabilitiesDescription("Markers", capabilities.markers)}
      </ul>
    );
  }

  let cardHeaderSx: any = { px: 2, py: 1.5 };
  if (label === "now") {
    cardHeaderSx = {...cardHeaderSx, bgcolor: 'primary.main', color: 'primary.contrastText' }
  } else {
    cardHeaderSx = {...cardHeaderSx, bgcolor: 'grey.200', color: 'text.primary' }
  }

  return (
    <Card variant="outlined" sx={{ height: '100%' }}>
      <CardHeader
        title={SeasonState.pretty(seasonState)}
        subheader={label}
        titleTypographyProps={{ variant: "h5", fontSize: "1.1em" }}
        subheaderTypographyProps={{
          variant: "caption",
          color: label === "now" ? "primary.contrastText" : "text.secondary",
          fontSize: '0.84em'
        }}
        sx={cardHeaderSx}
      />
      <CardContentNoPadding sx={{ fontSize: '0.9em' }}>
        <Box sx={{ p: 1.5 }}>
          {body}
        </Box>
      </CardContentNoPadding>
    </Card>
  );
}

function capabilitiesDescription(group: string, capabilities: string[] | undefined, includeMargin: boolean = false) {
  if (capabilities === undefined || capabilities.length === 0) {
    return null;
  }

  return (
    <li style={includeMargin ? { marginBottom: '0.5em' } : {}}>
      {group} can:
      <ul style={{ margin: 0, paddingInlineStart: '1em' }}>
        {capabilities.map(c => <li key={c}>{c}</li>)}
      </ul>
    </li>
  );
}
