import { Avatar, Chip, Stack, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import * as React from "react";

interface Props {
  readonly text: string;
  readonly ticked?: boolean;
  readonly selected?: boolean;
  readonly onClick?: () => void;
  readonly number: number;
}


export default function NumberedChip({ text, ticked = false, selected = false, onClick, number }: Props) {
  return <Chip
    label={(
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body2">{text}</Typography>
        {ticked && <CheckIcon fontSize="small" />}
      </Stack>
    )}
    clickable={!selected}
    color={selected ? "primary" : "default"}
    variant={selected ? "filled" : "outlined"}
    onClick={onClick}
    avatar={(
      <Avatar
        sx={{
          width: 24,
          height: 24,
          fontSize: "0.75rem",
          '&&': {
            backgroundColor: selected ? "primary.contrastText" : "grey.300",
            color: selected ? "primary.main" : "grey.900",
          }
        }}
      >
        {number}
      </Avatar>
    )}
  />
}
