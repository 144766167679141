import * as React from 'react';
import { useEffect, useState } from 'react';
import { UUID } from '../../domain/UUID';
import Centre from '../../domain/Centre';
import { getExam } from '../../gateway/exams';
import Student from '../../domain/Student';
import { getStudents } from '../../gateway/students';
import { getPoints } from '../../gateway/points';
import Season, { Exam, Question } from "../../domain/Season";
import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ResponsiveText from '../common/ResponsiveText';
import StudentExamPoints from "../../domain/StudentExamPoints";
import StudentPointsTableStudentRow from "./StudentPointsTableStudentRow";
import StudentPointsTableNewStudentRow from "./StudentPointsTableNewStudentRow";
import Typography from "@mui/material/Typography";

// Handle with care
// Make sure you understand everything in the other StudentPointsTable files before changing this

interface Props {
  readonly seasonId: UUID<Season>,
  readonly examId: UUID<Exam>,
  readonly centreId: UUID<Centre>,
  readonly readOnly?: boolean,
  readonly refreshStudentCounts?: () => Promise<any>,
}

interface QuestionHeaderProps {
  readonly question: Question
}

interface StudentPointsRow {
  readonly student: Student,
  readonly points: ReadonlyMap<UUID<Question>, number>,
  readonly scriptMissing: boolean,
}

function QuestionHeaderCell({ question }: QuestionHeaderProps) {
  return (
    <TableCell align="right" style={{ verticalAlign: "bottom" }}>
      <Stack direction="column" alignItems="flex-end">
        <ResponsiveText xs={`Q${question.number}`} md={`Q${question.number} ${question.name.split(' ')[0]}`} />
        <ResponsiveText xs={question.maximumPoints} md={`(max ${question.maximumPoints})`} variant="caption" noWrap />
      </Stack>
    </TableCell>
  );
}

export default function StudentPointsTable({
                                             seasonId,
                                             examId,
                                             centreId,
                                             readOnly = true,
                                             refreshStudentCounts = () => Promise.resolve()
                                           }: Props) {
  const [exam, setExam] = useState<Exam | undefined>(undefined);
  const [studentRows, setStudentRows] = useState<StudentPointsRow[] | undefined>(undefined);

  const refresh = () => {
    return refreshStudentCounts()
      .then(() => getExam(examId).then(setExam))
      .then(() => {
        return Promise.all([getStudents({ examId, centreId }), getPoints({ examId, centreId })])
          .then(joinStudentsAndPoints)
          .then(studentRows => (
            // put students created by marker at the bottom, and sort the rest by name
            studentRows.sort((a, b) => {
              if (a.student.createdByMarker === b.student.createdByMarker) {
                return a.student.name.localeCompare(b.student.name);
              }

              return a.student.createdByMarker ? 1 : -1;
            })
          ))
          .then(setStudentRows);
      });
  }

  useEffect(() => {
    refresh()
  }, [examId, centreId, readOnly]);

  if (exam === undefined) {
    return null;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: { xs: 100, md: 200, lg: 300 } }}>Student</TableCell>
            {exam.questions.map(question => <QuestionHeaderCell key={question.id} question={question} />)}
            <TableCell align="center">
              <ResponsiveText xs="Missing?" md="Script missing?" noWrap />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {studentRows === undefined
            ? (
              <TableRow>
                <TableCell colSpan={exam.questions.length + 2} align="center" sx={{ py: 2 }}>
                  <Typography variant="body2" color="textSecondary">Loading</Typography>
                </TableCell>
              </TableRow>
            ) : (
              studentRows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={exam.questions.length + 2} align="center" sx={{ py: 2 }}>
                    <Typography variant="body2" color="textSecondary">No registered students</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                studentRows.map(studentRow => (
                  <StudentPointsTableStudentRow
                    key={studentRow.student.id}
                    exam={exam}
                    student={studentRow.student}
                    points={studentRow.points}
                    scriptMissing={studentRow.scriptMissing}
                    refresh={refresh}
                    readOnly={readOnly}
                  />
                ))
              )
            )}
          {!readOnly && (
            <StudentPointsTableNewStudentRow
              examId={examId}
              seasonId={seasonId}
              centreId={centreId}
              refresh={refresh}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function joinStudentsAndPoints([students, allStudentPoints]: [Student[], StudentExamPoints[]]): StudentPointsRow[] {
  const pointsByStudent: Map<UUID<Student>, StudentExamPoints> = new Map();

  for (let studentPoints of allStudentPoints) {
    pointsByStudent.set(studentPoints.studentId, studentPoints)
  }

  return students.map(student => {
    const studentPoints = pointsByStudent.get(student.id);
    if (studentPoints === undefined) {
      return { student, points: new Map(), scriptMissing: false };
    }

    return { student, points: studentPoints.points, scriptMissing: studentPoints.scriptMissing };
  });
}
