import * as React from 'react';
import { useCallback, useState } from 'react';
import { UUID } from '../../domain/UUID';
import Student from '../../domain/Student';
import { Exam, Question } from "../../domain/Season";
import { Box, Checkbox, FormControlLabel, IconButton, Stack, TableCell, TableRow, Typography, alpha } from "@mui/material";
import StudentPointsTablePointsInput from "./StudentPointsTablePointsInput";
import { putPoints } from "../../gateway/points";
import { removeStudent } from "../../gateway/students";
import useDidMountEffect from "../common/useDidMountEffect";
import { debounce } from "lodash";
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from "@mui/material/colors";

interface Props {
  readonly exam: Exam,
  readonly student: Student,
  readonly points: ReadonlyMap<UUID<Question>, number>,
  readonly scriptMissing: boolean,
  readonly readOnly: boolean,
  readonly refresh: () => Promise<any>,
}

export default function StudentPointsTableStudentRow({
                                                       exam,
                                                       student,
                                                       points,
                                                       scriptMissing,
                                                       readOnly,
                                                       refresh
                                                     }: Props) {
  const [deletingStudent, setDeletingStudent] = useState<boolean>(false);
  const [pointsState, setPointsState] = useState<ReadonlyMap<UUID<Question>, number>>(points);
  const [scriptMissingState, setScriptMissingState] = useState<boolean>(scriptMissing);
  const [shouldPutPoints, setShouldPutPoints] = useState<boolean>(false);

  const debouncedPutPoints = useCallback(debounce(
    ({ e, s, p, sm }: { e: UUID<Exam>, s: UUID<Student>, p: ReadonlyMap<UUID<Question>, number>, sm: boolean }) => {
      putPoints({ examId: e, studentId: s, points: p, scriptMissing: sm })
        .catch(() => {
          setShouldPutPoints(false);
          refresh();
        });
    }, 500), []);

  useDidMountEffect(() => {
    if (shouldPutPoints) {
      debouncedPutPoints({ e: exam.examId, s: student.id, p: pointsState, sm: scriptMissingState });
    }
  }, [pointsState, scriptMissingState]);

  useDidMountEffect(() => {
    setScriptMissingState(existingScriptMissing => {
      if (existingScriptMissing === scriptMissing) {
        return existingScriptMissing;
      }

      setShouldPutPoints(false);
      return scriptMissing;
    });
  }, [scriptMissing]);

  useDidMountEffect(() => {
    setPointsState(existingPoints => {
      if (existingPoints.size === points.size && [...Array.from(existingPoints.entries())].every(([key, value]) => points.get(key) === value)) {
        return existingPoints;
      }

      setShouldPutPoints(false);
      return points;
    });
  }, [points]);

  const handlePointsChange = (questionId: UUID<Question>) => (updatedPoints: number) => {
    setShouldPutPoints(true);
    setPointsState(existingPoints => new Map(existingPoints).set(questionId, updatedPoints));
    setScriptMissingState(false);
  }

  const handleMissingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedMissing = e.currentTarget.checked;

    if (updatedMissing) {
      setPointsState(new Map());
    }

    setScriptMissingState(updatedMissing);
    setShouldPutPoints(true);
  }

  const handleRemoveStudent = () => {
    setDeletingStudent(true);
    removeStudent(student.id)
      .then(() => refresh())
      .catch(() => refresh())
      .finally(() => setDeletingStudent(false));
  }

  const isComplete = exam.questions.every(q => pointsState.has(q.id)) || scriptMissingState;
  const displayCompleteRowStyles = isComplete && !readOnly;

  return (
    <TableRow key={student.id} sx={{ backgroundColor: displayCompleteRowStyles ? alpha(green. A400, 0.1) : undefined }}>
      <TableCell sx={{ py: 0.25, pl: 2, pr: 1 }}>
        {student.createdByMarker
          ? (
            <Stack direction="row" alignItems="center">
              <Box flexGrow={1}>
                <Typography variant="body2" sx={{ pt: 0.25, mb: -0.5 }} color={displayCompleteRowStyles ? green["900"] : undefined}>
                  {student.name}
                </Typography>
                <Typography variant="caption" color={displayCompleteRowStyles ? green["800"] : "text.secondary"}>
                  Added during marking
                </Typography>
              </Box>
              {!readOnly && (
                <Box>
                  {deletingStudent
                    ? <CircularProgress color="primary" size={20} />
                    : (
                      <IconButton
                        size="small"
                        onClick={handleRemoveStudent}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                </Box>
              )}
            </Stack>
          ) : <Typography variant="body2" color={displayCompleteRowStyles ? green["900"] : undefined}>{student.name}</Typography>
        }
      </TableCell>
      {exam.questions.map(question => (
        <TableCell key={question.id} align="right" sx={{ p: 0.25 }}>
          {scriptMissingState
            ? <Box sx={{ pr: 2 }}>-</Box>
            : (
              readOnly
                ? (
                  <Typography variant="body2" sx={{ pr: 2 }}>
                    {pointsState.get(question.id)}
                  </Typography>
                ) : (
                  <StudentPointsTablePointsInput
                    points={pointsState.get(question.id)}
                    maximumPoints={question.maximumPoints}
                    onUpdatedPoints={handlePointsChange(question.id)}
                    highlightEmpty={pointsState.size > 0 && !pointsState.has(question.id)}
                  />
                )
            )
          }
        </TableCell>
      ))}
      <TableCell align="center" sx={{ py: 0.25, px: 0 }}>
        {scriptMissingState
          ? (
            <FormControlLabel
              control={<Checkbox
                size="small"
                checked={scriptMissingState}
                disabled={readOnly}
                onChange={handleMissingChange}
                color="default"
              />}
              label={<Typography variant="caption" color="text.secondary">Missing</Typography>}
              labelPlacement="end"
            />
          ) : (
            <Checkbox
              size="small"
              checked={scriptMissingState}
              disabled={readOnly}
              onChange={handleMissingChange}
            />
          )
        }
      </TableCell>
    </TableRow>
  );
}
