import * as React from "react";
import { useState } from "react";
import { Alert, Button, Stack, Typography } from "@mui/material";
import SelectCentre from "./SelectCentre";
import { CentreMetadata } from "../../../domain/Centre";

interface Props {
  readonly onSelectExistingCentre: (centre: CentreMetadata) => void,
  readonly onSelectNoCentreFound: () => void,
}

export default function LookForExistingCentre({ onSelectExistingCentre, onSelectNoCentreFound }: Props) {
  const [selectedCentre, setSelectedCentre] = useState<CentreMetadata | null>(null);

  const handleClickSelectCentre = () => {
    if (selectedCentre !== null) {
      onSelectExistingCentre(selectedCentre);
    }
  };

  return (
    <Stack direction="column" spacing={2}>
      <Alert severity="info">
        Registrations for the UKLO are attached to a <strong>centre</strong>, which is usually a school, but can be a
        home school, or anywhere else that students will sit the exams.
      </Alert>
      <Typography>Let's see if we already have your centre in the system:</Typography>
      <SelectCentre onSelectCentre={setSelectedCentre} />
      <Stack direction="row" spacing={2}>
        <Button variant="contained" disabled={selectedCentre === null} onClick={handleClickSelectCentre} disableElevation>
          {selectedCentre ? `${selectedCentre.name} is my centre` : 'Start typing above'}
        </Button>
        <Button onClick={onSelectNoCentreFound}>
          It's not there, set up a new one
        </Button>
      </Stack>
    </Stack>
  )
}
