import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider, GlobalStyles, CssBaseline } from '@mui/material';
import theme from './theme';
import configureAxios from './gateway/configureAxios';
import { BrowserRouter } from 'react-router-dom';
import UkloRoutes from './UkloRoutes';
import { AccountProvider } from './components/account/AccountContext';
import { SeasonProvider } from "./components/exams/SeasonContext";
import { ManagedCentresProvider } from "./components/centres/ManagedCentresContext";
import { SPIN_KEYFRAMES } from "./components/common/Spin";

configureAxios();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AccountProvider>
        <ManagedCentresProvider>
          <SeasonProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyles styles={SPIN_KEYFRAMES} />
              <CssBaseline />
              <UkloRoutes />
            </ThemeProvider>
          </SeasonProvider>
        </ManagedCentresProvider>
      </AccountProvider>
    </BrowserRouter>
  </React.StrictMode>
);
