import * as React from "react";
import { useAccount } from "../account/AccountContext";
import { Button, Stack } from "@mui/material";
import { AccountRole } from "../../domain/Account";
import { requestRole } from "../../gateway/accounts";

export interface RoleRequestOption {
  readonly role: AccountRole,
  readonly text: string
}

interface Props {
  readonly roles: RoleRequestOption[],
  readonly onNewRole?: (role: AccountRole) => void,
  readonly alignRight?: boolean
}

export default function RequestRolesButtons({ roles, onNewRole = () => {}, alignRight = false }: Props) {
  const { invalidate } = useAccount();

  if (roles.length === 0) {
    return null;
  }

  const handleRequestRole = (role: AccountRole) => requestRole(role)
    .then(invalidate)
    .then(() => onNewRole(role));

  if (alignRight) {
    roles = roles.reverse();
  }

  return (
    <Stack direction={alignRight ? "row-reverse" : "row"} spacing={1}>
      {roles.map(ro => (
        <Button key={ro.role} variant="contained" onClick={() => handleRequestRole(ro.role)} disableElevation>{ro.text}</Button>
      ))}
    </Stack>
  )
}
