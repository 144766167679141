enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NON_BINARY = 'NON_BINARY',
  OTHER = 'OTHER',
  UNSPECIFIED = 'UNSPECIFIED',
  UNKNOWN = 'UNKNOWN'
}

const GENDER_STRINGS: { [K in Gender]: string } = {
  MALE: "Male",
  FEMALE: "Female",
  NON_BINARY: "Non-binary",
  OTHER: "Other",
  UNSPECIFIED: "Prefer not to say",
  UNKNOWN: "Unknown"
};

namespace Gender {
  export function values(): Gender[] {
    return [Gender.MALE, Gender.FEMALE, Gender.NON_BINARY, Gender.OTHER, Gender.UNSPECIFIED, Gender.UNKNOWN]
  }

  export function pretty(gender: Gender): string {
    return GENDER_STRINGS[gender];
  }
}

export default Gender;
