import { UUID } from '../../domain/UUID';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useId } from 'react';

interface Props<T> {
  readonly label: string,
  readonly value: UUID<T>,
  readonly options: UUIDOption<T>[],
  readonly size?: "small" | "medium",
  readonly onChange: (newValue: UUID<T>) => void,
  readonly onBlur?: () => void,
  readonly error?: boolean,
  readonly helperText?: string
  readonly sx?: any,
}

export interface UUIDOption<T> {
  readonly id: UUID<T>;
  readonly name: string;
}

export default function UUIDSelection<T>({ label, value, options, size, onChange, onBlur, error, helperText, sx }: Props<T>) {
  const labelId = useId();

  const propagateChange = (event: SelectChangeEvent<UUID<T>>) => {
    onChange(event.target.value);
  };

  return (
    <FormControl size={size || "medium"}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        value={value}
        onChange={propagateChange}
        onBlur={onBlur}
        labelId={labelId}
        label={label}
        autoWidth
        error={error}
        sx={sx}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}
