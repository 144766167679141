import * as React from "react";
import { Button, Stack, Typography } from "@mui/material";

interface Props {
  readonly onAcknowledge: () => void,
}

export default function ConfirmCompletedSetup({ onAcknowledge }: Props) {
  return (
    <Stack direction="column" spacing={2}>
      <Typography>You're all set up!</Typography>
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={onAcknowledge} disableElevation>Start registering students</Button>
      </Stack>
    </Stack>
  );
}
