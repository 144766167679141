import * as React from "react";
import { TableCell, TableRow } from "@mui/material";
import LevelMarkCell from "./LevelMarkCell";
import { QuestionRowProps } from "./QuestionRow";

export default function ReadonlyQuestionRow({ workingSeason, questionId, levels }: QuestionRowProps) {
  const question = workingSeason.seasonView.questions.find(q => q.id === questionId);

  if (question === undefined) {
    return <TableRow><TableCell>Invalid configuration</TableCell></TableRow>;
  }

  return (
    <TableRow hover>
      <TableCell align="right">
        {question.number}
      </TableCell>
      <TableCell>
        {question.name}
      </TableCell>
      <TableCell align="right">
        {question.maximumPoints}
      </TableCell>
      {levels.map(l => (
        <LevelMarkCell key={l} workingSeason={workingSeason} exam={workingSeason.seasonView.exams[l]} questionId={questionId} editing={false}/>
      ))}
    </TableRow>
  );
}
