import * as React from 'react';
import { useEffect, useState } from 'react';
import Account from '../../domain/Account';
import { getAllAccounts, impersonate } from "../../gateway/accounts";
import { Tooltip, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import LoginIcon from '@mui/icons-material/Login';
import { UUID } from "../../domain/UUID";
import { useAccount } from "../account/AccountContext";
import { ROUTE_DASHBOARD } from "../../UkloRoutes";
import { useNavigate } from "react-router-dom";

export default function AccountTable() {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const { invalidate } = useAccount();
  const navigate = useNavigate();

  useEffect(() => {
    getAllAccounts().then(setAccounts);
  }, []);

  if (accounts.length === 0) {
    return <Typography>No accounts.</Typography>;
  }

  const handleImpersonate = (id: UUID<Account>) => () => {
    impersonate(id)
      .then(() => navigate(ROUTE_DASHBOARD))
      .then(() => window.location.reload());
  }

  const columns: GridColumns<Account> = [
    { headerName: 'Name', flex: 3, field: 'name', valueGetter: params => params.row.name },
    { headerName: 'Email', flex: 2, field: 'email', valueGetter: params => params.row.email },
    {
      headerName: 'Roles',
      flex: 2,
      field: 'roles',
      valueGetter: params => params.row.roles.join(', '),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          // @ts-ignore
          <GridActionsCellItem
            icon={<Tooltip title={"Log in as user"}><LoginIcon /></Tooltip>}
            label="Log in as user"
            onClick={handleImpersonate(id as UUID<Account>)}
          />,
        ];
      },
    }
  ];

  return (
    <DataGrid
      rows={accounts}
      columns={columns}
      getRowId={account => account.id}
      density="compact"
      autoHeight
      disableColumnSelector
      disableSelectionOnClick
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        },
      }}
    />
  );
}

