import * as React from 'react';
import { useEffect, useState } from 'react';
import Account from '../../domain/Account';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMe, postLogout } from '../../gateway/accounts';
import { ROUTE_DASHBOARD, ROUTE_LOGIN, ROUTE_SETUP } from '../../UkloRoutes';

interface AccountContext {
  readonly account?: Account,
  readonly invalidate: () => void,
  readonly invalidatePromise: () => Promise<void>,
  readonly logout: () => void,
  readonly onLogin: (account: Account) => void,
  readonly isLoggedIn: boolean,
}

const AccountContext = React.createContext<AccountContext>({ logout: () => {}, onLogin: () => {}, invalidate: () => {}, invalidatePromise: () => Promise.resolve(), isLoggedIn: false });

export const useAccount = () => {
  return React.useContext(AccountContext);
};

export const AccountProvider = ({ children }: { children: React.ReactNode }) => {
  const [account, setAccount] = useState<Account | undefined>(undefined);
  const location = useLocation();
  const navigate = useNavigate();

  const homepage = (account: Account) => account.roles.length === 0 ? ROUTE_SETUP : ROUTE_DASHBOARD;

  const invalidatePromise = () => {
    return getMe()
      .then(account => {
        setAccount(account);
        if (location.pathname === '/') {
          navigate(homepage(account));
        }
      })
      .catch(() => {
        setAccount(undefined);

        // if the path starts with '/t/', this is a transactional page from an email, do not redirect
        if (location.pathname != ROUTE_LOGIN && !location.pathname.startsWith('/t/')) {
          navigate(ROUTE_LOGIN);
        }
      });
  };

  const invalidate = () => {
    invalidatePromise();
  }

  const context = {
    account,
    invalidate,
    invalidatePromise,
    logout: () => {
      postLogout()
        .finally(() => {
          setAccount(undefined);
          navigate(ROUTE_LOGIN);
        })
    },
    onLogin: (account: Account) => {
      setAccount(account);
      navigate(homepage(account));
    },
    isLoggedIn: account != undefined,
  };

  useEffect(invalidate, []);

  return (
    <AccountContext.Provider value={context}>
      {children}
    </AccountContext.Provider>
  );
};
