import * as React from "react";
import {
  Box,
  Fade,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Exam } from "../../../domain/Season";
import { UUID } from "../../../domain/UUID";
import useMarkerAssignments from "./useMarkerAssignments";
import MarkerCell from "./MarkerCell";
import { MarkingState } from "../../../domain/MarkingAssignment";
import exhaustiveGuard from "../../common/exhaustiveGuard";
import CheckIcon from "@mui/icons-material/Check";

interface Props {
  readonly examId: UUID<Exam>,
  readonly lastChange: number
}

// TODO indicate how many exam documents have been uploaded by the centre
// TODO indicate how many students have been given points/marked as missing by the marker
export default function MarkingAssignmentTable({ examId, lastChange }: Props) {
  const { markers, assignments, statistics, assign, unassign } = useMarkerAssignments(examId, lastChange);

  if (assignments.length === 0) {
    return (
      <Box sx={{ pt: 1 }}>
        <Fade in style={{ transitionDelay: '800ms' }} unmountOnExit>
          <LinearProgress />
        </Fade>
      </Box>
    );
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow sx={{ verticalAlign: "bottom" }}>
          <TableCell>Centre ({statistics.totalCentres} total)</TableCell>
          <TableCell>Estimated students</TableCell>
          <TableCell>Registered students</TableCell>
          <TableCell>State ({statistics.centresFinished} finished)</TableCell>
          <TableCell sx={{ width: 360 }}>Marker ({statistics.centresWithoutMarkers} without marker)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {assignments.map(assignment => (
          <TableRow key={assignment.centre.id}>
            <TableCell>
              <Stack direction="column">
                <Typography>{assignment.centre.name}</Typography>
                <Typography variant="caption">{assignment.centre.postcode.toUpperCase()}</Typography>
              </Stack>
            </TableCell>
            <TableCell>{assignment.estimatedStudents}</TableCell>
            <TableCell>{assignment.registeredStudents}</TableCell>
            <TableCell>
              {markingStateCellContents(assignment.markingState)}
            </TableCell>
            <MarkerCell
              centreId={assignment.centre.id}
              selectedMarkerId={assignment.marker?.id}
              allMarkers={markers}
              assign={assign}
              unassign={unassign}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function markingStateCellContents(markingState: MarkingState | null): React.ReactNode {
  if (markingState === null) {
    return "";
  }

  switch (markingState) {
    case MarkingState.MARKING:
      return "Marking";
    case MarkingState.FINISHED:
      return (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <CheckIcon fontSize="small" color="success" />
          <Typography variant="body2">Finished</Typography>
        </Stack>
      );
    default:
      return exhaustiveGuard(markingState);
  }
}
