import React from "react";
import { DataGrid, GridAlignment, GridColDef, GridToolbarContainer, useGridApiContext } from "@mui/x-data-grid";
import StudentExamResult from "../../../domain/StudentExamResult";
import { Exam } from "../../../domain/Season";
import { Button } from "@mui/material";
import Award from "../../../domain/Award";


interface Props {
  readonly results?: StudentExamResult[];
  readonly exam: Exam;
}

function ExportToolbar() {
  const apiRef = useGridApiContext();
  return (
    <GridToolbarContainer>
      <div style={{ flex: 1 }} />
      <Button
        onClick={() => apiRef.current.exportDataAsCsv({ fileName: 'results' })}
        color="primary"
      >
        Download as CSV
      </Button>
    </GridToolbarContainer>
  );
}

export default function ResultsTable({ results, exam }: Props) {
  const columns: GridColDef[] = [
    {
      field: "position",
      headerName: "Position",
      sortable: false,
      width: 100,
      valueFormatter: (params) => params.value.toLocaleString(),
    },
    {
      field: "percentage",
      headerName: "Percentage",
      width: 120,
      headerAlign: "right",
      align: "right",
      sortable: false,
      valueFormatter: (params) => `${params.value}%`,
    },
    {
      field: "totalMarks",
      headerName: "Total Marks",
      width: 140,
      headerAlign: "right",
      align: "right",
      sortable: false,
    },
    {
      field: "award",
      headerName: "Award",
      width: 120,
      sortable: false,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => Award.toHumanString(params.value),
    },
    ...exam.questions.map((question) => ({
      field: `question_${question.id}`,
      headerName: `Q${question.number} ${question.name}`,
      flex: 1,
      headerAlign: "right" as GridAlignment,
      align: "right" as GridAlignment,
      sortable: false,
      cellClassName: 'question-cell',
      valueGetter: (params: any) => params.row.questionResults[question.id]?.marks || "-",
    })),
  ];

  const rows = results?.map((result, index) => ({
    id: result.studentId,
    position: index + 1,
    percentage: result.percentage,
    totalMarks: result.totalMarks,
    award: result.award,
    questionResults: result.questionResults,
  })) || [];

  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[50, 100, 500]}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        density="compact"
        loading={results === undefined}
        components={{
          Toolbar: ExportToolbar,
        }}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            outline: 'none',
          },
          "& .MuiDataGrid-cell:focus": {
            outline: 'none',
          },
          "& .MuiDataGrid-cell.question-cell": {
            backgroundColor: '#f8f8f8',
          },
        }}
      />
    </div>
  );
}
