import * as React from "react";
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import Layout from './components/layout/Layout';
import MarkingPage, { MarkingMode } from './components/marking/MarkingPage';
import LoginOrRegister from './components/account/LoginOrRegister';
import Dashboard from './components/dashboard/Dashboard';
import CentrePage from './components/centres/CentrePage';
import { UUID } from './domain/UUID';
import SeasonManagementPage from "./components/exams/SeasonManagementPage";
import SeasonsPage from "./components/exams/SeasonsPage";
import WelcomePage from "./components/dashboard/WelcomePage";
import Centre from "./domain/Centre";
import AdminPage from "./components/admin/AdminPage";
import MarkerAssignmentPage from "./components/admin/assignment/MarkerAssignmentPage";
import BoundariesPage from "./components/admin/boundaries/BoundariesPage";
import { useAccount } from "./components/account/AccountContext";
import { AccountRole } from "./domain/Account";
import EmailPage from "./components/admin/email/EmailPage";
import ResetPasswordPage from "./components/account/ResetPasswordPage";
import ForgotPasswordPage from "./components/account/ForgotPasswordPage";

export const ROUTE_LOGIN = "/";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_SETUP = "/welcome";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_CENTRE = (centreId: UUID<Centre>) => `/centre/${centreId}`;
export const ROUTE_MARKING = (centreId: UUID<Centre>, mode: MarkingMode) => `/marking/${mode}/${centreId}`;

const ID_PATH_PARAM = 'id';

type UUIDParam<T> = {
  [ID_PATH_PARAM]: UUID<T>
}

export function useUUIDParam<T>(): UUID<T> {
  const navigate = useNavigate();
  const id = useParams<UUIDParam<T>>()[ID_PATH_PARAM];

  if (id === undefined)
  {
    navigate(ROUTE_DASHBOARD);
    return "" as UUID<T>;
  }

  return id;
}

export function AdminRoute({ children }: { children: React.ReactNode })
{
  const { account } = useAccount();
  const navigate = useNavigate();

  if (account?.roles.includes(AccountRole.ADMIN))
  {
    return <>{children}</>;
  }

  navigate(ROUTE_DASHBOARD);
  return null;
}

export default function UkloRoutes()
{
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<LoginOrRegister />} />
        <Route path="forgot-password" element={<ForgotPasswordPage />} />
        <Route path="t/reset-password" element={<ResetPasswordPage />} />
        <Route path="welcome" element={<WelcomePage />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path={`centre/:${ID_PATH_PARAM}`} element={<CentrePage />} />
        <Route path="marking">
          {Object.values(MarkingMode).map(mode => (
            <Route key={mode} path={`${mode}/:${ID_PATH_PARAM}`} element={<MarkingPage mode={mode} />} />
          ))}
        </Route>
        <Route path="admin" element={<AdminRoute children={<AdminPage />} />} />
        <Route path="assignment" element={<AdminRoute children={<MarkerAssignmentPage />} />} />
        <Route path="seasons" element={<AdminRoute children={<SeasonsPage />} />} />
        <Route path={`boundaries/:${ID_PATH_PARAM}`} element={<AdminRoute children={<BoundariesPage />} />} />
        <Route path={`season/:${ID_PATH_PARAM}`} element={<AdminRoute children={<SeasonManagementPage />} />} />
        <Route path="email" element={<AdminRoute children={<EmailPage />} />} />
        <Route path="*" element={<Dashboard />} />
      </Route>
    </Routes>
  );
}
