import * as React from 'react';
import { useState } from 'react';
import { Button, Stack } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VisuallyHiddenInput from '../common/VisuallyHiddenInput';
import UploadingDocument from "./UploadingDocument";
import { postDocument } from "../../gateway/documents";
import randomUUID from "../common/randomUUID";
import { UUID } from "../../domain/UUID";
import Document, { DocumentType } from "../../domain/Document";
import LoadingButton from '@mui/lab/LoadingButton';

interface Props {
  readonly multiple?: boolean,
  readonly onUpload: (document: Document) => void,
  readonly documentType: DocumentType,
  readonly showIcon?: boolean,
  readonly uploadButtonText?: string,
  readonly variant?: "contained" | "text",
}

interface InProgressUpload {
  readonly clientId: UUID<any>,
  readonly file: File,
  readonly progress: number,
  readonly finishedDocument?: Document,
}

export default function DocumentUpload({
                                         onUpload,
                                         documentType,
                                         multiple = false,
                                         showIcon = false,
                                         variant = "text",
                                         uploadButtonText
                                       }: Props) {
  const [inProgressUploads, setInProgressUploads] = useState<Map<UUID<any>, InProgressUpload>>(new Map());

  const handleSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.currentTarget.files == null || e.currentTarget.value == null) {
      return;
    }

    let documentsToUpload = Array.from(e.currentTarget.files);

    if (documentsToUpload.length === 0) {
      return;
    }

    if (multiple === false && documentsToUpload.length > 1) {
      documentsToUpload = [documentsToUpload[0]];
    }

    const newInProgressUploads = documentsToUpload.map(file => ({ clientId: randomUUID(), file, progress: 0 }))

    newInProgressUploads.forEach(upload => {
      postDocument(upload.file, documentType, (progress) => {
        setInProgressUploads(prev => new Map(prev).set(upload.clientId, { ...upload, progress }));
      })
        .then(uploadedDocument => {
          onUpload(uploadedDocument);
          return uploadedDocument;
        })
        .then(uploadedDocument => {
            setInProgressUploads(prev => new Map(prev).set(upload.clientId, {
              ...upload,
              progress: 100,
              finishedDocument: uploadedDocument
            }));
            setTimeout(() => setInProgressUploads(prev => {
              const updatedUploads = new Map(prev);
              updatedUploads.delete(upload.clientId);
              return updatedUploads;
            }), 5000);
          }
        )
        .catch(() => setInProgressUploads(prev => new Map(prev).set(upload.clientId, { ...upload, progress: 0 })));
    });

    setInProgressUploads(prev => {
      const newMap = new Map(prev);
      newInProgressUploads.forEach(upload => newMap.set(upload.clientId, upload));
      return newMap;
    });

    e.currentTarget.value = "";
  }

  if (inProgressUploads.size === 0) {
    return (
      <Button
        component="label"
        startIcon={<CloudUploadIcon />}
        sx={multiple ? {} : { pl: 0.5 }}
        variant={variant}
        color="primary"
      >
        {uploadButtonText ?? `Upload document${multiple ? "(s)" : ""}`}
        <VisuallyHiddenInput type="file" multiple={multiple} onChange={handleSelectFiles} />
      </Button>
    );
  }

  if (!multiple) {
    const upload = Array.from(inProgressUploads.values())[0];
    return <UploadingDocument
      fileName={upload.file.name}
      type={upload.file.type}
      progress={upload.progress}
      finishedUpload={upload.finishedDocument}
      variant="standalone"
      showIcon={showIcon}
    />
  }

  const buttonIsLoading = Array.from(inProgressUploads.values()).some(upload => upload.finishedDocument === undefined);
  const remainingUploads = Array.from(inProgressUploads.values()).filter(upload => upload.finishedDocument === undefined).length;

  return (
      <LoadingButton
        loading={buttonIsLoading}
        loadingPosition="start"
        component="label"
        startIcon={<CloudUploadIcon />}
        variant={variant}
        color="primary"
      >
        <span>
          {buttonIsLoading
            ? `Uploading ${remainingUploads} file${remainingUploads > 1 ? "s" : ""}`
            : uploadButtonText ?? "Upload document(s)"
          }
        </span>
        <VisuallyHiddenInput type="file" multiple onChange={handleSelectFiles} />
      </LoadingButton>
  );
}
