import * as React from "react";
import { useEffect, useState } from "react";

import Page from "../../common/Page";
import NewEmail from "./NewEmail";
import HistoricEmails from "./HistoricEmails";
import GoHome from "../../common/GoHome";
import { Box, Stack } from "@mui/material";

export default function EmailPage() {
  const [lastUpdate, setLastUpdate] = React.useState(0);

  const invalidate = () => setLastUpdate(lastUpdate => lastUpdate + 1);

  return (
    <Stack spacing={1}>
      <NewEmail invalidate={invalidate} />
      <HistoricEmails lastUpdate={lastUpdate} />
      <Box sx={{ mt: 2, mb: 1 }}><GoHome /></Box>
    </Stack>
  )
}
