import * as React from 'react';
import { alpha, AppBar, Box, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { useAccount } from '../account/AccountContext';
import { Link as RouterLink } from 'react-router-dom';
import Account, { AccountRole } from "../../domain/Account";
import LoggedInUser from "./LoggedInUser";
import theme from "../../theme";
import { ROUTE_DASHBOARD, ROUTE_LOGIN, ROUTE_SETUP } from "../../UkloRoutes";
import ResponsiveText from "../common/ResponsiveText";
import HomeIcon from '@mui/icons-material/Home';
import { useCurrentSeason } from "../exams/SeasonContext";
import SeasonState from "../../domain/SeasonState";

export default function UkloAppBar() {
  const { isLoggedIn, account } = useAccount();
  const { currentSeason } = useCurrentSeason();

  const isAdmin = account !== undefined && Account.hasRole(account, AccountRole.ADMIN);

  let mainLink = ROUTE_DASHBOARD;
  if (!isLoggedIn) {
    mainLink = ROUTE_LOGIN;
  } else if (account != undefined && account.roles.length === 0) {
    mainLink = ROUTE_SETUP;
  }

  return (
    <AppBar position="static" elevation={1} sx={{ bgcolor: 'primary.dark' }}>
      <Toolbar sx={{ width: "100%", maxWidth: 'lg', mx: "auto", px: 1.5 }} disableGutters>
        <Typography
          variant="h5"
          component={RouterLink}
          sx={{
            textDecoration: 'none',
            color: 'primary.contrastText',
            mr: 1,
            "&:hover": { color: alpha(theme.palette.primary.contrastText, 0.8) },
          }}
          to={mainLink}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <HomeIcon/>
            <ResponsiveText xs="UKLO" md="UKLO Marking Portal" />
          </Stack>
        </Typography>
        {currentSeason && (
          <ResponsiveText
            xs=""
            md={`${currentSeason.year} ${SeasonState.pretty(currentSeason.state)}`}
            variant="subtitle1"
          />
        )}
        <Box sx={{ flexGrow: 1 }} />
        {isLoggedIn && <Paper
          variant="outlined"
          sx={{
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
            pl: { xs: 1, sm: 2 },
            pr: { xs: 0.5, sm: 1 },
            py: { xs: 0.25, sm: 0.5 },
            my: { xs: 0.5, sm: 1 },
            borderColor: alpha(theme.palette.common.white, 0.25),
          }}
        >
          <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
            {isAdmin && (
              <Typography component={RouterLink} sx={{ textDecoration: 'none', color: 'primary.contrastText', mr: 2 }}
                          to="/admin">
                <ResponsiveText xs="Admin" md="Admin dashboard" variant="body2" />
              </Typography>
            )}
            <LoggedInUser />
          </Stack>
        </Paper>}
      </Toolbar>
    </AppBar>
  );
}
