import * as React from "react";
import { Button, Stack, Typography } from "@mui/material";
import useCentreDetailsForm from "../useCentreDetailsForm";
import Centre from "../../../domain/Centre";
import { createCentre } from "../../../gateway/centres";
import SaveIcon from "@mui/icons-material/Save";
import { UUID } from "../../../domain/UUID";

interface Props {
  readonly onCreatedNewCentre: (centreId: UUID<Centre>) => void,
  readonly onGoBack: () => void
}

export default function CreateNewCentre({ onCreatedNewCentre, onGoBack }: Props) {
  const { form, handleSubmit } = useCentreDetailsForm();

  return (
    <form onSubmit={handleSubmit(cd => createCentre(cd).then(c => onCreatedNewCentre(c.id)))}>
      <Stack direction="column" spacing={2}>
        <Typography>
          Please let us know where students will be sitting the exams.
          You can update these details later.
        </Typography>
        {form}
        <Stack direction="row" spacing={2}>
          <Button variant="contained" startIcon={<SaveIcon />} type="submit" disableElevation>Finish setup</Button>
          <Button onClick={onGoBack}>Go back</Button>
        </Stack>
      </Stack>
    </form>
  );
}
