import * as React from 'react';
import { useEffect, useState } from 'react';
import { UUID } from "../../domain/UUID";
import Centre from "../../domain/Centre";
import Document from "../../domain/Document";
import { useCurrentSeason } from "../exams/SeasonContext";
import { downloadCentreExamDocumentBundle, getCentreExamDocuments, } from "../../gateway/documents";
import Grid from "@mui/material/Unstable_Grid2";
import CentreExamDocumentDisplay from "../documents/CentreExamDocumentDisplay";
import { Card, CardContent, CardHeader } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';


interface Props {
  readonly centreId: UUID<Centre>,
  readonly title?: string,
}

export default function CentreExamDocumentsReadOnly({ centreId, title }: Props) {
  const { currentAdvancedExam } = useCurrentSeason();
  const [examDocuments, setExamDocuments] = React.useState<Document[]>([]);
  const [largeDownloadInProgress, setLargeDownloadInProgress] = useState(false);

  const getAndSetExamDocuments = () => {
    if (currentAdvancedExam === undefined) {
      return;
    }

    getCentreExamDocuments(centreId, currentAdvancedExam.examId)
      .then(documents => documents.sort((a, b) => b.uploadedAt - a.uploadedAt))
      .then(documents => setExamDocuments(documents));
  }

  useEffect(getAndSetExamDocuments, [centreId, currentAdvancedExam]);

  const handleLargeDownload = () => {
    if (currentAdvancedExam === undefined) {
      return;
    }

    setLargeDownloadInProgress(true);
    downloadCentreExamDocumentBundle(centreId, currentAdvancedExam.examId)
      .finally(() => setLargeDownloadInProgress(false));
  }

  if (currentAdvancedExam === undefined) {
    return <p>Loading.</p>;
  }

  let scriptCountExplainer = "No files";
  if (examDocuments.length === 1) {
    scriptCountExplainer = "One file";
  } else if (examDocuments.length > 1) {
    scriptCountExplainer = `${examDocuments.length} files`;
  }

  const showDocuments = examDocuments.length > 0;

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title={title || "Advanced exam scripts"}
          subheader={scriptCountExplainer}
          sx={showDocuments ? { pb: 0 } : {}}
        />
        {showDocuments && (
          <CardContent style={{ maxHeight: 285, overflow: 'auto' }}>
            <Grid container spacing={0.75}>
              {examDocuments.length > 1 && (
                <Grid xs={12} sm={6} md={4} lg={3}>
                  <LoadingButton
                    loading={largeDownloadInProgress}
                    variant="contained"
                    sx={{ width: '100%', height: 56, bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark' } }}
                    disableElevation
                    onClick={handleLargeDownload}
                    startIcon={<DownloadIcon />}
                    size="large"
                  >
                    <span>{
                      examDocuments.length == 2
                        ? "Download both files"
                        : `Download all ${examDocuments.length} files`
                    }</span>
                  </LoadingButton>
                </Grid>
              )}
              {examDocuments.map(document => (
                <Grid key={document.id} xs={12} sm={6} md={4} lg={3}>
                  <CentreExamDocumentDisplay document={document} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        )}
      </Card>
    </>
  )
}
