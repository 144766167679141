import * as React from 'react';
import { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Centre, { CentreDetails } from '../../domain/Centre';
import SaveIcon from '@mui/icons-material/Save';
import useCentreDetailsForm from "./useCentreDetailsForm";

interface Props {
  open: boolean,
  dismiss: () => void,
  save: (centreDetails: CentreDetails) => void,
  centre: Centre
}

export default function UpdateCentreDetailsDialog({ open, dismiss, save, centre }: Props) {
  const { form, reset, handleSubmit } = useCentreDetailsForm();

  useEffect(() => {
    if (open) {
      reset(centre);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={dismiss} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(save)}>
        <DialogTitle>
          Update {centre.name}
        </DialogTitle>
        <DialogContent dividers>{form}</DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button onClick={dismiss}>Cancel</Button>
          <Button variant="contained" startIcon={<SaveIcon />} type="submit" disableElevation>Save details</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
