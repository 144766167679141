import * as React from 'react';
import SeasonState from "../../domain/SeasonState";
import SeasonStateDescription from "./SeasonStateDescription";
import { Box } from "@mui/material";
import theme from "../../theme";

interface Props {
  readonly currentSeasonState: SeasonState,
}

export default function SeasonStateProgressionDescription({ currentSeasonState }: Props) {
  const next = SeasonState.next(currentSeasonState);

  if (next === undefined) {
    return <SeasonStateDescription seasonState={currentSeasonState} label="now" />;
  }

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: theme.spacing(1), height: '100%' }}>
      <Box sx={{ height: '100%' }}>
        <SeasonStateDescription seasonState={currentSeasonState} label="now" />
      </Box>
      <Box sx={{ height: '100%' }}>
        <SeasonStateDescription seasonState={next} label="next" />
      </Box>
    </Box>
  )
}
