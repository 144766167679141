import * as React from 'react';
import { Box } from "@mui/material";

type Props = {
  readonly duration?: number;
  readonly children: React.ReactNode;
  readonly direction?: 'clockwise' | 'anti-clockwise';
}

export const SPIN_KEYFRAMES = {
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  }
}

export default function Spin({ children, duration = 1, direction = 'clockwise' }: Props) {
  return (
    <Box
      sx={{
        animation: `spin ${duration}s linear infinite ${direction === 'clockwise' ? 'normal' : 'reverse'}`,
        transformOrigin: 'center',
      }}
    >
      {children}
    </Box>
  )
}
