// utils/download.ts
import { AxiosResponse } from 'axios';

export function downloadBlob(response: AxiosResponse): void {
  const url = window.URL.createObjectURL(response.data);

  const contentDisposition = response.headers['content-disposition'];
  let filename = 'download';

  if (contentDisposition && contentDisposition.includes('filename=')) {
    filename = contentDisposition.split('filename=')[1];
    filename = filename.replace(/['"]/g, '');
  }

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;

  document.body.appendChild(a);
  a.click();
  a.remove();

  window.URL.revokeObjectURL(url);
}
