import * as React from "react";
import Typography from "@mui/material/Typography";

interface Props {
  readonly children: React.ReactNode
}

export default function Subtitle({ children }: Props) {
  return (
    <Typography variant="h5">
      {children}
    </Typography>
  );
}
