import * as React from 'react';
import { useState } from 'react';
import Season from '../../../domain/Season';
import { useCurrentSeason } from "../SeasonContext";
import Subtitle from "../../common/Subtitle";
import SeasonQuestionTable from "./SeasonQuestionTable";
import { Button, Grid, Stack } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import AddQuestionForm from "./AddQuestionForm";
import useWorkingSeason from "./useWorkingSeason";
import { putSeasonQuestions } from "../../../gateway/exams";
import SeasonState from "../../../domain/SeasonState";

interface Props {
  readonly season: Season
}

export default function QuestionManagement({ season }: Props) {
  const { currentSeason } = useCurrentSeason();
  const [editing, setEditing] = useState(false);
  const workingSeason = useWorkingSeason(season);

  const editable = season.state === SeasonState.UNPUBLISHED || season.id === currentSeason?.id;

  const handleCancelChanges = () => {
    workingSeason.reset();
    setEditing(false);
  }

  const handleSaveChanges = () => {
    putSeasonQuestions(season.id, workingSeason.toPutSeasonQuestionsRequest())
      .then(season => {
        workingSeason.reset(season);
        setEditing(false);
      })
  }

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item xs={8}>
          <Subtitle>Questions</Subtitle>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row-reverse" spacing={1}>
            {editing ? (
                <>
                  <Button onClick={handleSaveChanges} variant="contained" startIcon={<SaveIcon/>} disableElevation>Save changes</Button>
                  <Button onClick={handleCancelChanges}>Cancel</Button>
                </>
              )
              : (
                editable
                  ? <Button onClick={() => setEditing(true)} startIcon={<EditIcon/>}>Edit</Button>
                  : <Button disabled>Past seasons are not editable</Button>
              )}
          </Stack>
        </Grid>
      </Grid>
      <SeasonQuestionTable workingSeason={workingSeason} editing={editing}/>
      {editing && <AddQuestionForm workingSeason={workingSeason} />}
    </Stack>
  );
}
