import * as React from "react";
import { useState } from "react";
import Page from "../../common/Page";
import MarkingAssignmentTable from "./MarkingAssignmentTable";
import { useCurrentSeason } from "../../exams/SeasonContext";
import ExamLevel from "../../../domain/ExamLevel";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { createAllAssignments, deleteAllAssignments } from "../../../gateway/marking";
import SeasonState from "../../../domain/SeasonState";

export default function MarkingAssignmentsPage() {
  const { currentSeason } = useCurrentSeason();
  const currentAdvancedExam = currentSeason?.exams[ExamLevel.ADVANCED];
  const [lastChange, setLastChange] = useState(new Date().getTime());
  const [showDeleteAllAssignmentsConfirmation, setShowDeleteAllAssignmentsConfirmation] = useState(false);

  if (currentAdvancedExam === undefined) {
    return <Typography>Loading</Typography>;
  }

  const handleCreateAllAssignments = () => {
    createAllAssignments(currentAdvancedExam.examId)
      .then(() => {
        setLastChange(new Date().getTime());
      });
  }

  const handleCloseDeleteAllAssignmentsConfirmation = (doDelete: boolean) => () => {
    if (doDelete) {
      deleteAllAssignments(currentAdvancedExam.examId)
        .then(() => {
          setLastChange(new Date().getTime());
          setShowDeleteAllAssignmentsConfirmation(false);
        });
    } else {
      setShowDeleteAllAssignmentsConfirmation(false);
    }
  }

  return (
    <Page
      title="Marker assignment"
      subtitle={`${ExamLevel.pretty(currentAdvancedExam.level)} ${currentSeason?.year}`}
      main
    >
      <Stack direction="row-reverse" spacing={1} sx={{ mb: 2 }}>
        <Button
          variant="contained"
          startIcon={<AutoFixHighIcon />}
          onClick={handleCreateAllAssignments}
          disableElevation
        >
          Automatically assign markers
        </Button>
        {SeasonState.isBefore(currentSeason?.state, SeasonState.ROUND_1_ADVANCED_MARKING) && (<Button
          variant="outlined"
          startIcon={<DeleteSweepIcon />}
          color="error"
          onClick={() => setShowDeleteAllAssignmentsConfirmation(true)}
        >
          Delete all assignments
        </Button>)}
      </Stack>
      <MarkingAssignmentTable examId={currentAdvancedExam.examId} lastChange={lastChange} />
      <Dialog
        open={showDeleteAllAssignmentsConfirmation}
        onClose={handleCloseDeleteAllAssignmentsConfirmation(false)}
      >
        <DialogTitle>Really delete all marker assignments?</DialogTitle>
        <DialogContent>
          <DialogContentText>This will remove all marker assignments for this exam.</DialogContentText>
          <DialogContentText>This action cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteAllAssignmentsConfirmation(false)}>Cancel</Button>
          <Button
            variant="contained"
            startIcon={<DeleteSweepIcon />}
            color="error"
            onClick={handleCloseDeleteAllAssignmentsConfirmation(true)}
          >
            Delete all assignments
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}
