import * as React from 'react';
import { Control, Controller, Path, useFormState } from 'react-hook-form';
import ValidationRules from './ValidationRules';
import UUIDSelection, { UUIDOption } from "../common/UUIDSelection";
import { UUID } from "../../domain/UUID";

interface Props<T, S> {
  readonly name: Path<T>;
  readonly label: string;
  readonly control: Control<T>;
  readonly options: UUIDOption<S>[];
  readonly rules?: ValidationRules;
  readonly sx?: any;
}

export default function FormInputUUIDSelection<T, S>({ control, name, label, rules, options, sx }: Props<T, S>) {
  const { errors } = useFormState({ control });
  // @ts-ignore
  const error = errors[name];

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <UUIDSelection
          label={label}
          value={field.value as UUID<S>}
          options={options}
          onChange={field.onChange}
          onBlur={field.onBlur}
          size="small"
          sx={sx}
          error={error !== undefined}
          helperText={error?.message}
        />
      )}
    />
  );
};
