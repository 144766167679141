import { CentreMetadata } from "./Centre";
import Account from "./Account";

export enum MarkingState {
  MARKING = 'MARKING',
  FINISHED = 'FINISHED',
}

export interface MarkingAssignment {
  readonly centre: CentreMetadata,
  readonly marker: null | Account,
  readonly markingState: null | MarkingState,
  readonly registeredStudents: number,
  readonly estimatedStudents: number,
}
