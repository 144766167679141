import * as React from 'react';
import { Box, Card, Link, Stack, Typography } from "@mui/material";
import CircularProgressWithLabel from "../common/CircularProgressWithLabel";
import PdfIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import exhaustiveGuard from "../common/exhaustiveGuard";
import Document from "../../domain/Document";

type Props = {
  fileName: string;
  type: string;
  progress: number;
  finishedUpload?: Document;
  variant: 'card' | 'standalone';
  showIcon: boolean;
}

enum FileType {
  PDF,
  IMAGE,
  OTHER,
}

export default function UploadingDocument({ type, fileName, progress, finishedUpload, variant, showIcon }: Props) {
  progress = Math.min(100, Math.max(0, progress));

  // only necessary if WebkitLineClamp is not supported
  const truncatedFileName = fileName.length > 100 ? fileName.substring(0, 97) + '...' : fileName;

  const body = (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ height: '100%' }}>
      {showIcon && icon(fileType(type))}
      <Typography
        variant="body2"
        sx={{
          flexGrow: 1,
          wordWrap: 'break-word',
          wordBreak: 'break-all',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {finishedUpload === undefined ? truncatedFileName : (
          <Link href={finishedUpload.url} download underline="hover" target="_blank" rel="noopener">
            {truncatedFileName}
          </Link>
        )}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pr: 1 }}>
        <CircularProgressWithLabel value={progress} incomplete={finishedUpload === undefined} />
      </Box>
    </Stack>
  );

  if (variant === 'standalone') {
    return body;
  }

  return (
    <Card variant="outlined" sx={{ height: '100%', mx: 1.5, my: 1.5 }}>
      {body}
    </Card>
  );
}

function fileType(fileTypeString: string): FileType {
  if (fileTypeString.startsWith('image/')) {
    return FileType.IMAGE;
  }

  if (fileTypeString === 'application/pdf') {
    return FileType.PDF;
  }

  return FileType.OTHER;
}

function icon(type: FileType): React.ReactNode {
  switch (type) {
    case FileType.PDF:
      return <PdfIcon fontSize="large" />;
    case FileType.IMAGE:
      return <ImageIcon fontSize="large" />;
    case FileType.OTHER:
      return <FileIcon fontSize="large" />;
    default:
      exhaustiveGuard(type);
  }
}
