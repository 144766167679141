import * as React from "react";
import { useEffect, useState } from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import LookForExistingCentre from "./LookForExistingCentre";
import Centre, { CentreMetadata } from "../../../domain/Centre";
import RequestJoiningExistingCentre from "./RequestJoiningExistingCentre";
import CreateNewCentre from "./CreateNewCentre";
import ConfirmCompletedSetup from "./ConfirmCompletedSetup";
import { UUID } from "../../../domain/UUID";
import { useManagedCentres } from "../ManagedCentresContext";

export default function SetupCentreCard() {
  const [currentStep, setCurrentStep] = useState(<></>);
  const { invalidate } = useManagedCentres();

  const goToLookForExistingCentre = () => {
    setCurrentStep(
      <LookForExistingCentre
        onSelectExistingCentre={goToRequestJoining}
        onSelectNoCentreFound={goToCreateNewCentre}
      />
    );
  }

  const goToRequestJoining = (centreMetadata: CentreMetadata) => {
    setCurrentStep(
      <RequestJoiningExistingCentre
        centreMetadata={centreMetadata}
        onConfirmedRequestToJoin={() => goToConfirmCompletedSetup(centreMetadata.id)}
        onGoBack={goToLookForExistingCentre}
      />
    );
  }

  const goToCreateNewCentre = () => {
    setCurrentStep(
      <CreateNewCentre onCreatedNewCentre={goToConfirmCompletedSetup} onGoBack={goToLookForExistingCentre} />
    );
  }

  const goToConfirmCompletedSetup = (centreId: UUID<Centre>) => {
    setCurrentStep(<ConfirmCompletedSetup onAcknowledge={invalidate} />);
  }

  useEffect(goToLookForExistingCentre, []);

  return (
    <Card variant="outlined">
      <CardHeader title="Your centre"></CardHeader>
      <CardContent sx={{ pt: 0 }}>
        {currentStep}
      </CardContent>
    </Card>
  )
}
