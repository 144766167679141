import * as React from "react";
import { useEffect, useState } from "react";
import { Exam, SeasonMetadata } from "../../domain/Season";
import Document, { DocumentType } from "../../domain/Document";
import { Box, Button, Link, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { deleteExamDocument, getExam, putExamDocument } from "../../gateway/exams";
import DocumentUpload from "./DocumentUpload";
import { getDocument } from "../../gateway/documents";
import { UUID } from "../../domain/UUID";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatTime } from "../common/time";

interface Props {
  readonly seasonMetadata: SeasonMetadata;
  readonly examId: UUID<Exam>;
}

interface EditableExamDocumentCellProps {
  readonly examId: UUID<Exam>;
  readonly documentId: UUID<Document> | null;
  readonly type: DocumentType;
  readonly setExam: (exam: Exam) => void;
}

function EditableExamDocumentCellContents({ examId, documentId, type, setExam }: EditableExamDocumentCellProps) {
  const [document, setDocument] = useState<Document | undefined>(undefined);

  useEffect(() => {
    if (documentId === null) {
      setDocument(undefined);
      return;
    }

    getDocument(documentId).then(setDocument);
  }, [documentId]);

  const handleUpload = (d: Document) => {
    putExamDocument(examId, d.id, type).then(() => {
      // Let the success tick stay for a little bit
      setTimeout(function(){
        getExam(examId).then(setExam);
      }, 1500);
    })
  }

  const handleDelete = () => {
    deleteExamDocument(examId, type).then(setExam);
  }

  if (documentId === null) {
    return (
      <Box sx={{ pl: 1}}>
        <DocumentUpload onUpload={handleUpload} documentType={type} />
      </Box>
    );
  }

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      spacing={{ xs: 0.25, md: 1 }}
      alignItems={{ xs: "flex-start", md: "center" }}
    >
      <Stack direction="column" sx={{ pl: 1, flexGrow: 1 }}>
        {document === undefined
          ? (
            <>
              Loading
              <Typography variant="caption" color="text.secondary">
                Loading
              </Typography>
            </>
          ) : (
            <>
              <Link href={document.url} download underline="hover" target="_self" rel="noreferrer">
                {document.name}
              </Link>
              <Typography variant="caption" color="text.secondary">
                {formatTime(document.uploadedAt)}
              </Typography>
            </>
          )
        }
      </Stack>
      <Button
        onClick={handleDelete}
        size="small"
        endIcon={<DeleteIcon />}
        color="error"
        sx={{ minWidth: 90, pl: 0.5}}
      >
        Delete
      </Button>
    </Stack>
  )
}

export default function EditableExamDocumentRow({ seasonMetadata, examId }: Props) {
  const [exam, setExam] = useState<Exam | undefined>(undefined);

  useEffect(() => {
    getExam(examId).then(setExam);
  }, [examId]);

  if (exam === undefined) {
    return (
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell colSpan={3}>
          <Typography>Loading</Typography>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell>
        {Exam.name(seasonMetadata, exam)}
      </TableCell>
      <TableCell>
        <EditableExamDocumentCellContents
          examId={examId}
          type={DocumentType.QUESTIONS}
          documentId={exam.questionsDocument}
          setExam={(e) => setExam(e)}
        />
      </TableCell>
      <TableCell>
        <EditableExamDocumentCellContents
          examId={exam.examId}
          type={DocumentType.SOLUTIONS}
          documentId={exam.solutionsDocument}
          setExam={(e) => setExam(e)}
        />
      </TableCell>
    </TableRow>
  );
}
