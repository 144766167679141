import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Stack, Typography } from "@mui/material";
import * as React from "react";

type Props = {
  readonly text: string
}

export default function SuccessText({ text }: Props) {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <CheckCircleIcon color="success" />
      <Typography variant="body1">{text}</Typography>
    </Stack>
  );
}
