import Season, { Exam, ExamQuestion, Question } from "../../../domain/Season";
import { useReducer } from "react";
import { UUID } from "../../../domain/UUID";
import ExamLevel from "../../../domain/ExamLevel";
import workingSeasonReducer from "./workingSeasonReducer";
import { PutSeasonQuestionsRequest } from "../../../gateway/exams";

type QuestionField = 'name' | 'number' | 'maximumPoints';

export interface WorkingSeason {
  readonly seasonView: Season,
  readonly reset: (season?: Season) => void
  readonly toPutSeasonQuestionsRequest: () => PutSeasonQuestionsRequest,
  readonly onAddQuestion: (question: Question) => void,
  readonly onDeleteQuestion: (questionId: UUID<Question>) => void,
  readonly onChangeQuestion: (questionId: UUID<Question>, field: QuestionField, value: string | number) => void,
  readonly onAssignQuestion: (questionId: UUID<Question>, examId: UUID<Exam>) => void,
  readonly onUnassignQuestion: (questionId: UUID<Question>, examId: UUID<Exam>) => void,
  readonly onChangeAssignedMarks: (questionId: UUID<Question>, examId: UUID<Exam>, marks: number) => void
}

export interface ExamAssignment {
  readonly examId: UUID<Exam>,
  readonly questionId: UUID<Question>,
  readonly maximumMarks: number
}

export interface WorkingSeasonState {
  readonly initialSeason: Season,
  readonly questions: Map<UUID<Question>, Question>,
  readonly examAssignments: ExamAssignment[]
}

function examAssignments(season: Season): ExamAssignment[] {
  const assignments: ExamAssignment[] = [];

  for (let level in season.exams) {
    const exam = season.exams[level as ExamLevel];

    for (let question of exam.questions) {
      assignments.push({ examId: exam.examId, questionId: question.id, maximumMarks: question.maximumMarks });
    }
  }

  return assignments;
}

export function workingSeasonState(season: Season): WorkingSeasonState {
  const questions: Map<UUID<Question>, Question> = new Map<UUID<Question>, Question>();

  for (let question of season.questions) {
    questions.set(question.id, { ...question });
  }

  return { initialSeason: season, questions, examAssignments: examAssignments(season) }
}

function asSeason(workingSeason: WorkingSeasonState): Season {
  // @ts-ignore
  const exams: { [K in ExamLevel]: Exam } = {};

  for (let level in workingSeason.initialSeason.exams) {
    const initialExam = workingSeason.initialSeason.exams[level as ExamLevel];

    const examQuestions: ExamQuestion[] = workingSeason.examAssignments
      .filter(ea => ea.examId === initialExam.examId)
      .map((ea): [ExamAssignment, Question | undefined] => [ea, workingSeason.questions.get(ea.questionId)])
      .filter(([ea, q]) => q !== undefined)
      .map(([ea, q]): [ExamAssignment, Question] => {
        // @ts-ignore
        return [ea, q];
      })
      .map(([ea, q]) => {
        return {
          id: q.id,
          name: q.name,
          number: q.number,
          maximumPoints: q.maximumPoints,
          maximumMarks: ea.maximumMarks
        }
      });

    exams[level as ExamLevel] = {
      ...initialExam,
      questions: examQuestions
    }
  }

  const sortedQuestions: Question[] = [];
  workingSeason.questions.forEach(v => sortedQuestions.push(v));
  sortedQuestions.sort((a, b) => a.number - b.number);

  return {
    ...workingSeason.initialSeason,
    questions: sortedQuestions,
    exams
  };
}

export default function useWorkingSeason(initialSeason: Season): WorkingSeason {
  const [state, dispatch] = useReducer(workingSeasonReducer, initialSeason, workingSeasonState);

  let seasonView = asSeason(state);

  return {
    seasonView,
    reset: (season) => dispatch({ type: "RESET", value: season }),
    toPutSeasonQuestionsRequest: () => ({ questions: seasonView.questions, examAssignments: state.examAssignments }),
    onAddQuestion: question => dispatch({ type: "ADD_QUESTION", value: question }),
    onDeleteQuestion: questionId => dispatch({ type: "DELETE_QUESTION", value: questionId }),
    onChangeQuestion: (questionId, field, value) => {
      dispatch({ type: "CHANGE_QUESTION", value: { questionId, field, value } })
    },
    onAssignQuestion: (questionId, examId) => dispatch({ type: "ASSIGN_QUESTION", value: { questionId, examId } }),
    onUnassignQuestion: (questionId, examId) => dispatch({ type: "UNASSIGN_QUESTION", value: { questionId, examId } }),
    onChangeAssignedMarks: (questionId, examId, marks) => dispatch({
      type: "CHANGE_ASSIGNED_MARKS",
      value: { questionId, examId, marks }
    })
  };
}
