import * as React from 'react';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import UkloAppBar from './UkloAppBar';

export default function Layout() {
  return (
    <>
      <UkloAppBar />
      <Container maxWidth="lg" sx={{ p: 1 }} disableGutters>
        <Box>
          <Outlet />
        </Box>
      </Container>
    </>
  );
}
