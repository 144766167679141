import * as React from 'react';
import { Button, Stack } from '@mui/material';
import { useAccount } from '../account/AccountContext';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountAvatar from "../common/AccountAvatar";
import ResponsiveText from '../common/ResponsiveText';

export default function LoggedInUser() {
  const { account, logout } = useAccount();

  if (account === undefined) {
    return null;
  }

  return (
      <Stack direction="row" spacing={{xs: 0.5, md: 1 }} alignItems="center">
        <AccountAvatar account={account} size="sm" />
        <ResponsiveText xs="" md={account.name} variant="body2" />
        <Button
          color="inherit"
          onClick={logout}
          endIcon={<LogoutIcon />}
          size="small"
        >
          Log out
        </Button>
      </Stack>
  );
}
