import * as React from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { CentreMetadata } from "../../../domain/Centre";
import { getAllCentreMetadata } from "../../../gateway/centres";

interface Props {
  readonly onSelectCentre: (centre: CentreMetadata | null) => void
}

export default function SelectCentre({ onSelectCentre }: Props) {
  const [allCentres, setAllCentres] = useState<CentreMetadata[]>([]);

  useEffect(() => {
    getAllCentreMetadata().then(setAllCentres);
  }, []);

  return (
    <Autocomplete
      options={allCentres}
      onChange={(e, value) => onSelectCentre(value)}
      getOptionLabel={(centre) => `${centre.name} (${centre.postcode})`}
      noOptionsText="No centre found"
      renderInput={(params) => (
        <TextField label="Centre name or postcode" variant="outlined" {...params} />
      )}
      renderOption={(props, option) => <Box component='li' {...props} key={option.id}>{option.name} ({option.postcode})</Box>}
    />
  );
}
