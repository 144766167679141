import * as React from 'react';
import { useEffect, useState } from 'react';
import Page from '../common/Page';
import Centre from "../../domain/Centre";
import { ROUTE_DASHBOARD, ROUTE_MARKING } from "../../UkloRoutes";
import { useAccount } from "../account/AccountContext";
import StudentPointsTable from "./StudentPointsTable";
import { getMyAssignments, putMarkingState } from "../../gateway/marking";
import { useNavigate } from "react-router-dom";
import { UUID } from "../../domain/UUID";
import Season, { Exam } from "../../domain/Season";
import { MarkingMode } from "./MarkingPage";
import { Alert, AlertTitle, Avatar, Box, Button, Card, CardContent, Chip, Stack, Typography } from "@mui/material";
import { MarkingAssignment, MarkingState } from "../../domain/MarkingAssignment";
import CheckIcon from "@mui/icons-material/Check";
import ExamDocumentDownloadCard from "../documents/ExamDocumentDownloadCard";
import CentreExamDocumentsCard from "../centres/CentreExamDocumentsCard";
import { useCurrentSeason } from "../exams/SeasonContext";
import ExamLevel from "../../domain/ExamLevel";
import NumberedChip from "../common/NumberedChip";

type Props = {
  readonly season: Season,
  readonly examId: UUID<Exam>,
  readonly centreId: UUID<Centre>;
}

type AlertDetails = {
  readonly severity: 'info' | 'error' | 'success';
  readonly title?: string;
  readonly showDismiss?: boolean;
  readonly contents: string;
}

export default function AssignedMarkerMarkingPage({ season, examId, centreId }: Props) {
  const { account } = useAccount();
  const { currentSeason } = useCurrentSeason();
  const [assignments, setAssignments] = useState<MarkingAssignment[] | undefined>(undefined);
  const [selectedAssignment, setSelectedAssignment] = useState<MarkingAssignment | undefined>(undefined);
  const [activeAlert, setActiveAlert] = useState<AlertDetails | undefined>(undefined);
  const navigate = useNavigate();

  const refreshAssignments = () => {
    if (account === undefined) {
      return Promise.resolve();
    }

    return getMyAssignments(examId).then(setAssignments);
  }

  useEffect(() => {
    refreshAssignments();
  }, [examId, account?.id]);

  useEffect(() => {
    if (assignments === undefined) {
      return;
    }

    if (assignments.length === 0) {
      navigate(ROUTE_DASHBOARD);
      return;
    }

    const assignment = assignments.find(assignment => assignment.centre.id === centreId);

    if (assignment === undefined) {
      navigate(ROUTE_MARKING(assignments[0].centre.id, MarkingMode.ASSIGNED_MARKER));
    }

    setSelectedAssignment(assignment);
  }, [centreId, assignments]);

  useEffect(() => {
    if (selectedAssignment === undefined) {
      return;
    }

    if (selectedAssignment.markingState !== MarkingState.FINISHED) {
      setActiveAlert(undefined);
      return;
    }

    setActiveAlert({
      contents: `You've finished marking ${selectedCentreName}. You can re-open marking at any time.`,
      severity: 'success'
    });
  }, [selectedAssignment?.centre.id]);

  const handleSelectCentre = (centreId: UUID<Centre>) => {
    navigate(ROUTE_MARKING(centreId, MarkingMode.ASSIGNED_MARKER));
  }

  const handlePutMarkingState = (markingState: MarkingState) => {
    if (selectedAssignment === undefined || assignments === undefined) {
      return;
    }

    putMarkingState(examId, centreId, markingState)
      .then(() => {
        setAssignments(assignments.map(assignment => assignment.centre.id === centreId ? {
          ...assignment,
          markingState
        } : assignment));
        setSelectedAssignment({ ...selectedAssignment, markingState });

        if (markingState === MarkingState.FINISHED)
        {
          setActiveAlert({
            severity: "info",
            title: "Please double check the points below before leaving this page.",
            contents: "You can re-open marking at any time.",
            showDismiss: true,
          });
        } else {
          setActiveAlert(undefined);
        }
      }).catch(() => {
        refreshAssignments();

        if (markingState === MarkingState.FINISHED) {
          setActiveAlert({
            severity: "error",
            title: "There was a problem.",
            contents: "Please check that each student has been given points for every question, or that their script is marked as missing."
          })
        } else {
          setActiveAlert(undefined);
        }
      });
  }

  if (assignments === undefined || selectedAssignment === undefined) {
    return null;
  }

  const selectedAssignmentState = selectedAssignment.markingState === null ? MarkingState.MARKING : selectedAssignment.markingState;
  const selectedCentreName = selectedAssignment.centre.name;
  const multipleAssignments = assignments.length > 1;

  let selectedExamName = "Advanced Exam";
  if (currentSeason != undefined)
  {
    const selectedExam = ExamLevel.values()
      .map(l => currentSeason.exams[l])
      .filter(e => e !== undefined)
      .filter(e => e.examId === examId);
    if (selectedExam.length > 0)
    {
      selectedExamName = Exam.name(currentSeason, selectedExam[0]);
    }
  }

  return (
    <Page title="Marking">
      <Stack direction="column" spacing={1}>
        <Card variant="outlined">
          <CardContent>
            <Stack spacing={1} direction={{ xs: "column", md: "row" }} alignItems="flex-start" sx={{ mb: 1, mt: multipleAssignments ? 1 : 0 }}>
              <Box sx={{ flexGrow: 1 }}>
                {multipleAssignments
                  ? (
                    <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
                      {assignments.map(assignment => {
                        const { centre } = assignment;
                        const selected = centre.id === centreId;
                        return (
                          <NumberedChip
                            key={centre.id}
                            text={centre.name}
                            ticked={assignment.markingState === MarkingState.FINISHED}
                            selected={selected}
                            onClick={() => handleSelectCentre(centre.id)}
                            number={assignment.registeredStudents}
                          />
                        );
                      })}
                    </Stack>
                  ) : (
                    <Stack direction="row" alignItems="baseline" spacing={0.5}>
                      <Typography variant="h6">{selectedCentreName}</Typography>
                      <Typography variant="body1">{selectedAssignment.registeredStudents} registered student{selectedAssignment.registeredStudents === 1 ? '' : 's'}</Typography>
                      {selectedAssignment.markingState === MarkingState.FINISHED && <Typography variant="body2" color="text.secondary">(finished marking)</Typography>}
                    </Stack>
                  )
                }
              </Box>
              <Box>
                <Button
                  variant={selectedAssignmentState === MarkingState.MARKING ? "contained" : "outlined"}
                  color="primary"
                  fullWidth
                  onClick={() => handlePutMarkingState(selectedAssignmentState === MarkingState.MARKING ? MarkingState.FINISHED : MarkingState.MARKING)}
                >
                  {selectedAssignmentState === MarkingState.MARKING
                    ? (
                      <>
                        <CheckIcon sx={{ mr: 1, ml: -1 }} />
                        Finish marking this centre
                      </>
                    ) : `Re-open marking for this centre`
                  }
                </Button>
              </Box>
            </Stack>
            {activeAlert !== undefined && (
              <Alert
                severity={activeAlert.severity}
                sx={{ mt: 2, mb: 1 }}
                action={activeAlert.showDismiss && (
                  <Button color="inherit" size="small" onClick={() => setActiveAlert(undefined)}>
                    Dismiss
                  </Button>
                )}
              >
                <>
                  {activeAlert.title !== undefined && (<AlertTitle>{activeAlert.title}</AlertTitle>)}
                  {activeAlert.contents}
                </>
              </Alert>
              )}
            <StudentPointsTable
              seasonId={season.id}
              examId={examId}
              centreId={centreId}
              refreshStudentCounts={refreshAssignments}
              readOnly={selectedAssignmentState === MarkingState.FINISHED}
            />
          </CardContent>
        </Card>
        <CentreExamDocumentsCard centreId={centreId} title={`${selectedCentreName} ${selectedExamName} scripts`} />
        <ExamDocumentDownloadCard season={season} onlyExamIds={examId} />
      </Stack>
    </Page>
  );
}
