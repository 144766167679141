import * as React from 'react';
import { useEffect, useState } from 'react';
import Centre from "../../domain/Centre";
import { useAccount } from "../account/AccountContext";
import { getMyManagedCentres } from "../../gateway/centres";

interface ManagedCentresContext {
  readonly managedCentres: Centre[] | undefined,
  readonly invalidate: () => void
}

const ManagedCentresContext = React.createContext<ManagedCentresContext>({ managedCentres: undefined, invalidate: () => {} });

export const useManagedCentres = () => {
  return React.useContext(ManagedCentresContext);
};

export const ManagedCentresProvider = ({ children }: { children: React.ReactNode }) => {
  const { account, isLoggedIn } = useAccount();
  const [managedCentres, setManagedCentres] = useState<Centre[] | undefined>(undefined);

  const invalidate = () => {
    if (isLoggedIn) {
      getMyManagedCentres().then(setManagedCentres);
    } else {
      setManagedCentres(undefined);
    }
  }

  useEffect(invalidate, [isLoggedIn, account?.id]);

  const context = { invalidate, managedCentres };

  return (
    <ManagedCentresContext.Provider value={context}>
      {children}
    </ManagedCentresContext.Provider>
  );
};
