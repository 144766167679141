import * as React from 'react';
import Page from '../common/Page';
import { useAccount } from "../account/AccountContext";
import { Stack, Typography } from "@mui/material";
import Account, { AccountRole } from "../../domain/Account";
import MarkingSection from "./MarkingSection";
import CentreSection from "./CentreSection";
import RequestRolesButtons, { RoleRequestOption } from "./RequestRolesButtons";
import { useNavigate } from "react-router-dom";
import { ROUTE_SETUP } from "../../UkloRoutes";
import { useCurrentSeason } from "../exams/SeasonContext";
import SeasonState from "../../domain/SeasonState";
import ExamDocumentDownloadCard from '../documents/ExamDocumentDownloadCard';

export default function Dashboard() {
  const { account } = useAccount();
  const { currentSeason } = useCurrentSeason();
  const navigate = useNavigate();

  if (account === undefined || currentSeason === undefined) {
    return <Typography>Loading</Typography>;
  }

  if (account.roles.length === 0)
  {
    navigate(ROUTE_SETUP);
  }

  const isCentreManager = Account.hasRole(account, AccountRole.CENTRE_MANAGER);
  const isMarker = Account.hasRole(account, AccountRole.MARKER);

  const newRoles: RoleRequestOption[] = [];

  if (!isCentreManager && SeasonState.isBefore(currentSeason.state, SeasonState.ROUND_1_TEST_PERIOD))
  {
    newRoles.push({ role: AccountRole.CENTRE_MANAGER, text: "I'd like to register students too" })
  }

  if (!isMarker)
  {
    newRoles.push({ role: AccountRole.MARKER, text: "I'd like to volunteer to mark Advanced scripts too" })
  }

  return (
    <Page hideHomeLink>
      <Stack spacing={1}>
        {isMarker && <MarkingSection />}
        {isCentreManager && <CentreSection />}
        {SeasonState.isOrIsAfter(currentSeason.state, SeasonState.ROUND_1_TEST_PERIOD) && <ExamDocumentDownloadCard season={currentSeason} />}
        {newRoles.length > 0 && <RequestRolesButtons roles={newRoles} alignRight />}
      </Stack>
    </Page>
  );
}
