import * as React from 'react';
import { Stack } from '@mui/material';
import CentreType from '../../domain/CentreType';
import { Control, useForm } from "react-hook-form";
import FormInputText from "../form/FormInputText";
import FormInputRadio from '../form/FormInputRadio';
import { CentreDetails } from "../../domain/Centre";

interface CentreDetailsFormData {
  readonly name: string,
  readonly postcode: string,
  readonly address: string,
  readonly selective: "SELECTIVE" | "NOT_SELECTIVE",
  readonly type: CentreType | null,
  readonly ages: string
}

interface Props {
  readonly control: Control<CentreDetailsFormData>,
  readonly size?: 'small' | 'medium'
}

function CentreDetailsForm({ control, size = 'medium' }: Props) {
  return (
    <Stack direction="column" spacing={2}>
      <FormInputText
        name="name"
        label="Centre name"
        placeholder="e.g. Manchester Grammar School"
        required
        autoFocus
        size={size}
        rules={{
          required: "Please provide the centre's name",
          minLength: { value: 3, message: "Must be at least 3 characters" }
        }}
        control={control}
      />
      <FormInputText
        name="address"
        label="Address"
        required
        multiline
        minRows={3}
        size={size}
        rules={{ required: "Please provide the centre's address" }}
        control={control}
      />
      <FormInputText
        name="postcode"
        label="Postcode"
        placeholder="e.g. AB12 C34"
        required
        size={size}
        rules={{ required: "Please provide the centre's postcode" }}
        control={control}
      />
      <FormInputRadio
        name="selective"
        label="Selective"
        required
        size={size}
        options={[{ value: "SELECTIVE", label: "Selective" }, { value: "NOT_SELECTIVE", label: "Not selective" }]}
        rules={{ required: true }}
        control={control}
      />
      <FormInputRadio
        name="type"
        label="Type"
        size={size}
        options={CentreType.values().map((type: CentreType) => ({ value: type, label: CentreType.pretty(type) }))}
        control={control}
      />
      <FormInputText
        name="ages"
        label="Student ages"
        placeholder="e.g. 11-18"
        size={size}
        control={control}
      />
    </Stack>
  );
}

export default function useCentreDetailsForm(props?: Omit<Props, 'control'>) {
  const { control, handleSubmit, setValue } = useForm<CentreDetailsFormData>();

  const reset = (centre?: CentreDetails) => {
    setValue('name', centre?.name || '');
    setValue('address', centre?.address || '');
    setValue('postcode', centre?.postcode || '');
    setValue('selective', centre?.selective ? "SELECTIVE" : "NOT_SELECTIVE");
    setValue('type', centre?.type || null);
    setValue('ages', centre?.ages || '');
  };

  const adaptFormData = (fd: CentreDetailsFormData): CentreDetails => {
    return {
      name: fd.name,
      address: fd.address,
      postcode: fd.postcode,
      selective: fd.selective === "SELECTIVE",
      type: fd.type || undefined,
      ages: fd.ages
    };
  }

  return {
    form: <CentreDetailsForm control={control} />,
    reset,
    handleSubmit: (handleCentreDetails: (cd: CentreDetails) => void) => handleSubmit(fd => handleCentreDetails(adaptFormData(fd)))
  };
}
