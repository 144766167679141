import axios from 'axios';
import { UUID } from '../domain/UUID';
import { Exam } from "../domain/Season";
import AwardBoundaries from "../domain/AwardBoundaries";


export function getAwardBoundaries(examId: UUID<Exam>): Promise<AwardBoundaries> {
  return axios
    .get<AwardBoundaries>(`/awardBoundaries/${examId}`)
    .then(({ data }) => data)
    .catch((error) => {
      if (error.response?.status === 404) {
        return { examId, awardToThreshold: {} };
      }
      throw error;
    });
}

export function putAwardBoundaries(examId: UUID<Exam>, awardBoundaries: AwardBoundaries): Promise<AwardBoundaries> {
  return axios
    .put<AwardBoundaries>(`/awardBoundaries/${examId}`, awardBoundaries)
    .then(({ data }) => data);
}

export function deleteAwardBoundaries(examId: UUID<Exam>): Promise<void> {
  return axios.delete(`/awardBoundaries/${examId}`);
}
