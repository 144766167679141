import * as React from "react";
import { useEffect, useState } from "react";
import { getMarkingCapacity, putMarkingCapacity } from "../../gateway/marking";
import { Alert, Button, Card, CardContent, CardHeader } from "@mui/material";
import Season, { Exam } from "../../domain/Season";
import MarkingCapacityDialog from "./MarkingCapacityDialog";
import CloseIcon from "@mui/icons-material/Close";
import { removeRole } from "../../gateway/accounts";
import { AccountRole } from "../../domain/Account";
import { useAccount } from "../account/AccountContext";
import SuccessText from "../common/SuccessText";
import SeasonState from "../../domain/SeasonState";

interface Props {
  readonly season: Season,
  readonly exam: Exam
}

function getCapacityExplainer(capacity: number, season: Season, exam: Exam) {
  if (capacity === 0) {
    if (SeasonState.isBefore(season.state, SeasonState.ROUND_1_TEST_PERIOD)) {
      return `You're not registered to mark any ${Exam.name(season, exam)} scripts yet.`;
    } else {
      return `Your capacity is 0 ${Exam.name(season, exam)} scripts.`;
    }
  }

  const registeredText = `You're registered to mark up to ${capacity} ${Exam.name(season, exam)} script${capacity !== 1 ? 's' : ''}.`;

  return <SuccessText text={registeredText} />;
}

export default function MarkingCapacityCard({ season, exam }: Props) {
  const { invalidate } = useAccount();
  const [capacity, setCapacity] = useState<number>(-1);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    getMarkingCapacity(exam.examId).then(mc => setCapacity(mc.capacity));
  }, [exam.examId]);

  const handleCapacity = (c: number) => {
    putMarkingCapacity(exam.examId, { capacity: c })
      .then(mc => setCapacity(mc.capacity))
      .then(() => setShowDialog(false));
  }

  const handleStopMarking = () => removeRole(AccountRole.MARKER).then(invalidate);

const capacityExplainer = getCapacityExplainer(capacity, season, exam);

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title="Your marking capacity"
          subheader={capacityExplainer}
          subheaderTypographyProps={{ sx: { pt: 1.5 } }}
          action={
            <Button
              variant={capacity === 0 ? 'contained' : 'text'}
              color="primary"
              onClick={() => setShowDialog(true)}
              disableElevation
            >
              Update capacity
            </Button>
          }
        />
        {capacity === 0 && SeasonState.isBefore(season.state, SeasonState.ROUND_1_TEST_PERIOD) && (
          <CardContent sx={{ pt: 0 }}>
            <Alert
              severity="info"
              action={
                <Button color="info" size="small" startIcon={<CloseIcon />} onClick={handleStopMarking}>
                  I don't want to be a marker any more
                </Button>
              }
            >
              Please provide your capacity, otherwise you won't be assigned any scripts.
            </Alert>
          </CardContent>
        )}
      </Card>
      <MarkingCapacityDialog open={showDialog} initialCapacity={capacity} dismiss={() => setShowDialog(false)} save={handleCapacity} />
    </>
  );
}
