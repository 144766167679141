import AwardBoundaries from "../../../domain/AwardBoundaries";
import React, { useState, useEffect } from "react";
import Award from "../../../domain/Award";
import { Button, Stack, TextField, Typography } from "@mui/material";
import StudentExamResult from "../../../domain/StudentExamResult";
import { calculateBoundaryPreview, BoundaryPreview } from "./boundaryPreview";

interface Props {
  readonly initialBoundaries: AwardBoundaries;
  readonly results: StudentExamResult[] | undefined;
  readonly onSave: (boundaries: AwardBoundaries) => void;
  readonly onCancel: () => void;
  readonly onDelete: () => void;
}

export default function AwardBoundariesForm({ initialBoundaries, results, onSave, onCancel, onDelete }: Props) {
  const [boundaries, setBoundaries] = useState<AwardBoundaries>(initialBoundaries);
  const [errors, setErrors] = useState<string | null>(null);
  const [boundaryPreview, setBoundaryPreview] = useState<BoundaryPreview[] | null>(null);

  const validateBoundaries = () => {
    const thresholds = Award.getOrderedAwardsExcludingParticipation().map(
      (award) => parseFloat(boundaries.awardToThreshold[award] || "0")
    );
    if (thresholds.some((value) => isNaN(value) || value < 0 || value > 100)) {
      return "Boundaries must be between 0 and 100.";
    }
    if (thresholds.some((value, idx) => idx > 0 && value <= thresholds[idx - 1])) {
      return "Boundaries must be strictly increasing.";
    }
    return null;
  };

  const handleChange = (award: Award, value: string) => {
    setBoundaries((prev) => ({
      ...prev,
      awardToThreshold: {
        ...prev.awardToThreshold,
        [award]: value,
      },
    }));
  };

  const handleSubmit = () => {
    const validationError = validateBoundaries();
    if (validationError) {
      setErrors(validationError);
      return;
    }
    onSave(boundaries);
  };

  // Recalculate the preview whenever boundaries or results change
  useEffect(() => {
    if (results && validateBoundaries() === null) {
      const preview = calculateBoundaryPreview(
        results,
        Award.getOrderedAwardsExcludingParticipation().reverse().map((award) => ({
          award,
          boundary: parseFloat(boundaries.awardToThreshold[award] || "0"),
        }))
      );
      setBoundaryPreview(preview.reverse());
    } else {
      setBoundaryPreview(null);
    }
  }, [boundaries, results]);

  return (
    <Stack spacing={1.5}>
      {Award.getOrderedAwardsExcludingParticipation().map((award) => (
        <TextField
          key={award}
          label={Award.toHumanString(award)}
          value={boundaries.awardToThreshold[award] || ""}
          onChange={(e) => handleChange(award, e.target.value)}
          fullWidth
          margin="normal"
          type="number"
          size="small"
          inputProps={{ min: 0, max: 100 }}
        />
      ))}
      {errors && <Typography color="error">{errors}</Typography>}
      <Stack direction="row" spacing={1}>
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="outlined" color="error" onClick={onDelete}>
          Delete
        </Button>
      </Stack>
      {boundaryPreview && (
        <Stack spacing={0.25} sx={{ marginTop: 2 }}>
          <Typography variant="h6">Preview</Typography>
          {boundaryPreview.map((preview) => (
            <Typography key={preview.award} variant="body1">
              <strong>{Award.toHumanString(preview.award)}</strong>: {preview.count} student{preview.count == 1 ? '' : 's'} ({preview.percentageOfStudents.toFixed(2)}%)
            </Typography>
          ))}
        </Stack>
      )}
    </Stack>
  );
}
