export default function sanitizeFileName(name: string): string {
  if (!name) {
    return 'default';
  }

  const normalized = name.normalize('NFD');
  const withoutAccents = normalized.replace(/[\u0300-\u036f]/g, '');
  const withoutWhitespace = withoutAccents.replace(/\s+/g, '_');
  const sanitized = withoutWhitespace.replace(/[<>:"/\\|?*\x00-\x1F]/g, '');

  const asciiOnly = sanitized.replace(/[^a-zA-Z0-9_\-]/g, '');

  const defaultName = 'default';
  const reservedNames = ['CON', 'PRN', 'AUX', 'NUL',
    'COM1', 'COM2', 'COM3', 'COM4', 'COM5', 'COM6', 'COM7', 'COM8', 'COM9',
    'LPT1', 'LPT2', 'LPT3', 'LPT4', 'LPT5', 'LPT6', 'LPT7', 'LPT8', 'LPT9'];

  const finalName = asciiOnly || defaultName;
  const isReserved = reservedNames.includes(finalName.toUpperCase());

  return isReserved ? `_${finalName}` : finalName;
}
