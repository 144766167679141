enum SeasonState {
  UNPUBLISHED = 'UNPUBLISHED',
  REGISTRATION = 'REGISTRATION',
  ROUND_1_TEST_PERIOD = 'ROUND_1_TEST_PERIOD',
  ROUND_1_ADVANCED_MARKING = 'ROUND_1_ADVANCED_MARKING',
  ROUND_1_ADVANCED_RESULTS_VERIFICATION = 'ROUND_1_ADVANCED_RESULTS_VERIFICATION',
  ROUND_1_ADVANCED_RESULTS_PUBLICATION = 'ROUND_1_ADVANCED_RESULTS_PUBLICATION',
  ROUND_1_RESULTS_PUBLICATION = 'ROUND_1_RESULTS_PUBLICATION',
  ROUND_1_MATERIALS_PUBLICATION = 'ROUND_1_MATERIALS_PUBLICATION',
  COMPLETE = 'COMPLETE'
}

const SEASON_STATE_STRINGS: { [K in SeasonState]: string } = {
  UNPUBLISHED: "Unpublished",
  REGISTRATION: "Registration period",
  ROUND_1_TEST_PERIOD: "Round 1 test period",
  ROUND_1_ADVANCED_MARKING: "Round 1 Advanced marking period",
  ROUND_1_ADVANCED_RESULTS_VERIFICATION: "Round 1 Advanced results verification",
  ROUND_1_ADVANCED_RESULTS_PUBLICATION: "Round 1 Advanced results",
  ROUND_1_RESULTS_PUBLICATION: "Round 1 results",
  ROUND_1_MATERIALS_PUBLICATION: "Round 1 materials released",
  COMPLETE: "Finished",
};

namespace SeasonState {
  export function values(): SeasonState[] {
    return [
      SeasonState.UNPUBLISHED,
      SeasonState.REGISTRATION,
      SeasonState.ROUND_1_TEST_PERIOD,
      SeasonState.ROUND_1_ADVANCED_MARKING,
      SeasonState.ROUND_1_ADVANCED_RESULTS_VERIFICATION,
      SeasonState.ROUND_1_ADVANCED_RESULTS_PUBLICATION,
      SeasonState.ROUND_1_RESULTS_PUBLICATION,
      SeasonState.ROUND_1_MATERIALS_PUBLICATION,
      SeasonState.COMPLETE
    ]
  }

  export function publicValues(): SeasonState[] {
    return SeasonState.values().filter(s => s !== SeasonState.UNPUBLISHED);
  }

  export function pretty(state: SeasonState): string {
    return SEASON_STATE_STRINGS[state];
  }

  export function isBetween(state: SeasonState | undefined, startInclusive?: SeasonState, endExclusive?: SeasonState): boolean {
    if (state == undefined) {
      return false;
    }

    const startIdx = startInclusive ? SeasonState.values().indexOf(startInclusive) : 0;
    const endIdx = endExclusive ? SeasonState.values().indexOf(endExclusive) : SeasonState.values().length;
    const stateIdx = SeasonState.values().indexOf(state);

    return startIdx <= stateIdx && stateIdx < endIdx;
  }

  export function isBefore(state: SeasonState | undefined, endExclusive: SeasonState): boolean {
    return SeasonState.isBetween(state, undefined, endExclusive);
  }

  export function isOrIsAfter(state: SeasonState | undefined, startInclusive: SeasonState): boolean {
    return SeasonState.isBetween(state, startInclusive, undefined);
  }

  export function next(state: SeasonState): SeasonState | undefined {
    const idx = SeasonState.values().indexOf(state);
    return idx < SeasonState.values().length - 1 ? SeasonState.values()[idx + 1] : undefined;
  }
}

export default SeasonState;
