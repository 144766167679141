import * as React from "react";
import { useEffect, useState } from "react";
import Editor from "./Editor";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField
} from "@mui/material";
import { AccountRole, PLURAL_ROLE_NAMES } from "../../../domain/Account";
import Grid from "@mui/material/Unstable_Grid2";
import SendIcon from "@mui/icons-material/Send";
import { useAccount } from "../../account/AccountContext";
import { sendRealEmail, sendTestEmail } from "../../../gateway/email";
import LoadingButton from '@mui/lab/LoadingButton';

interface Props {
  readonly invalidate: () => void;
}

export default function NewEmail({ invalidate }: Props) {
  const { account } = useAccount();
  const [subject, setSubject] = useState('');
  const [bodyContent, setBodyContent] = useState('');
  const [testEmailAddress, setTestEmailAddress] = useState('');
  const [emailName, setEmailName] = useState('');
  const [selectedRoles, setSelectedRoles] = useState<AccountRole[]>([]);

  // Validation error states
  const [subjectError, setSubjectError] = useState('');
  const [bodyError, setBodyError] = useState('');
  const [testEmailError, setTestEmailError] = useState('');
  const [emailNameError, setEmailNameError] = useState('');
  const [recipientsError, setRecipientsError] = useState('');

  // Snackbar state for feedback
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  // Editor last reset state, to clear the editor after sending an email
  const [editorLastReset, setEditorLastReset] = useState(0);

  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (account) {
      setTestEmailAddress(account.email);
    }
  }, [account]);

  const validateFields = () => {
    let isValid = true;

    if (!subject.trim()) {
      setSubjectError('Subject is required.');
      isValid = false;
    } else {
      setSubjectError('');
    }

    if (!bodyContent.trim() || bodyContent.replace(/<[^>]*>/g, '').trim() === '') {
      setBodyError('Body content must contain meaningful text.');
      isValid = false;
    } else {
      setBodyError('');
    }

    return isValid;
  };

  const validateTestEmail = () => {
    let isValid = validateFields();

    if (!testEmailAddress.trim()) {
      setTestEmailError('Test email address is required.');
      isValid = false;
    } else {
      setTestEmailError('');
    }

    return isValid;
  };

  const validateRealEmail = () => {
    let isValid = validateFields();

    if (!emailName.trim()) {
      setEmailNameError('Email name is required.');
      isValid = false;
    } else {
      setEmailNameError('');
    }

    if (selectedRoles.length === 0) {
      setRecipientsError('At least one recipient role must be selected.');
      isValid = false;
    } else {
      setRecipientsError('');
    }

    return isValid;
  };

  const resetFields = () => {
    setSubject('');
    setBodyContent('');
    setEmailName('');
    setSelectedRoles([]);
    setEditorLastReset(editorLastReset => editorLastReset + 1);
  }

  const handleSendTestEmail = () => {
    if (validateTestEmail()) {
      setSnackbarOpen(false);
      setSnackbarMessage('');

      sendTestEmail({
        emailRequest: {
          subject,
          htmlBodyContent: bodyContent,
        },
        testEmail: testEmailAddress,
      })
        .then(() => {
          setSnackbarMessage('Test email sent successfully!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        })
        .catch(() => {
          setSnackbarMessage('Failed to send test email.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
    }
  };

  const handleSendEmailToRoles = () => {
    if (validateRealEmail()) {
      setIsSending(true);
      sendRealEmail({
        emailRequest: {
          subject,
          htmlBodyContent: bodyContent,
        },
        emailName,
        recipientRoles: selectedRoles,
      })
        .then(() => {
          setSnackbarMessage('Email sent!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          resetFields();
          invalidate();
        })
        .catch(() => {
          setSnackbarMessage('Failed to send email.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        })
        .finally(() => setIsSending(false));
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title="New Email"
          sx={{
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <CardContent sx={{ px: 1, pt: 1, '&:last-child': { pb: 1 } }}>
          <Stack spacing={1}>
            <TextField
              label="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              error={!!subjectError}
              helperText={subjectError}
              margin="normal"
              sx={{ maxWidth: "620px" }}
            />
            <Editor onChange={setBodyContent} lastReset={editorLastReset} />
            {bodyError && <Box sx={{ color: 'error.main', mt: 1 }}>{bodyError}</Box>}
            <Box>
              <Grid container spacing={1} alignItems="stretch">
                <Grid xs={12} md={5}>
                  <Card variant="outlined" sx={{ height: "100%" }}>
                    <CardHeader title="Send test email" sx={{ pb: 0 }} />
                    <CardContent>
                      <Stack spacing={1}>
                        <TextField
                          label="Test email address"
                          value={testEmailAddress}
                          onChange={(e) => setTestEmailAddress(e.target.value)}
                          error={!!testEmailError}
                          helperText={testEmailError}
                          sx={{ flexGrow: 1 }}
                        />
                        <Button
                          variant="contained"
                          onClick={handleSendTestEmail}
                          sx={{ minWidth: "140px" }}
                          size="large"
                        >
                          Send Test
                        </Button>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid xs={12} md={7}>
                  <Card variant="outlined" sx={{ height: "100%" }}>
                    <CardHeader title="Send real email" sx={{ pb: 0 }} />
                    <CardContent>
                      <Stack spacing={1}>
                        <TextField
                          label="Email name"
                          value={emailName}
                          onChange={(e) => setEmailName(e.target.value)}
                          error={!!emailNameError}
                          helperText={emailNameError}
                        />
                        <FormControl error={!!recipientsError}>
                          <InputLabel id="roles-label">Recipients</InputLabel>
                          <Select
                            labelId="roles-label"
                            label="Recipients"
                            multiple
                            value={selectedRoles}
                            onChange={(e) => setSelectedRoles(e.target.value as AccountRole[])}
                            renderValue={(selected) => (selected as AccountRole[]).map(role => PLURAL_ROLE_NAMES[role]).join(', ')}
                            variant="outlined"
                          >
                            {Object.values(AccountRole).map(role => (
                              <MenuItem key={role} value={role}>
                                {PLURAL_ROLE_NAMES[role]}
                              </MenuItem>
                            ))}
                          </Select>
                          {recipientsError && <Box sx={{ color: 'error.main', mt: 1 }}>{recipientsError}</Box>}
                        </FormControl>
                        <LoadingButton
                          variant="contained"
                          onClick={handleSendEmailToRoles}
                          size="large"
                          color="warning"
                          startIcon={<SendIcon />}
                          loading={isSending}
                        >
                          Send
                        </LoadingButton>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </CardContent>
      </Card>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
