import * as React from "react";
import { Marker } from "../../../domain/MarkingCapacity";
import { Chip, Grid, Stack, Tooltip, Typography } from "@mui/material";
import AccountAvatar from "../../common/AccountAvatar";

interface Props {
  readonly marker: Marker
}

export default function MarkerDetails({ marker }: Props) {
  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Grid item xs="auto" sx={{ mr: 1 }}>
        <AccountAvatar account={marker.account} />
      </Grid>
      <Grid item xs>
        <Stack direction="column">
          <Typography sx={{ flexGrow: 1 }}>{marker.account.name}</Typography>
          <Typography variant="caption" style={{ wordWrap: "break-word" }}>{marker.account.email}</Typography>
        </Stack>
      </Grid>
      <Grid item xs="auto" sx={{ ml: 1 }}>
        <Tooltip title="Capacity (used/total)">
          <Chip
            size="small"
            color={marker.usedCapacity > marker.totalCapacity ? "warning" : "info"}
            label={`${marker.usedCapacity}/${marker.totalCapacity}`}
          />
        </Tooltip>
      </Grid>
    </Grid>
  )
}
