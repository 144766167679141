import * as React from 'react';
import { useEffect, useState } from 'react';
import { SentEmail } from "../../../domain/Email";
import { getAllSentEmails } from "../../../gateway/email";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Dialog, DialogTitle, DialogContent, IconButton, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AccountRole, PLURAL_ROLE_NAMES } from "../../../domain/Account";

interface Props {
  readonly lastUpdate: number;
}

export default function HistoricEmails({ lastUpdate }: Props) {
  const [emails, setEmails] = useState<SentEmail[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<SentEmail | null>(null);

  useEffect(() => {
    getAllSentEmails().then(setEmails);
  }, [lastUpdate]);

  const handleOpenDialog = (email: SentEmail) => {
    setSelectedEmail(email);
  };

  const handleCloseDialog = () => {
    setSelectedEmail(null);
  };

  const columns: GridColDef[] = [
    { field: 'emailName', headerName: 'Name', flex: 1 },
    {
      field: 'recipientRoles',
      headerName: 'Recipients',
      width: 200,
      valueFormatter: (params) => {
        const roles = params.value as AccountRole[];
        return roles
          .slice()
          .sort((a, b) => PLURAL_ROLE_NAMES[b].localeCompare(PLURAL_ROLE_NAMES[a]))
          .map((role) => PLURAL_ROLE_NAMES[role])
          .join(', ');
      },
    },
    { field: 'subject', headerName: 'Subject', flex: 1 },
    { field: 'senderEmailAddress', headerName: 'Sender', flex: 1 },
    {
      field: 'createdAt',
      headerName: 'Sent At',
      flex: 1,
      valueFormatter: (params) => new Date(params.value).toLocaleString(),
    },
    { field: 'totalSent', headerName: 'Sent', headerAlign: 'right', align: 'right' },
    {
      field: 'view',
      headerName: 'View',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <IconButton onClick={() => handleOpenDialog(params.row)}>
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={emails}
        columns={columns}
        autoHeight
        getRowId={(row) => row.id}
        density="compact"
        disableSelectionOnClick
        rowsPerPageOptions={[20, 50, 100]}
        initialState={{
          pagination: {
            pageSize: 20,
          },
        }}
        sx={{
          backgroundColor: 'background.paper',
          "& .MuiDataGrid-columnHeaders": {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            outline: 'none',
          },
          "& .MuiDataGrid-cell:focus": {
            outline: 'none',
          },
        }}
      />
      {selectedEmail && (
        <Dialog
          open={Boolean(selectedEmail)}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>{selectedEmail.subject}</DialogTitle>
          <DialogContent>
            <div
              dangerouslySetInnerHTML={{ __html: selectedEmail.htmlBodyContent }}
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}
