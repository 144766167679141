import * as React from "react";
import { Tooltip } from "@mui/material";

interface Props {
  readonly text: string;
  readonly maxLength?: number;
}

export default function TruncateWithTooltip({ text, maxLength = 50 }: Props) {
  if (text.length <= maxLength) {
    return <>{text}</>;
  }

  return (
    <Tooltip title={text} enterDelay={0}>
      <span>{text.substring(0, maxLength)}...</span>
    </Tooltip>
  );
}
