import * as React from 'react';
import { useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Centre from '../../../domain/Centre';
import { UUID } from '../../../domain/UUID';
import Student from '../../../domain/Student';
import Gender from '../../../domain/Gender';
import { useForm } from 'react-hook-form';
import FormInputText from '../../form/FormInputText';
import FormInputSelect from '../../form/FormInputSelect';
import { addStudent } from '../../../gateway/students';
import { Exam } from "../../../domain/Season";
import { useCurrentSeason } from "../../exams/SeasonContext";
import ExamLevel from "../../../domain/ExamLevel";
import FormInputUUIDSelection from "../../form/FormInputUUIDSelection";
import ResponsiveText from "../../common/ResponsiveText";

interface Props {
  readonly centreId: UUID<Centre>,
  readonly onNewStudent: (student: Student) => void
}

interface AddStudentFormData {
  readonly examId: UUID<Exam> | null,
  readonly name: string,
  readonly gender: Gender | null,
  readonly year: string | null
}

export default function AddStudentForm({ centreId, onNewStudent }: Props) {
  const { currentSeason } = useCurrentSeason();

  const { control, handleSubmit, setFocus, setValue } = useForm<AddStudentFormData>({
    defaultValues: {
      examId: '',
      name: '',
      gender: null,
      year: null
    }
  });

  useEffect(() => {
    if (currentSeason !== undefined) {
      setValue("examId", currentSeason.exams[ExamLevel.ADVANCED].examId);
    }
  }, [currentSeason?.id]);

  const onSubmit = handleSubmit(({ examId, name, gender, year }) => {
    if (currentSeason === undefined || examId === null || gender === null || year === null) {
      return;
    }

    addStudent({ centreId, seasonId: currentSeason.id, examId, name, gender: gender || Gender.UNKNOWN, year, createdByMarker: false })
      .then(onNewStudent)
      .then(() => {
        setValue('name', '');
        setValue('gender', null);
        setFocus('name', { shouldSelect: true });
      });
  });

  if (currentSeason === undefined) {
    return <Typography>Loading</Typography>;
  }

  let examOptions = ExamLevel.values()
    .map(l => currentSeason.exams[l])
    .filter(e => e !== undefined)
    .map(e => ({ id: e.examId, name: Exam.name(currentSeason, e) }));

  return (
    <form onSubmit={onSubmit} noValidate>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>
        <FormInputText
          name="name"
          label="Full name"
          control={control}
          rules={{ required: "Please provide the student's name" }}
          sx={{ minWidth: { md: 200, lg: 330 } }}
        />
        <FormInputSelect
          name="gender"
          label="Gender"
          options={Gender.values().map(gender => ({ value: gender, label: Gender.pretty(gender) }))}
          control={control}
          rules={{ required: "Please select a gender" }}
          sx={{ minWidth: { md: 170, lg: 220 } }}
        />
        <FormInputSelect
          name="year"
          label="Year"
          options={[...([4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(y => (`Year ${y}`))), 'Other']}
          control={control}
          rules={{ required: "Please select a year" }}
          sx={{ minWidth: 140 }}
        />
        <FormInputUUIDSelection
          name="examId"
          label="Exam"
          control={control}
          options={examOptions}
        />
        <Button type="submit" variant="outlined" startIcon={<AddIcon />}>
          <ResponsiveText
            xs="Register student"
            md="Register"
            lg="Register student"
          />
        </Button>
      </Stack>
    </form>
  );
}
