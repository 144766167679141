enum ExamLevel {
  ADVANCED = 'ADVANCED',
  INTERMEDIATE = 'INTERMEDIATE',
  FOUNDATION = 'FOUNDATION',
  BREAKTHROUGH = 'BREAKTHROUGH',
  OTHER = 'OTHER'
}

const EXAM_LEVEl_STRINGS: { [K in ExamLevel]: string } = {
  ADVANCED: "Advanced",
  INTERMEDIATE: "Intermediate",
  FOUNDATION: "Foundation",
  BREAKTHROUGH: "Breakthrough",
  OTHER: "Other",
};

namespace ExamLevel {
  export function values(): ExamLevel[] {
    return [ExamLevel.ADVANCED, ExamLevel.INTERMEDIATE, ExamLevel.FOUNDATION, ExamLevel.BREAKTHROUGH, ExamLevel.OTHER]
  }

  export function reverseValues(): ExamLevel[] {
    return [ExamLevel.BREAKTHROUGH, ExamLevel.FOUNDATION, ExamLevel.INTERMEDIATE, ExamLevel.ADVANCED, ExamLevel.OTHER]
  }

  export function roundOneValues(): ExamLevel[] {
    // TODO eventually this will exclude a new ExamLevel called ROUND_TWO
    return ExamLevel.values()
  }

  export function selfServeMarkingValues(): ExamLevel[] {
    return ExamLevel.roundOneValues().filter(level => level !== ExamLevel.ADVANCED).sort(ExamLevel.comparator(true));
  }

  export function comparator(reversed: boolean = false): (a: ExamLevel, b: ExamLevel) => number {
    if (reversed)
    {
      return (a, b) => ExamLevel.reverseValues().indexOf(a) - ExamLevel.reverseValues().indexOf(b);
    }

    return (a, b) => ExamLevel.values().indexOf(a) - ExamLevel.values().indexOf(b);
  }

  export function pretty(level: ExamLevel): string {
    return EXAM_LEVEl_STRINGS[level];
  }
}

export default ExamLevel;
