import axios from 'axios';
import { AccountRole } from "../domain/Account";
import Email, { SentEmail } from "../domain/Email";

interface NewEmailRequest {
  readonly subject: string;
  readonly htmlBodyContent: string;
}

interface SendTestEmailRequest {
  readonly emailRequest: NewEmailRequest;
  readonly testEmail: string;
}

interface SendRealEmailRequest {
  readonly emailRequest: NewEmailRequest;
  readonly emailName: string;
  readonly recipientRoles: AccountRole[];
}

interface EmailListResponse {
  readonly emails: SentEmail[];
}

export function getAllSentEmails(): Promise<SentEmail[]> {
  return axios.get<EmailListResponse>(`/email/all`).then(response => response.data.emails);
}

export function sendTestEmail({ emailRequest, testEmail }: SendTestEmailRequest): Promise<void> {
  return axios.post(`/email/send/test`, { emailRequest, testEmail });
}

export function sendRealEmail({ emailRequest, emailName, recipientRoles }: SendRealEmailRequest): Promise<void> {
  return axios.post(`/email/send/real`, { emailRequest, emailName, recipientRoles });
}
