import { Box, Typography } from "@mui/material";
import * as React from "react";
import { Variant } from "@mui/material/styles/createTypography";

type Props = {
  readonly xs: string | number,
  readonly sm?: string | number,
  readonly md?: string | number,
  readonly lg?: string | number,
  readonly variant?: Variant,
  readonly noWrap?: boolean,
}

export default function ResponsiveText({ xs, sm, md, lg, variant, noWrap = false }: Props) {
  if (sm === undefined) {
    sm = xs;
  }

  if (md === undefined) {
    md = sm;
  }

  if (lg === undefined) {
    lg = md;
  }

  const typography = (text: string | number) => (
    <Typography
      variant={variant || 'inherit'}
      sx={noWrap ? { overflow: 'hidden', whiteSpace: 'nowrap'} : {}}
    >
      {text}
    </Typography>
  );

  return (
    <>
      {xs !== "" && <Box component="span" sx={{ display: { xs: "block", sm: "none" } }}>{typography(xs)}</Box>}
      {sm !== "" && <Box component="span" sx={{ display: { xs: "none", sm: "block", md: "none" } }}>{typography(sm)}</Box>}
      {md !== "" && <Box component="span" sx={{ display: { xs: "none", sm: "none", md: "block", lg: "none" } }}>{typography(md)}</Box>}
      {lg !== "" && <Box component="span" sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>{typography(lg)}</Box>}
    </>
  )
}
