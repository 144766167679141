import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { UUID } from "../../../domain/UUID";
import { Exam } from "../../../domain/Season";
import AwardBoundaries from "../../../domain/AwardBoundaries";
import { deleteAwardBoundaries, getAwardBoundaries, putAwardBoundaries } from "../../../gateway/awardBoundaries";
import AwardBoundariesView from "./AwardBoundariesView";
import AwardBoundariesForm from "./AwardBoundariesForm";
import StudentExamResult from "../../../domain/StudentExamResult";

interface Props {
  readonly examId: UUID<Exam>;
  readonly invalidate: () => void;
  readonly results: StudentExamResult[] | undefined;
  readonly editable?: boolean;
}

export default function ExamAwardBoundariesCard({ examId, editable = false, results, invalidate }: Props) {
  const [awardBoundaries, setAwardBoundaries] = useState<AwardBoundaries | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsEditing(false);
    getAwardBoundaries(examId).then(setAwardBoundaries);
  }, [examId]);

  const handleSave = async (updatedBoundaries: AwardBoundaries) => {
    try {
      const savedBoundaries = await putAwardBoundaries(examId, updatedBoundaries);
      setAwardBoundaries(savedBoundaries);
      setIsEditing(false);
      invalidate();
    } catch (error) {
      console.error("Failed to save award boundaries", error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteAwardBoundaries(examId);
      setAwardBoundaries({ examId, awardToThreshold: {} });
      setIsEditing(false);
      invalidate();
    } catch (error) {
      console.error("Failed to delete award boundaries", error);
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={editable ? "Configure boundaries" : "Boundaries"}
        sx={{ pb: 0 }}
      />
      <CardContent>
        {(awardBoundaries === undefined) ?
          <Typography>Loading...</Typography> :
          (
            isEditing ? (
              <AwardBoundariesForm
                initialBoundaries={awardBoundaries}
                results={results}
                onSave={handleSave}
                onCancel={() => setIsEditing(false)}
                onDelete={handleDelete}
              />
            ) : (
              <AwardBoundariesView
                boundaries={awardBoundaries}
                editable={editable}
                results={results}
                onEdit={() => setIsEditing(true)}
              />
            )
          )}
      </CardContent>
    </Card>
  );
}
