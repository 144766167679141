import { UUID } from './UUID';
import Account from "./Account";

export enum DocumentType {
  QUESTIONS = 'QUESTIONS',
  SOLUTIONS = 'SOLUTIONS',
  SCRIPT = 'SCRIPT',
}

export default interface Document {
  readonly id: UUID<Document>;
  readonly type: DocumentType;
  readonly name: string;
  readonly url: string;
  readonly uploadedAt: number;
}
