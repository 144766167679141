import * as React from 'react';
import { Box, Paper, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import GoHome from "./GoHome";

interface Props {
  readonly title?: string,
  readonly subtitle?: string,
  readonly children?: React.ReactNode
  readonly main?: boolean,
  readonly hideHomeLink?: boolean,
  readonly padding?: number
}

export default function Page({ title, subtitle, children, main = false, hideHomeLink = false, padding = 3 }: Props) {
  const contents = (
    <Stack spacing={1}>
      {(title || subtitle) && (
        <Box sx={main ? { pb: 1 } : { pt: 2, pb: 1 }}>
          {title && <Typography variant="h4">{title}</Typography>}
          {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
        </Box>
      )}
      {children != undefined && <Box>{children}</Box>}
    </Stack>
  );

  if (!main) {
    return <>
      {contents}
      {hideHomeLink || <Box sx={{ mt: 2, mb: 1 }}><GoHome /></Box>}
    </>;
  }

  return (
    <>
      <Paper variant="outlined" sx={{ p: padding }}>
        {contents}
      </Paper>
      {hideHomeLink || <Box sx={{ mt: 2, mb: 1 }}><GoHome /></Box>}
    </>
  );
}
