import * as React from "react";
import { useEffect, useState } from "react";
import SelectMarker from "./SelectMarker";
import { Grid, IconButton, TableCell, Typography } from "@mui/material";
import { Marker } from "../../../domain/MarkingCapacity";
import { UUID } from "../../../domain/UUID";
import Centre from "../../../domain/Centre";
import Account from "../../../domain/Account";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import MarkerDetails from "./MarkerDetails";

interface Props {
  readonly centreId: UUID<Centre>,
  readonly selectedMarkerId: UUID<Account> | undefined,
  readonly allMarkers: ReadonlyMap<UUID<Account>, Marker>,
  readonly assign: (centreId: UUID<Centre>, accountId: UUID<Account>) => Promise<void>,
  readonly unassign: (centreId: UUID<Centre>) => Promise<void>
}

export default function MarkerCell({ centreId, selectedMarkerId, allMarkers, assign, unassign }: Props) {
  const [unsavedSelectedMarkerId, setUnsavedSelectedMarkerId] = useState(selectedMarkerId);
  const [editing, setEditing] = useState(false);
  const selectedMarker = selectedMarkerId === undefined ? undefined : allMarkers.get(selectedMarkerId);

  useEffect(() => {
    setUnsavedSelectedMarkerId(selectedMarkerId);
    setEditing(false);
  }, [selectedMarkerId]);

  const handleSave = () => {
    if (unsavedSelectedMarkerId === undefined) {
      unassign(centreId).then(() => setEditing(false));
    } else {
      assign(centreId, unsavedSelectedMarkerId).then(() => setEditing(false));
    }
  }

  return (
    <TableCell>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item xs>
          {editing
            ? (
              <SelectMarker
                markers={allMarkers}
                selectedMarkerId={unsavedSelectedMarkerId}
                onSelectMarkerId={setUnsavedSelectedMarkerId}
              />
            ) : (
              selectedMarker === undefined
                ? <Typography>None</Typography>
                : <MarkerDetails marker={selectedMarker} />
            )}
        </Grid>
        <Grid item xs="auto" sx={{ ml: 0.5 }}>
          {editing
            ? <IconButton color="primary" onClick={handleSave}><SaveIcon /></IconButton>
            : <IconButton onClick={() => setEditing(true)}><EditIcon /></IconButton>
          }
        </Grid>
      </Grid>
    </TableCell>
  )
}
