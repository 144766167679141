import { UUID } from './UUID';
import ExamLevel from "./ExamLevel";
import SeasonState from "./SeasonState";
import Document from "./Document";

export interface Question {
  readonly id: UUID<Question>,
  readonly number: number,
  readonly name: string,
  readonly maximumPoints: number
}

export interface ExamQuestion extends Question {
  readonly maximumMarks: number
}

interface Exam {
  readonly examId: UUID<Exam>,
  readonly level: ExamLevel,
  readonly questions: ExamQuestion[],
  readonly questionsDocument: UUID<Document> | null,
  readonly solutionsDocument: UUID<Document> | null,
}

namespace Exam {
  export function name(season: SeasonMetadata, exam: Exam | undefined): string {
    return `${ExamLevel.pretty(exam?.level || ExamLevel.OTHER)} ${season.year}`;
  }
}

export { Exam };

export interface SeasonMetadata {
  readonly id: UUID<Season>,
  readonly year: number,
  readonly state: SeasonState
}

export default interface Season extends SeasonMetadata {
  readonly questions: Question[],
  readonly exams: {
    [K in ExamLevel]: Exam
  }
}

