import * as React from "react";
import { IconButton, TableCell, TableRow, TextField, Tooltip } from "@mui/material";
import LevelMarkCell from "./LevelMarkCell";
import DeleteIcon from "@mui/icons-material/Delete";
import { QuestionRowProps } from "./QuestionRow";

export default function EditableQuestionRow({ workingSeason, questionId, levels }: QuestionRowProps) {
  const question = workingSeason.seasonView.questions.find(q => q.id === questionId);

  if (question === undefined) {
    return <TableRow><TableCell>Invalid configuration</TableCell></TableRow>;
  }

  return (
    <TableRow hover>
      <TableCell align="right" padding="none">
        <TextField
          defaultValue={question.number}
          onBlur={e => workingSeason.onChangeQuestion(questionId, 'number', parseInt(e.currentTarget.value))}
          size="small"
          sx={{ width: 50, mt: 0.5, mb: 0.5, mr: 2 }}
          InputProps={{ inputProps: { sx: { textAlign: "right" } } }}
        />
      </TableCell>
      <TableCell padding="none">
        <TextField
          defaultValue={question.name}
          onBlur={e => workingSeason.onChangeQuestion(questionId, 'name', e.currentTarget.value)}
          size="small"
          fullWidth
          sx={{ mt: 0.5, mb: 0.5, mr: 1 }}
        />
      </TableCell>
      <TableCell align="right" padding="none">
        <TextField
          defaultValue={question.maximumPoints}
          onBlur={e => workingSeason.onChangeQuestion(questionId, 'maximumPoints', parseInt(e.currentTarget.value))}
          size="small"
          sx={{ width: 60, mt: 0.5, mb: 0.5, mr: 1 }}
          InputProps={{ inputProps: { sx: { textAlign: "right" } } }}
        />
      </TableCell>
      {levels.map(l => (
        <LevelMarkCell key={l} workingSeason={workingSeason} exam={workingSeason.seasonView.exams[l]} questionId={questionId} editing/>
      ))}
      <TableCell>
        <Tooltip title="Delete">
          <IconButton color="error" size="small" onClick={() => workingSeason.onDeleteQuestion(questionId)}><DeleteIcon/></IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
