import axios from 'axios';
import { UUID } from '../domain/UUID';
import Centre from '../domain/Centre';
import Student from '../domain/Student';
import Season, { Exam } from "../domain/Season";
import StudentExamResult from "../domain/StudentExamResult";
import { downloadBlob } from "./download";

interface GetResultsRequest {
  readonly examId?: UUID<Exam>,
  readonly centreId: UUID<Centre>,
}

type GetResultsResponse = { [key: UUID<Student>]: StudentExamResult };

export function getResults({ examId, centreId }: GetResultsRequest): Promise<{
  [key: UUID<Student>]: StudentExamResult
}> {
  if (!examId) {
    return axios.get<GetResultsResponse>('/results', { params: { centreId } })
      .then(response => response.data);
  }

  return axios.get<GetResultsResponse>('/results', { params: { examId, centreId } })
    .then(response => response.data);
}

export function getAllResults(examId: UUID<Exam>): Promise<{
  [key: UUID<Student>]: StudentExamResult
}> {
  return axios.get<GetResultsResponse>(`/results/all/${examId}`)
    .then(response => response.data);
}

export function downloadStudentCertificate(studentId: UUID<Student>, requestedFileName: string) {
  return axios.get(`/certificates/student/${studentId}/${requestedFileName}.pdf`, { responseType: 'blob' })
    .then(downloadBlob);
}

export function downloadAllCentreCertificates(seasonId: UUID<Season>, centreId: UUID<Centre>, requestedFileName: string) {
  return axios.get(`/certificates/centre/${centreId}/${seasonId}/${requestedFileName}.pdf`, { responseType: 'blob' })
    .then(downloadBlob);
}
