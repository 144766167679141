import * as React from "react";
import { useEffect } from "react";
import Page from "../common/Page";
import { useAccount } from "../account/AccountContext";
import { Stack, Typography } from "@mui/material";
import Account, { AccountRole } from "../../domain/Account";
import { ROUTE_DASHBOARD } from "../../UkloRoutes";
import { useNavigate } from "react-router-dom";
import RequestRolesButtons from "./RequestRolesButtons";

const selfServiceRoles = [
  { role: AccountRole.CENTRE_MANAGER, text: "Register students to participate" },
  { role: AccountRole.MARKER, text: "Volunteer to mark scripts" }
]

export default function WelcomePage() {
  const { account } = useAccount();
  const navigate = useNavigate();

  useEffect(() => {
    if (account !== undefined && account.roles.some(existingRole => selfServiceRoles.findIndex(ssr => existingRole === ssr.role) !== -1)) {
      navigate(ROUTE_DASHBOARD, { replace: true });
    }
  }, [account?.id]);

  if (account === undefined) {
    return <Typography>Loading</Typography>
  }

  return (
    <Page title={`Welcome, ${Account.firstName(account)}`} main hideHomeLink>
      <Stack spacing={1}>
        <Typography sx={{pt: 2}}>
          We use the UKLO portal to manage registration and marking for the UK Linguistics Olympiad.
        </Typography>
        <Typography>
          Let's get your account set up.
        </Typography>
        <Typography fontWeight="bold" sx={{pb: 1}}>
          What would you like to do?
        </Typography>
        <RequestRolesButtons roles={selfServiceRoles} onNewRole={() => navigate(ROUTE_DASHBOARD)} />
        <Typography variant="body2" sx={{pt: 1}}>
          If you'd like to do both, pick one to start with.
          You can set up the other afterwards.
        </Typography>
      </Stack>
    </Page>
  )
}
