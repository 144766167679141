import * as React from 'react';
import Centre from '../../domain/Centre';
import { Button, ButtonProps, Card, CardHeader, styled } from '@mui/material';
import CentreType from '../../domain/CentreType';

interface Props {
  readonly centre: Centre,
  readonly onAction: () => void,
  readonly actionText: string
}

const WhiteButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

export default function CentreDetailsCard({ centre, onAction, actionText }: Props) {
  return (
    <Card variant="outlined" sx={{backgroundColor: "primary.main", color: "primary.contrastText", borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}>
      <CardHeader
        title={centre.name}
        subheader={centreMetadata(centre)}
        action={<WhiteButton onClick={onAction}>{actionText}</WhiteButton>}
        subheaderTypographyProps={{variant: "subtitle2", color: "primary.contrastText"}}
      />
    </Card>
  );
}

function centreMetadata(centre: Centre) {
  let metadata = centre.postcode;

  if (centre.type) {
    metadata += `, ${CentreType.pretty(centre.type).toLowerCase()}`;
  }

  metadata += centre.selective ? ', selective' : ', not selective';

  if (centre.ages) {
    metadata += `, ${centre.ages}`;
  }

  return metadata;
}
