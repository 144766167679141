import axios from 'axios';
import Document, { DocumentType } from '../domain/Document';
import { UUID } from "../domain/UUID";
import Centre from "../domain/Centre";
import { Exam } from "../domain/Season";
import { downloadBlob } from "./download";

interface DocumentListResponse {
  readonly documents: Document[];
}

export function getDocument(id: UUID<Document>): Promise<Document> {
  return axios.get<Document>(`/documents/${id}`).then(response => response.data);
}

export function postDocument(file: File, documentType: DocumentType, onProgress: (progress: number) => void | undefined): Promise<Document> {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post<Document>(`/documents/${documentType.toLowerCase()}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (progressEvent: ProgressEvent) => {
      if (onProgress !== undefined) {
        onProgress(100 * progressEvent.loaded / progressEvent.total);
      }
    }
  }).then(response => response.data);
}

export function getCentreExamDocuments(centreId: UUID<Centre>, examId: UUID<Exam>): Promise<Document[]> {
  return axios.get<DocumentListResponse>(`/documents/centre/${centreId}/exam/${examId}/documents`)
    .then(response => response.data.documents);
}

export function addCentreExamDocuments(centreId: UUID<Centre>, examId: UUID<Exam>, documentIds: UUID<Document>[]): Promise<void> {
  return axios.post(`/documents/centre/${centreId}/exam/${examId}/documents`, { documentIds });
}

export function removeCentreExamDocuments(centreId: UUID<Centre>, examId: UUID<Exam>, documentIds: UUID<Document>[]): Promise<void> {
  return axios.delete(`/documents/centre/${centreId}/exam/${examId}/documents`, { data: { documentIds } });
}

export function downloadCentreExamDocumentBundle(centreId: UUID<Centre>, examId: UUID<Exam>): Promise<void> {
  return axios.get(`/documents/centre/${centreId}/exam/${examId}/documents/download`, { responseType: 'blob' })
    .then(downloadBlob);
}
