import { MarkingAssignment, MarkingState } from "../../../domain/MarkingAssignment";
import Centre from "../../../domain/Centre";
import { Marker } from "../../../domain/MarkingCapacity";
import Account from "../../../domain/Account";
import { UUID } from "../../../domain/UUID";
import { useEffect, useReducer } from "react";
import { Exam } from "../../../domain/Season";
import { deleteAssignment, getAllAssignments, getAllMarkers, putAssignment } from "../../../gateway/marking";

interface MarkerAssignmentStatistics {
  readonly totalCentres: number,
  readonly centresWithoutMarkers: number,
  readonly centresFinished: number,
}

export interface MarkerAssignments {
  readonly markers: ReadonlyMap<UUID<Account>, Marker>,
  readonly assignments: MarkingAssignment[],
  readonly statistics: MarkerAssignmentStatistics,
  readonly assign: (centreId: UUID<Centre>, accountId: UUID<Account>) => Promise<void>,
  readonly unassign: (centreId: UUID<Centre>) => Promise<void>
}

interface MarkerAssignmentReducerState {
  readonly markers: Map<UUID<Account>, Marker>,
  readonly assignments: Map<UUID<Centre>, MarkingAssignment>,
}

interface Reset {
  readonly type: "RESET",
  readonly value: MarkerAssignmentReducerState
}

interface PutAssignment {
  readonly type: "PUT_ASSIGNMENT",
  readonly value: {
    readonly centreId: UUID<Centre>,
    readonly accountId: UUID<Account>
  }
}

interface DeleteAssignment {
  readonly type: "DELETE_ASSIGNMENT",
  readonly value: UUID<Centre>
}

type Action = Reset | PutAssignment | DeleteAssignment;

function markerAssignmentsReducer(previousState: MarkerAssignmentReducerState, action: Action): MarkerAssignmentReducerState {
  switch (action.type) {
    case "RESET": {
      return action.value;
    }

    case "PUT_ASSIGNMENT": {
      const oldAssignment = previousState.assignments.get(action.value.centreId);

      if (oldAssignment === undefined)
      {
        return previousState;
      }

      const existingMarker = oldAssignment.marker?.id === undefined ? undefined : previousState.markers.get(oldAssignment.marker.id);
      const newMarker = previousState.markers.get(action.value.accountId);

      if (newMarker === undefined || existingMarker?.account.id === newMarker.account.id) {
        return previousState;
      }

      const assignmentCapacityRequirement = Math.max(oldAssignment.registeredStudents, oldAssignment.estimatedStudents);

      const newMarkers = new Map(previousState.markers);
      newMarkers.set(newMarker.account.id, { ...newMarker, usedCapacity: newMarker.usedCapacity + assignmentCapacityRequirement });

      if (existingMarker !== undefined)
      {
        newMarkers.set(existingMarker.account.id, { ...existingMarker, usedCapacity: existingMarker.usedCapacity - assignmentCapacityRequirement });
      }

      const newAssignments = new Map(previousState.assignments);
      const newAssignment = { ...oldAssignment, marker: newMarker.account, markingState: MarkingState.MARKING };
      newAssignments.set(newAssignment.centre.id, newAssignment);

      return { markers: newMarkers, assignments: newAssignments };
    }

    case "DELETE_ASSIGNMENT": {
      const oldAssignment = previousState.assignments.get(action.value);

      if (oldAssignment === undefined || oldAssignment.marker === null)
      {
        return previousState;
      }

      const newAssignments = new Map(previousState.assignments);
      const newAssignment = { ...oldAssignment, marker: null, markingState: null };
      newAssignments.set(newAssignment.centre.id, newAssignment);

      const newMarkers = new Map(previousState.markers);
      const oldMarker = previousState.markers.get(oldAssignment.marker.id);

      if (oldMarker !== undefined)
      {
        const assignmentCapacityRequirement = Math.max(oldAssignment.registeredStudents, oldAssignment.estimatedStudents);
        const newMarker = { ...oldMarker, usedCapacity: oldMarker.usedCapacity - assignmentCapacityRequirement };
        newMarkers.set(newMarker.account.id, newMarker);
      }

      return { markers: newMarkers, assignments: newAssignments };
    }
  }
  return previousState;
}

function compareMarkerAssignments(a: MarkingAssignment, b: MarkingAssignment): number {
  if (a.marker === null && b.marker !== null) {
    return -1;
  }

  if (a.marker !== null && b.marker === null) {
    return 1;
  }

  if (a.markingState === MarkingState.MARKING && b.markingState !== MarkingState.MARKING) {
    return -1;
  }

  if (a.markingState !== MarkingState.MARKING && b.markingState === MarkingState.MARKING) {
    return 1;
  }

  return a.centre.name.localeCompare(b.centre.name);
}

export default function useMarkerAssignments(examId: UUID<Exam>, lastChange: number): MarkerAssignments {
  const [state, dispatch] = useReducer(markerAssignmentsReducer, { markers: new Map(), assignments: new Map() });

  useEffect(() => {
    Promise.all([getAllMarkers(examId), getAllAssignments(examId)])
      .then(([markers, assignments]) => {
        const markersMap = new Map<UUID<Account>, Marker>();
        for (let marker of markers) {
          markersMap.set(marker.account.id, marker);
        }

        const assignmentsMap = new Map<UUID<Centre>, MarkingAssignment>();
        for (let assignment of assignments) {
          assignmentsMap.set(assignment.centre.id, assignment);
        }

        dispatch({ type: "RESET", value: { markers: markersMap, assignments: assignmentsMap }});
      });
  }, [examId, lastChange]);

  const handleAssign = (centreId: UUID<Centre>, accountId: UUID<Account>) =>
    putAssignment(examId, centreId, accountId).then(() => dispatch({ type: "PUT_ASSIGNMENT", value: { centreId, accountId } }))

  const handleUnassign = (centreId: UUID<Centre>) =>
    deleteAssignment(examId, centreId).then(() => dispatch({ type: "DELETE_ASSIGNMENT", value: centreId }))

  return {
    markers: state.markers,
    assignments: Array.from(state.assignments.values()).sort(compareMarkerAssignments),
    statistics: {
      totalCentres: state.assignments.size,
      centresWithoutMarkers: Array.from(state.assignments.values()).filter(a => a.marker === null).length,
      centresFinished: Array.from(state.assignments.values()).filter(a => a.markingState === MarkingState.FINISHED).length
    },
    assign: handleAssign,
    unassign: handleUnassign
  };
}
