import Season, { Exam } from "../../domain/Season";
import { UUID } from "../../domain/UUID";
import Centre from "../../domain/Centre";
import StudentPointsTable from "./StudentPointsTable";
import { getMyManagedCentres } from "../../gateway/centres";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_DASHBOARD } from "../../UkloRoutes";
import ExamLevel from "../../domain/ExamLevel";
import { getStudents } from "../../gateway/students";
import { Card, CardContent, Stack } from "@mui/material";
import NumberedChip from "../common/NumberedChip";
import Page from "../common/Page";
import ExamDocumentDownloadCard from "../documents/ExamDocumentDownloadCard";

type Props = {
  readonly season: Season,
  readonly centreId: UUID<Centre>;
}

export default function CentreManagerMarkingPage({ season, centreId }: Props) {
  const navigate = useNavigate();
  const [managedCentres, setManagedCentres] = useState<Centre[] | undefined>(undefined);
  const [registeredStudentCounts, setRegisteredStudentCounts] = useState<Map<ExamLevel, number> | undefined>(undefined);
  const [selectedExam, setSelectedExam] = useState<UUID<Exam> | undefined>(undefined);

  useEffect(() => {
    getMyManagedCentres().then(setManagedCentres);
  }, []);

  const getAndSetRegisteredStudentCounts = () => {
    if (managedCentres === undefined) {
      return Promise.resolve();
    }

    if (!managedCentres.some(centre => centre.id === centreId)) {
      return Promise.resolve();
    }

    return getStudents({ centreId, seasonId: season.id })
      .then(students => {
        const counts = new Map<ExamLevel, number>();

        students.forEach(student => {
          const level = Object.values(season.exams).find(exam => exam.examId === student.examId)?.level;
          if (level === undefined || !ExamLevel.selfServeMarkingValues().includes(level)) {
            return;
          }

          const count = counts.get(level) || 0;
          counts.set(level, count + 1);
        });

        setRegisteredStudentCounts(counts);
      });
  }

  useEffect(() => {
    getAndSetRegisteredStudentCounts()
  }, [season.id, centreId, managedCentres]);

  useEffect(() => {
    console.log("Running effect: " + registeredStudentCounts);
    if (registeredStudentCounts === undefined || selectedExam !== undefined) {
      return;
    }

    const firstExamIdWithRegisteredStudents = ExamLevel.selfServeMarkingValues().map(level => season.exams[level])
      .filter(exam => exam !== undefined)
      .find(exam => registeredStudentCounts.get(exam.level) !== undefined && registeredStudentCounts.get(exam.level)! > 0)
      ?.examId;

    if (firstExamIdWithRegisteredStudents !== undefined) {
      setSelectedExam(firstExamIdWithRegisteredStudents);
    } else {
      setSelectedExam(season.exams[ExamLevel.selfServeMarkingValues()[0]]?.examId);
    }
  }, [registeredStudentCounts]);

  if (managedCentres === undefined || selectedExam === undefined || registeredStudentCounts === undefined) {
    return <div>Loading...</div>;
  }

  if (!managedCentres.some(centre => centre.id === centreId)) {
    navigate(ROUTE_DASHBOARD);
  }

  const examsWithRegisteredStudents = ExamLevel.selfServeMarkingValues().map(level => season.exams[level])
    .filter(exam => exam !== undefined && registeredStudentCounts.get(exam.level) !== undefined && registeredStudentCounts.get(exam.level)! > 0);
  const examsWithoutRegisteredStudents = ExamLevel.selfServeMarkingValues().map(level => season.exams[level])
    .filter(exam => exam !== undefined && registeredStudentCounts.get(exam.level) === undefined);

  return (
    <Page title="Marking">
      <Stack direction="column" spacing={1}>
        <Card variant="outlined">
          <CardContent>
            <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1} sx={{ mb: 2 }}>
              {examsWithRegisteredStudents.length > 0 && (
                <>
                  {examsWithRegisteredStudents.map(exam => (
                    <NumberedChip
                      key={exam.examId}
                      text={ExamLevel.pretty(exam.level)}
                      number={registeredStudentCounts.get(exam.level) || 0}
                      onClick={() => setSelectedExam(exam.examId)}
                      selected={selectedExam === exam.examId}
                    />
                  ))}
                  <div style={{ flexGrow: 1 }} />
                </>
              )}
              {examsWithoutRegisteredStudents.map(exam => (
                <NumberedChip
                  key={exam.examId}
                  text={ExamLevel.pretty(exam.level)}
                  number={registeredStudentCounts.get(exam.level) || 0}
                  onClick={() => setSelectedExam(exam.examId)}
                  selected={selectedExam === exam.examId}
                />
              ))}
            </Stack>
            <StudentPointsTable
              seasonId={season.id}
              examId={selectedExam}
              centreId={centreId}
              readOnly={false}
              refreshStudentCounts={getAndSetRegisteredStudentCounts}
            />
          </CardContent>
        </Card>
        <ExamDocumentDownloadCard
          season={season}
          onlyExamIds={ExamLevel.selfServeMarkingValues()
            .map(level => season.exams[level])
            .filter(exam => exam !== undefined)
            .map(exam => exam!.examId)}
        />
      </Stack>
    </Page>
  );
}
