import * as React from 'react';
import { useRef, useState } from 'react';
import Season, { Exam } from "../../domain/Season";
import { UUID } from "../../domain/UUID";
import Centre from "../../domain/Centre";
import { TableCell, TableRow, TextField } from "@mui/material";
import { addStudent } from "../../gateway/students";
import Gender from "../../domain/Gender";
import { LoadingButton } from "@mui/lab";
import AddIcon from '@mui/icons-material/Add';

type Props = {
  readonly examId: UUID<Exam>,
  readonly seasonId: UUID<Season>,
  readonly centreId: UUID<Centre>,
  readonly refresh: () => Promise<any>,
}

export default function StudentPointsTableNewStudentRow({ examId, seasonId, centreId, refresh }: Props) {
  const studentNameRef = useRef<HTMLInputElement>(null);
  const [addingStudent, setAddingStudent] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleAddStudent = () => {
    if (studentNameRef.current === null) {
      return;
    }

    const name = studentNameRef.current.value.trim();

    if (name === '') {
      setError('Please input a name');
      return;
    }

    setAddingStudent(true);

    addStudent({
      centreId,
      seasonId,
      examId,
      name,
      gender: Gender.UNKNOWN,
      year: '',
      createdByMarker: true,
    })
      .then(refresh)
      .then(() => {
        studentNameRef.current!.value = '';
        setError(undefined);
      })
      .catch(() => setError('Failed to add student'))
      .finally(() => setAddingStudent(false));
  }

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell sx={{ py: 1, pl: 0.5, pr: 1 }}>
        <TextField
          label="Additional student"
          size="small"
          fullWidth
          inputRef={studentNameRef}
          error={error !== undefined}
          helperText={error}
        />
      </TableCell>
      <TableCell sx={{ px: 0.25, pt: 1, verticalAlign: "top" }}>
        <LoadingButton
          variant="contained"
          fullWidth
          onClick={handleAddStudent}
          loading={addingStudent}
          startIcon={<AddIcon />}
          disableElevation
          sx={{ height: '40px' }}
        >
          Add
        </LoadingButton>
      </TableCell>
    </TableRow>
  );
}
