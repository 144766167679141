import * as React from "react";
import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface RequestConfirmation {
  readonly action: () => void;
  readonly message: string;
  readonly confirmText?: string;
  readonly cancelText?: string;
  readonly title?: string;
  readonly confirmButtonColor?: "primary" | "error";
}

interface UseConfirmationDialog {
  readonly requestConfirmation: (request: RequestConfirmation) => void;
  readonly component: React.ReactNode;
}

export default function useConfirmationDialog(): UseConfirmationDialog {
  const [open, setOpen] = useState(false);
  const [confirmation, setConfirmation] = useState<RequestConfirmation | null>(null);

  const requestConfirmation = (request: RequestConfirmation) => {
    setConfirmation(request);
    setOpen(true);
  };

  const component = (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>{confirmation?.title || "Confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText>{confirmation?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>{confirmation?.cancelText || "Cancel"}</Button>
        <Button
          onClick={() => {
            confirmation?.action();
            setOpen(false);
          }}
          autoFocus
          color={confirmation?.confirmButtonColor || "error"}
          variant="contained"
          disableElevation
        >
          {confirmation?.confirmText || "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return {
    requestConfirmation,
    component
  };
}
