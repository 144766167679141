import * as React from "react";
import { useEffect, useState } from "react";
import { UUID } from "../../domain/UUID";
import Season from "../../domain/Season";
import { getSeason } from "../../gateway/exams";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import EditableExamDocumentRow from "./EditableExamDocumentRow";
import ExamLevel from "../../domain/ExamLevel";
import ExamDocumentRow from "./ExamDocumentRow";

export interface IncludedExamLevel {
  readonly level: ExamLevel,
  readonly includeSolutions: boolean,
}

interface Props {
  readonly seasonId: UUID<Season>,
  readonly levels: "all-editable" | IncludedExamLevel[],
}

export default function ExamDocumentTable({ seasonId, levels }: Props) {
  const [season, setSeason] = useState<Season | null>(null);

  useEffect(() => {
    getSeason(seasonId).then(setSeason);
  }, [seasonId]);

  if (season === null) {
    return <p>Loading</p>
  }

  return (
    <Table size="small">
      {levels === "all-editable"
        ? (
          <colgroup>
            <col style={{ width: '20%' }} />
            <col style={{ width: '40%' }} />
            <col style={{ width: '40%' }} />
          </colgroup>
        ) : (
          <colgroup>
            <col style={{ width: '36%' }} />
            <col style={{ width: '32%' }} />
            <col style={{ width: '32%' }} />
          </colgroup>
        )
      }
      <TableHead>
        <TableRow>
          <TableCell>Exam</TableCell>
          <TableCell sx={{ pl: 3}}>Questions document</TableCell>
          <TableCell sx={{ pl: 3}}>Solutions document</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {levels === "all-editable"
          ? Object.keys(season.exams)
            .map(l => l as ExamLevel)
            .sort(ExamLevel.comparator())
            .map(examLevel => (
              <EditableExamDocumentRow
                key={examLevel}
                seasonMetadata={season}
                examId={season.exams[examLevel].examId}
              />
            ))
          : levels
            .filter(level => season.exams[level.level] !== undefined)
            .sort((a, b) => ExamLevel.comparator()(a.level, b.level))
            .map(l => (
              <ExamDocumentRow
                key={l.level}
                seasonMetadata={season}
                examId={season.exams[l.level].examId}
                includeSolutions={l.includeSolutions}
              />
            ))
        }
      </TableBody>
    </Table>
  );
}
