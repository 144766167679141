import React, { useMemo } from 'react';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import StudentExamResult from '../../../domain/StudentExamResult';
import Grid from "@mui/material/Unstable_Grid2";
import Award from "../../../domain/Award";
import { BoundaryPreview, calculateBoundaryPreview } from "./boundaryPreview";


interface Props {
  results: StudentExamResult[] | undefined;
}

export default function SuggestedBoundariesCard({ results }: Props) {
  const boundaries = useMemo<BoundaryPreview[]>(() => {
    if (!results || results.length === 0) {
      return [];
    }

    const totalStudents = results.length;

    const calculateBoundaryPercentage = (index: number): number => {
      if (index <= 0 || index >= totalStudents) {
        return parseFloat(results[index]?.percentage || '0');
      }
      const lower = parseFloat(results[index - 1]?.percentage || '0');
      const upper = parseFloat(results[index]?.percentage || '0');
      return parseFloat(((lower + upper) / 2).toFixed(2));
    };

    const cutoffIndices = [
      { award: Award.GOLD, cutoff: Math.ceil(totalStudents * 0.05) },
      { award: Award.SILVER, cutoff: Math.ceil(totalStudents * 0.15) },
      { award: Award.BRONZE, cutoff: Math.ceil(totalStudents * 0.35) },
    ];

    const boundaries = cutoffIndices.map((cutoff) => {
      return {
        award: cutoff.award,
        boundary: calculateBoundaryPercentage(cutoff.cutoff),
      };
    });

    return calculateBoundaryPreview(results, boundaries);
  }, [results]);

  if (!results) {
    return (
      <Card variant="outlined">
        <CardHeader title="Suggested boundaries" sx={{ pb: 0 }} />
        <CardContent>
          <Typography variant="body2" color="textSecondary">Loading...</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card variant="outlined">
      <CardHeader
        title="Suggested boundaries"
        subheader={`Based on ${results.length} marked papers`}
        sx={{ pb: 0 }}
      />
      <CardContent>
        <Grid container spacing={0}>
          {boundaries.reverse().map((boundary) => (
            <React.Fragment key={boundary.award}>
              <Grid xs={2}>
                <Typography variant="body1" align="right">
                  <strong>{Award.toHumanString(boundary.award)}:</strong>
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography variant="body1" align="center">
                  {boundary.boundary.toFixed(2)}%
                </Typography>
              </Grid>
              <Grid xs={7}>
                <Typography variant="body1">
                  ({boundary.count} student{boundary.count == 1 ? '' : 's'}, {boundary.percentageOfStudents.toFixed(2)}%)
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
