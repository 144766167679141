import * as React from "react";
import { Avatar } from "@mui/material";
import Account from "../../domain/Account";

interface Props {
  readonly account: Account,
  readonly size?: "sm" | "md",
  readonly sx?: any
}

const sizes = {
  sm: {
    'avatar': 24,
    'fontSize': 12
  },
  md: {
    'avatar': 36,
    'fontSize': 16
  }
}

export default function AccountAvatar({ account, size = 'md', sx = {} }: Props) {
  let initials = account.name.split(" ").map((name) => name[0]).filter((initial) => initial !== undefined && initial !== '')
  if (initials.length > 2) {
    initials = [initials[0], initials[initials.length - 1]]
  }
  initials = initials.map((initial) => initial.toUpperCase())

  const hue = parseInt(account.id.slice(0, 8), 16) % 360;
  const color = `hsl(${hue}, 66%, 50%)`

  return (
    <Avatar
      sx={{
        ...sx,
        bgcolor: color,
        width: sizes[size].avatar,
        height: sizes[size].avatar,
        fontSize: sizes[size].fontSize
      }}
    >
      {initials.join("")}
    </Avatar>
  );
}
