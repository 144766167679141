import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Alert, Button, Container, Link, Paper, Stack, TextField, Typography } from '@mui/material';
import { resetPassword } from '../../gateway/accounts';
import { ROUTE_DASHBOARD, ROUTE_LOGIN } from '../../UkloRoutes';
import { useAccount } from "./AccountContext";

export default function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get("accountId") || "";
  const token = searchParams.get("token") || "";
  const navigate = useNavigate();
  const { invalidatePromise, isLoggedIn } = useAccount();

  const [errorMessage, setErrorMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (isLoggedIn)
    {
      // If the user is already logged in, go home
      invalidatePromise()
        .then(() => navigate(ROUTE_DASHBOARD))
        .then(() => window.location.reload());
    }
  }, [isLoggedIn]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget as typeof event.currentTarget & {
      password: { value: string };
    };
    const newPassword = form.password.value.trim();

    if (newPassword.length < 8) {
      setErrorMessage("Password must be at least 8 characters.");
      return;
    }

    resetPassword({ accountId, token, newPassword })
      .then(() => {
        setSubmitted(true);
        setErrorMessage("");
        setTimeout(() => {
          invalidatePromise()
            .then(() => navigate(ROUTE_DASHBOARD))
            .then(() => window.location.reload());
        }, 3000);
      })
      .catch(() => setErrorMessage("Unable to reset password. Your link may have expired."));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper sx={{ mt: 4, p: 4, pb: 3 }}>
        <Stack alignItems="center" spacing={3}>
          <Typography component="h1" variant="h5">
            Choose a new password
          </Typography>
          {!submitted && (
            <Stack component="form" onSubmit={handleSubmit} noValidate spacing={1} sx={{ width: '100%' }}>
              {errorMessage && (
                <Alert severity="error">
                  {errorMessage}
                </Alert>
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="New password"
                type="password"
                id="password"
                autoComplete="new-password"
                error={!!errorMessage}
              />
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                disableElevation
              >
                Reset password
              </Button>
            </Stack>
          )}
          {submitted && (
            <Alert severity="success">
              Your password has been reset. Redirecting...
            </Alert>
          )}
          <Link component={RouterLink} to={ROUTE_LOGIN} variant="body2">
            Back to login
          </Link>
        </Stack>
      </Paper>
    </Container>
  );
}
