import AwardBoundaries from "../../../domain/AwardBoundaries";
import { Button, Typography } from "@mui/material";
import Award from "../../../domain/Award";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { BoundaryPreview, calculateBoundaryPreview } from "./boundaryPreview";
import StudentExamResult from "../../../domain/StudentExamResult";

interface Props {
  readonly boundaries: AwardBoundaries;
  readonly editable: boolean;
  readonly results: StudentExamResult[] | undefined;
  readonly onEdit: () => void;
}

export default function AwardBoundariesView({ boundaries, editable, results, onEdit }: Props) {
  let boundaryPreview: BoundaryPreview[] = [];
  if (results && results.length > 0 && Object.keys(boundaries.awardToThreshold).length > 0) {
    boundaryPreview = calculateBoundaryPreview(results, Award.getOrderedAwardsExcludingParticipation().reverse().map((award) => ({
      award,
      boundary: parseFloat(boundaries.awardToThreshold[award] || "0"),
    })));
  }

  const previewDisplay = (award: Award) => {
    const preview = boundaryPreview.find((preview) => preview.award === award);
    if (!preview) {
      return <></>;
    }

    return (
      <Typography variant="body1" align="left">
        ({preview.count} student{preview.count == 1 ? '' : 's'}, {preview.percentageOfStudents.toFixed(2)}%)
      </Typography>
    );
  }

  return (
    <>
      <Grid container spacing={0}>
        {Award.getOrderedAwardsExcludingParticipation().map((award) => (
          <React.Fragment key={award}>
            <Grid xs={2}>
              <Typography variant="body1" align="right">
                <strong>{Award.toHumanString(award)}:</strong>
              </Typography>
            </Grid>
            <Grid xs={3}>
              <Typography variant="body1" align="center">
                {boundaries.awardToThreshold[award] ? `${boundaries.awardToThreshold[award]}%` : "Not set"}
              </Typography>
            </Grid>
            <Grid xs={7}>{previewDisplay(award)}</Grid>
          </React.Fragment>
        ))}
      </Grid>
      {editable && (
        <Button variant="contained" color="primary" onClick={onEdit} sx={{ marginTop: 2 }} fullWidth>
          Edit Award Boundaries
        </Button>
      )}
    </>
  );
}
