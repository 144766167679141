import * as React from "react";
import { useEffect, useState } from "react";
import Centre from "../../../domain/Centre";
import { UUID } from "../../../domain/UUID";
import { Alert, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import Student from "../../../domain/Student";
import { getStudents, removeStudent } from "../../../gateway/students";
import AddStudentForm from "./AddStudentForm";
import CentreStudentsTableDataGrid from "./CentreStudentsTableDataGrid";
import Season from "../../../domain/Season";
import ExamLevel from "../../../domain/ExamLevel";
import StudentEstimate from "./StudentEstimate";
import IconCardTitle from "../../common/IconCardTitle";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

interface Props {
  readonly season: Season,
  readonly centreId: UUID<Centre>
}

export default function RegisterStudentsSection({ season, centreId }: Props) {
  const [students, setStudents] = useState<Student[] | undefined>(undefined);
  const [lastChange, setLastChange] = useState<Date>(new Date());

  useEffect(() => {
    getStudents({ centreId, seasonId: season.id }).then(setStudents);
  }, [season.id, centreId]);

  const addNewStudent = (student: Student) => {
    setLastChange(new Date());

    if (students === undefined) {
      setStudents([student]);
      return;
    }

    setStudents([student, ...students]);
  }

  const deleteStudent = (id: UUID<Student>) => {
    setLastChange(new Date());

    removeStudent(id);

    if (students === undefined) {
      return;
    }

    setStudents(students.filter(s => s.id !== id));
  }

  if (students === undefined) {
    return <Typography>Loading</Typography>;
  }

  const advancedExam = season.exams[ExamLevel.ADVANCED];
  const anyStudentsAreNotAdvanced = students.find(s => s.examId !== advancedExam.examId) !== undefined;

  return (
    <>
      <StudentEstimate season={season} exam={advancedExam} centreId={centreId} stale={lastChange} />
      <Card variant="outlined">
        <CardHeader
          title={<IconCardTitle icon={<PersonAddAlt1Icon fontSize="large" />} text={`Register students for ${season.year}`} />}
          sx={{ pb: 0 }}
        />
        <CardContent>
          <Stack spacing={1}>
            <AddStudentForm centreId={centreId} onNewStudent={addNewStudent} />
            {students.length > 0
              ? <CentreStudentsTableDataGrid season={season} rows={students} editable displayRegistered
                                             onDeleteStudent={deleteStudent} />
              : <Alert severity="warning">You haven't registered any students yet.</Alert>
            }
            {anyStudentsAreNotAdvanced &&
              <Alert severity="info">Our markers will only mark students for the advanced exam.</Alert>}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
