import * as React from 'react';
import { UUID } from "../../domain/UUID";
import Centre from "../../domain/Centre";
import CentreExamDocumentsEditable from "./CentreExamDocumentsEditable";
import CentreExamDocumentsReadOnly from "./CentreExamDocumentsReadOnly";


interface Props {
  readonly centreId: UUID<Centre>,
  readonly editable?: boolean,
  readonly title?: string,
}

export default function CentreExamDocumentsCard({ centreId, editable = false, title }: Props) {
  if (editable) {
    return <CentreExamDocumentsEditable centreId={centreId} title={title} />;
  }

  return <CentreExamDocumentsReadOnly centreId={centreId} title={title} />;
}
