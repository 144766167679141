import * as React from 'react';
import { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from "react-hook-form";
import FormInputText from "../form/FormInputText";

interface Props {
  readonly open: boolean,
  readonly initialCapacity: number,
  readonly dismiss: () => void,
  readonly save: (capacity: number) => void,
}

interface MarkingCapacityFormData {
  readonly capacity: number
}

export default function MarkingCapacityDialog({ open, initialCapacity, dismiss, save }: Props) {
  const { control, handleSubmit, setValue } = useForm<MarkingCapacityFormData>({
    defaultValues: {
      capacity: initialCapacity
    }
  });

  useEffect(() => {
    if (open)
    {
      setValue('capacity', initialCapacity);
    }
  }, [open]);

  const onSubmit = handleSubmit(({ capacity }) => save(capacity));

  return (
    <Dialog open={open} onClose={dismiss} fullWidth>
      <form onSubmit={onSubmit} noValidate>
        <DialogTitle>
          How many scripts can you mark this year?
        </DialogTitle>
        <DialogContent dividers>
          <FormInputText
            name="capacity"
            label="Capacity"
            control={control}
            fullWidth
            rules={{
              required: "Please input a capacity",
              min: { value: 0, message: "Your capacity cannot be less than 0" }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button onClick={dismiss}>Cancel</Button>
          <Button type="submit" onClick={onSubmit} variant="contained" startIcon={<SaveIcon />} disableElevation>Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
