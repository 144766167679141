import * as React from "react";
import { Alert, Button } from "@mui/material";
import Centre from "../../domain/Centre";
import { UUID } from "../../domain/UUID";
import { Link as RouterLink } from "react-router-dom";
import { ROUTE_MARKING } from "../../UkloRoutes";
import { MarkingMode } from "../marking/MarkingPage";
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';

interface Props {
  readonly centreId: UUID<Centre>;
}

export default function SelfServeCentreMarkingAlert({ centreId }: Props) {
  return (
    <Alert
      severity="info"
      icon={<ChecklistRtlIcon fontSize="inherit" />}
      action={
        <Button
          component={RouterLink}
          to={ROUTE_MARKING(centreId, MarkingMode.CENTRE_MANAGER)}
          color="inherit"
          size="small"
        >
          Start marking
        </Button>
      }
    >
      You can now input points for non-Advanced exams.
    </Alert>
  );
}
